import React, { useEffect } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import noScroll from 'no-scroll';

import * as styles from './modal.scss';

const ThumbRenderer = ({ elementRef, style, ...restProps }) => {
  return (
    <span
      {...restProps}
      ref={elementRef}
      style={{
        ...style,
        background: '#23282d',
        position: 'absolute'
      }}
    />
  );
};

ThumbRenderer.propTypes = {
  elementRef: PropTypes.object,
  style: PropTypes.object
};

const Modal = ({ children, ...props }) => {
  useEffect(() => {
    if (props.isOpen) {
      noScroll.on();
    } else {
      noScroll.off();
    }
  }, [props.isOpen]);

  return (
    <ReactModal
      {...props}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <Scrollbar
          thumbYProps={{
            renderer: ThumbRenderer
          }}
        >
          {children}
        </Scrollbar>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool
};

export default Modal;
