import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import Helmet from 'react-helmet';
import appSwitcher from '#/config/appSwitcher';
import { connect } from 'react-redux';

import * as versioningClient from '#/services/editorClient/editionsVersioningClient';
import Header from '#/containers/Header/Header';
import TVHeader from '#/elevate/containers/Header/TVHeader';
import {
  containerEntryTypeId,
  itemEntryTypeId
} from '#/config/accedoOneManagement';
import appConfig from '#/config/app';
import {
  changeEntry,
  createEntryContainerInPage,
  getAllItems,
  removeEntry,
  resetEntry
} from '#/services/editorClient/editorClient';
import socket from '#/services/clientSocket/clientSocket';
import { redirectToUrl, createRandomString } from '#/utils/general';

import Panel, {
  PanelPosition
} from '#/containers/IntuitiveEditor/components/Panel';
import {
  ACCEDO_ENTRIES_QUERY,
  AVAILABLE_CONTAINER_TEMPLATES,
  SOLO_CONTAINER_TEMPLATE_IDS,
  BRIGHTCOVE_ENTRIES_QUERY,
  EDITOR_FOOTER_HEIGHT,
  EDITOR_TOP_BAR_HEIGHT,
  STORAGE_KEY_VERSION_PREVIEW,
  PLATFORMS
} from '#/containers/IntuitiveEditor/components/editorConstants';
import Minimap from '#/containers/IntuitiveEditor/components/Minimap';
import VersionsModal from '#/containers/IntuitiveEditor/components/Versions/VersionsModal';
import ContextualMenu from '#/containers/IntuitiveEditor/components/ContextualMenu/ContextualMenu';
import ViewWrapper from '#/containers/IntuitiveEditor/components/ViewWrapper';
import ContainerCreator from '#/containers/IntuitiveEditor/components/ContainerCreator';
import TopBar from '#/containers/IntuitiveEditor/components/TopBar';
import Footer from '#/containers/IntuitiveEditor/components/Footer';
import withConfig from '#/containers/utils/withConfig';

import reduxModules from '#/redux/modules';

import {
  createUnsavedEntryId,
  getEditionFromLocation,
  getHelperStructures,
  getNewContainersWithItemReplaced,
  isUnsavedContainer,
  reorderList
} from './intuitiveEditorHelpers';

import PageModal from './PageModal';

const PANEL_WIDTH_PERCENTAGE = 22;
const PANEL_WIDTH_STR = `${PANEL_WIDTH_PERCENTAGE}%`;

const DESKTOP_CONTAINER_CONFIG = {};
const MOBILE_CONTAINER_CONFIG = {
  shouldEnforceMobile: true
};
const CTV_CONTAINER_CONFIG = {
  shouldEnforceCTV: true
};

const editablePageTemplates = ['elevate-modular-ui'];

if (global.localStorage) {
  localStorage.setItem(STORAGE_KEY_VERSION_PREVIEW, JSON.stringify(null));
}

const getCurrentVersionId = () => {
  const result = localStorage.getItem(STORAGE_KEY_VERSION_PREVIEW);

  return result ? JSON.parse(result) : null;
};

const mapStateToProps = state => ({
  platform: reduxModules.platform.selectors.getPlatform(state)
});

const mapActionsToProps = {
  updateQueriesData: reduxModules.vikimapQueries.actions.updateData,
  setPlatform: reduxModules.platform.actions.setPlatform
};

const IntuitiveEditor = ({
  config,
  children,
  entry,
  entryUpdated,
  location,
  onPageRefresh,
  queriesData,
  updateQueriesData,
  setPlatform,
  platform
}) => {
  const editionId = getEditionFromLocation(location);
  const { parsedContainers } = getHelperStructures({
    containers: entry.containers
  });

  const getDefaultSelectedEntry = (containers) => {
    return containers?.length
      ? { type: 'container', entry: containers[0] }
      : null;
  };
  const isEditionsSupported = appSwitcher.backend === 'git';
  const [isEditionDisabled, setEditionDisabled] = useState(isEditionsSupported);
  const [editionMetadata, setEditionMetadata] = useState({});
  const [editionVersions, setEditionVersions] = useState(null);
  const [containers, setContainers] = useState(parsedContainers || []);
  const [availableItems, setAvailableItems] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(
    getDefaultSelectedEntry(containers)
  );
  const [selectedItemContainer, setSelectedItemContainer] = useState(
    containers?.length ? containers[0] : null
  );
  const [currentError, setCurrentError] = useState('');
  const [isPageModalOpen, setIsPageModalOpen] = useState(false);
  const [newVersionDisplayed, setNewVersionDisplayed] = useState(0);

  const [menuPagesState, setMenuPagesState] = useState({});

  const uneditablePage = editablePageTemplates.indexOf(entry.template) < 0;
  let isSoloPage =
    uneditablePage || (containers?.length > 0 ? false : undefined);

  const compRef = useRef({});
  const containerIdToEntryMap = containers.reduce((acc, containerEntry) => {
    if (SOLO_CONTAINER_TEMPLATE_IDS.indexOf(containerEntry.template) > -1) {
      isSoloPage = true;
    }
    acc[containerEntry.id] = containerEntry;
    return acc;
  }, {});
  const containersIds = Object.keys(containerIdToEntryMap);
  //const mainMenuId = config.accedoOne.mainMenuEntryId;

  const setSelectedContainer = (v) => {
    setSelectedEntry({ type: 'container', entry: v });
    setSelectedItemContainer(v);
  };

  const setSelectedItem = (v) => setSelectedEntry({ type: 'item', entry: v });

  useEffect(() => {
    if (entryUpdated > 1) {
      setContainers(parsedContainers);
      setSelectedItemContainer(null);
      setSelectedEntry(getDefaultSelectedEntry(parsedContainers));
      setNewVersionDisplayed(newVersionDisplayed + 1);
    }
  }, [entryUpdated]);

  useEffect(() => {
    // Sending entire copy might be too expensive, but IMO that's a robust and easy solution.
    // I'd suggest that we have a performance test.
    const content = { ...entry, containers };

    socket?.emit('entry-updated', JSON.stringify({ content, editionId }));
  }, [containers]);

  useEffect(() => {
    if (currentError) {
      clearTimeout(compRef.current.errorTimeout);
      compRef.current.errorTimeout = setTimeout(() => {
        setCurrentError('');
      }, 1000);
    }

    return () => {
      clearTimeout(compRef.current.errorTimeout);
    };
  }, [currentError]);

  useEffect(() => {
    const fetchItems = async () => {
      if (!__CLIENT__ || !entry) {
        return;
      }

      const items = await getAllItems({ editionId });

      setAvailableItems(items);
    };
    const fetchEditionMetadata = async () => {
      if (!__CLIENT__ || !editionId) {
        return;
      }

      const metadata = await versioningClient.getEditionMetadata({ editionId });

      setEditionMetadata(metadata);

      if (!metadata.published.length && isEditionDisabled) {
        setEditionDisabled(false);
      }
    };

    fetchItems();
    fetchEditionMetadata();
  }, []);

  if (!entry) {
    return null;
  }

  const reorderContainers = ({ sourceIndex, destinationIndex }) => {
    if (sourceIndex === destinationIndex) {
      // no need to reorder and change entry
      return;
    }

    const newContainers = reorderList(
      containers,
      sourceIndex,
      destinationIndex
    );

    setContainers(newContainers);

    changeEntry({
      entryId: entry.id,
      editionId,
      values: [
        {
          key: 'containers',
          value: newContainers
            .map((c) => c.id)
            .filter((id) => !isUnsavedContainer(id))
        }
      ]
    });
  };

  const reorderItems = ({ sourceIndex, destinationIndex }) => {
    if (sourceIndex === destinationIndex) {
      // no need to reorder and change entry
      return;
    }

    const container = selectedEntry.entry;
    const items = container.query?.includes(BRIGHTCOVE_ENTRIES_QUERY)
      ? queriesData[container.query].content
      : container.items;
    const newItems = reorderList(items, sourceIndex, destinationIndex);
    const newContainer = { ...container, items: newItems };
    const newContainers = containers.map((c) =>
      c.id === container.id ? newContainer : c
    );

    if (newContainer?.query?.includes(BRIGHTCOVE_ENTRIES_QUERY)) {
      const queryData = queriesData[selectedEntry.entry.query]?.content;
      const newQueryData = reorderList(
        queryData,
        sourceIndex,
        destinationIndex
      );

      updateQueriesData(newContainer.query, newQueryData);
    }

    setContainers(newContainers);
    setSelectedContainer(newContainer);

    changeEntry({
      editionId,
      entryId: newContainer.id,
      values: [
        {
          key: 'items',
          value: newItems.map((i) => i.id)
        }
      ]
    });
  };

  const createContainer = async ({ template, newIndex }) => {
    const tempContainerId = createUnsavedEntryId();
    const newContainer = {
      id: tempContainerId,
      template,
      displayText: 'New Container',
      title: `Container - New Container ${createRandomString()}`,
      query: 'placeholderData'
    };
    const newContainers = containers.slice(0);

    newContainers.splice(newIndex, 0, newContainer);

    setContainers(newContainers);
    setSelectedContainer(newContainer);

    const newId = await createEntryContainerInPage({
      container: newContainer,
      containersIds: newContainers.map((c) => c.id),
      editionId,
      pageId: entry.id
    });

    const containersWithNewId = newContainers.map((container) => {
      return container.id === newContainer.id
        ? {
            ...container,
            id: newId
          }
        : container;
    });

    setContainers(containersWithNewId);

    const containerWithNewId = containersWithNewId.find((c) => c.id === newId);

    setSelectedContainer(containerWithNewId);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (
      (source.droppableId === 'minimap' &&
        destination?.droppableId === 'minimap') ||
      (source.droppableId === 'containerPage' &&
        destination?.droppableId === 'containerPage')
    ) {
      reorderContainers({
        sourceIndex: source.index,
        destinationIndex: destination.index
      });
    } else if (
      source.droppableId === 'containerCreator' &&
      (destination?.droppableId === 'minimap' ||
        destination?.droppableId === 'containerPage')
    ) {
      const template = result.draggableId;
      createContainer({ template, newIndex: destination.index });
    } else if (
      source.droppableId === 'contextualMenuItems' &&
      destination?.droppableId === 'contextualMenuItems'
    ) {
      reorderItems({
        sourceIndex: source.index,
        destinationIndex: destination.index
      });
    }
  };

  const handleAddNewContainer = () => {
    const currentSelectedIndex = containers.findIndex((c) => {
      return c.id === selectedEntry?.entry.id;
    });

    const newIndex =
      typeof currentSelectedIndex === 'number'
        ? currentSelectedIndex + 1
        : containers.length - 1;

    createContainer({
      template: AVAILABLE_CONTAINER_TEMPLATES[0].id,
      newIndex
    });
  };

  const handleContainerClick = (container) => {
    setSelectedContainer(container);
  };

  const handlePageContainerClick = (container) => {
    setSelectedContainer(container);
    setSelectedItemContainer(container);
  };

  const handlePageItemClick = (props) => {
    props = props || {};
    const { event, item, containerId } = props;
    event?.stopPropagation();

    if (selectedItemContainer?.id === containerId) {
      setSelectedItem(item);
    } else {
      const container = containerIdToEntryMap[containerId];

      setSelectedItemContainer(null);
      setSelectedContainer(container);
    }
  };

  compRef.current.handleContainerSave = (opts) => {
    const { newDisplayText, newQuery, newTemplate, newTitle } = opts;

    if (!selectedEntry) {
      return;
    }

    return resetEntry({
      editionId,
      contentTypeId: containerEntryTypeId,
      entryId: selectedEntry.entry.id,
      values: [
        {
          key: 'title',
          value: newTitle
        },
        {
          key: 'displaytext',
          locale: 'en',
          value: newDisplayText
        },
        {
          key: 'template',
          value: newTemplate
        },
        // TODO: key: 'metadata' is DEPRECATED in Accedo One.
        // Do we need to replace this with something else?
        //  {
        //   key: 'metadata',
        //   value: newMetadata
        // },
        {
          key: 'items',
          value:
            newQuery === ACCEDO_ENTRIES_QUERY
              ? (selectedEntry.entry.items || []).map((i) => i.id)
              : []
        },
        {
          key: 'query',
          value: newQuery,
          locale: 'en'
        }
      ]
    }).catch((e) => {
      console.error(e);

      setCurrentError(e);
    });
  };

  const handleContainerUpdate = (opts) => {
    const {
      newDescription,
      newDisplayText,
      newQuery,
      newMetadata,
      newTemplate,
      newTitle
    } = opts;
    let title = newTitle;
    if (newTitle.indexOf('Container - New Container') > -1) {
      title = `Container - ${newDisplayText}`;
    }
    const newContainers = containers.map((container) => {
      if (container.id === selectedEntry.entry.id) {
        const newContainer = {
          ...container,
          description: newDescription,
          displayText: newDisplayText,
          metadata: newMetadata,
          query: newQuery,
          template: newTemplate,
          title
        };
        opts.newTitle = title;

        if (
          newQuery !== ACCEDO_ENTRIES_QUERY &&
          (!newQuery.includes(BRIGHTCOVE_ENTRIES_QUERY) ||
            !container.query?.includes(BRIGHTCOVE_ENTRIES_QUERY))
        ) {
          newContainer.items = [];
        }

        return newContainer;
      }
      return container;
    });
    const newContainer = newContainers.find(
      (c) => c.id === selectedEntry.entry.id
    );

    setContainers(newContainers);
    setSelectedContainer(newContainer);

    return compRef.current.handleContainerSave(opts);
  };

  const getSelectedEditorEntry = () => {
    if (!selectedEntry) {
      return null;
    }

    const editorEntry = { ...selectedEntry.entry };

    if (selectedEntry.type === 'item') {
      editorEntry.parentContainer = selectedItemContainer;
    }

    return editorEntry;
  };

  const handleContainerRemove = ({ id: entryId }) => {
    const newContainers = containers.filter((c) => c.id !== entryId);

    setContainers(newContainers);

    if (!isUnsavedContainer(entryId)) {
      removeEntry({ entryId, editionId });
    }

    setSelectedContainer(null);
  };

  const persistContainerItems = (newContainer) => {
    if (!isUnsavedContainer(newContainer.id)) {
      const value = (newContainer.items || []).map((i) => i.id);
      changeEntry({
        editionId,
        entryId: newContainer.id,
        entryType: newContainer.type,
        values: [
          {
            key: 'items',
            value
          }
        ]
      });
    }
  };

  const handleItemRemove = ({ id: entryId }) => {
    const container =
      selectedEntry?.type === 'container'
        ? selectedEntry.entry
        : selectedItemContainer;

    const newContainers = containers.map((c) =>
      c.id === container.id
        ? {
            ...c,
            items: container.query?.includes(BRIGHTCOVE_ENTRIES_QUERY)
              ? queriesData[container.query].content.filter(
                  (item) => item?.id !== entryId
                )
              : c.items.filter((i) => i.id !== entryId)
          }
        : c
    );

    setContainers(newContainers);

    const newContainer = newContainers.find((c) => c.id === container.id);
    setSelectedContainer(newContainer);
    persistContainerItems(newContainer);
  };

  compRef.current.handleItemSave = (opts) => {
    const { newTitle, newDisplayText, newDescription, newSubtitle } = opts;

    resetEntry({
      editionId,
      entryId: selectedEntry.entry.id,
      contentTypeId: itemEntryTypeId,
      values: [
        {
          key: 'title',
          value: newTitle
        },
        {
          key: 'displaytext',
          locale: 'en',
          value: newDisplayText
        },
        {
          key: 'description',
          value: newDescription
        },
        {
          key: 'subtitle',
          value: newSubtitle
        }
      ]
    });
  };

  const handleItemUpdate = (opts) => {
    const { newSubtitle, newDisplayText, newDescription } = opts;
    const parentContainer = selectedItemContainer;

    if (!parentContainer) {
      return null;
    }

    const newContainers = getNewContainersWithItemReplaced({
      containers,
      itemId: selectedEntry.entry.id,
      replacerFn: (item) => ({
        ...item,
        displayText: newDisplayText,
        description: newDescription,
        subtitle: newSubtitle
      })
    });

    setContainers(newContainers);

    compRef.current.handleItemSave(opts);
  };

  const handleContainerWrapperArrowClick = (container, direction) => {
    const containerIdx = containersIds.indexOf(container.id);
    const newIdx = direction === 'up' ? containerIdx - 1 : containerIdx + 1;

    reorderContainers({
      sourceIndex: containerIdx,
      destinationIndex: newIdx
    });
  };

  const handleContainerItemEditClick = (item) => {
    setSelectedItem(item);
  };

  const getEntryPositionProp = () => ({
    value: containersIds.indexOf(selectedEntry?.entry?.id) + 1,
    total: containersIds.length,
    onChange: (newIdx) => {
      if (!selectedEntry?.entry?.id) {
        console.log('selectedEntry', selectedEntry);
        return;
      }
      const containerIdx = containersIds.indexOf(selectedEntry.entry.id);

      reorderContainers({
        sourceIndex: containerIdx,
        destinationIndex: newIdx - 1
      });
    }
  });

  const handleDashboardClick = () => {
    redirectToUrl('/dashboard');
  };

  const handleItemAdd = (itemId) => {
    const item = availableItems[
      selectedEntry?.entry?.query?.includes(BRIGHTCOVE_ENTRIES_QUERY)
        ? 'brightcoveData'
        : 'accedoData'
    ].find((i) => i.id === itemId);
    const containerId = selectedEntry.entry.id;

    const container =
      selectedEntry?.type === 'container'
        ? selectedEntry.entry
        : selectedItemContainer;

    let items = [];

    if (container?.query?.includes(BRIGHTCOVE_ENTRIES_QUERY)) {
      const newItem = availableItems.brightcoveData.filter(
        (i) => i.id === itemId
      );

      items = queriesData[container.query].content.concat(newItem);
    } else {
      items = (container?.items?.filter((i) => i.id !== itemId) || []).concat([
        item
      ]);
    }

    const newContainers = containers.map((c) =>
      c.id === containerId
        ? {
            ...c,
            items
          }
        : c
    );

    setContainers(newContainers);

    const newContainer = newContainers.find((c) => c.id === containerId);
    setSelectedContainer(newContainer);
    persistContainerItems(newContainer);
  };

  const contextualMenuProps =
    selectedEntry?.type === 'container'
      ? {
          availableTemplates: AVAILABLE_CONTAINER_TEMPLATES,
          entryPosition: getEntryPositionProp(),
          onAddItemClick: handleItemAdd,
          onChildEntryEditClick: handleContainerItemEditClick,
          onChildEntryRemoveClick: handleItemRemove,
          onEntryRemove: handleContainerRemove,
          onEntryUpdate: handleContainerUpdate,
          queryData: queriesData[selectedEntry.entry?.query]?.content
        }
      : {
          availableTemplates: null,
          parentEntryField: {
            label: 'Container',
            link: selectedItemContainer?.displayText || '',
            onClick: () => {
              setSelectedContainer(selectedItemContainer);
            },
            container: selectedItemContainer
          },
          onEntryRemove: handleItemRemove,
          onEntryUpdate: handleItemUpdate
        };

  let containerConfig = DESKTOP_CONTAINER_CONFIG;

  if (platform === PLATFORMS.mobile.value) {
    containerConfig = MOBILE_CONTAINER_CONFIG;
  } else if (platform === PLATFORMS.ctv.value) {
    containerConfig = CTV_CONTAINER_CONFIG;
  }

  const handleModalOpenClick = () => {
    setIsPageModalOpen(true);
  };

  const handleModalCloseClick = () => {
    setIsPageModalOpen(false);
  };

  const getMinimapSelectedEntryId = () => {
    if (!selectedEntry) {
      return;
    }

    if (selectedEntry.type === 'container') {
      return selectedEntry?.entry?.id;
    }

    return selectedItemContainer?.id || '';
  };

  const closeEditionVersionsModal = () => {
    localStorage.setItem(STORAGE_KEY_VERSION_PREVIEW, JSON.stringify(null));
    onPageRefresh();

    if (!editionMetadata.published.length) {
      setEditionDisabled(false);
    }

    setEditionVersions(null);
  };

  const handleVersionsButtonClick = async () => {
    if (editionVersions) {
      closeEditionVersionsModal();

      return;
    }

    const { versions, editionCreatedOn } =
      await versioningClient.getEditionVersions({
        editionId
      });

    setEditionVersions(versions);
    setEditionMetadata({ ...editionMetadata, createdOn: editionCreatedOn });

    setEditionDisabled(isEditionsSupported);
  };

  const handleVersionClick = (version) => {
    localStorage.setItem(
      STORAGE_KEY_VERSION_PREVIEW,
      JSON.stringify(version.id)
    );
    onPageRefresh();
  };

  const handleEditionStatusUpdate = (newStatus) => {
    versioningClient.updateEditionMetadata({
      editionId,
      status: newStatus,
      updatedKeys: ['status']
    });

    setEditionMetadata({
      ...editionMetadata,
      status: newStatus
    });
  };

  const handleVersionRestoreClick = async (version) => {
    await versioningClient.restoreVersion({
      versionId: version.id,
      editionId
    });

    closeEditionVersionsModal();
  };

  const currentVersionId = getCurrentVersionId() || editionVersions?.[0]?.id;

  const getShowVersionRestoreButton = ({ index, version }) =>
    index !== 0 &&
    version.id === currentVersionId &&
    !editionMetadata.published.length;

  const messageStyle = {
    margin: '200px auto',
    width: '500px',
    fontSize: '20px',
    textAlign: 'center'
  };

  return (
    <>
      {editionMetadata?.name && (
        <Helmet title={`Edition - ${editionMetadata.name}`} />
      )}
      <TopBar
        currentPlatform={platform}
        isVersionsModalOpen={!!editionVersions}
        onPageModalClick={handleModalOpenClick}
        onPlatformUpdate={setPlatform}
        onVersionsButtonClick={handleVersionsButtonClick}
        userInfo={appConfig.userInfo}
      />
      <PageModal
        currentPageEntry={entry}
        isOpen={isPageModalOpen}
        onRequestClose={handleModalCloseClick}
        menuPagesState={menuPagesState}
        setMenuPagesState={setMenuPagesState}
      />
      <VersionsModal
        editionCreatedOn={editionMetadata.createdOn}
        getShowVersionRestoreButton={getShowVersionRestoreButton}
        highlightedVersionId={currentVersionId}
        onRestoreClick={handleVersionRestoreClick}
        onVersionClick={handleVersionClick}
        versions={editionVersions}
        width={PANEL_WIDTH_STR}
      />
      {currentError && <div>{currentError}</div>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Panel
          isHidden={false}
          width={PANEL_WIDTH_STR}
          style={{
            top: EDITOR_TOP_BAR_HEIGHT,
            marginBottom: EDITOR_FOOTER_HEIGHT
          }}
        >
          <ContainerCreator isEditionDisabled={isEditionDisabled} isSoloPage={isSoloPage} />
          <Minimap
            containers={containers}
            entry={entry}
            isEditionDisabled={isEditionDisabled}
            onAddNewContainer={handleAddNewContainer}
            onContainerClick={handleContainerClick}
            selectedEntryId={getMinimapSelectedEntryId()}
          />
        </Panel>
        <ViewWrapper
          currentPlatform={platform}
          top={EDITOR_TOP_BAR_HEIGHT}
          left={PANEL_WIDTH_PERCENTAGE}
        >
          {platform === 'ctv' ? <TVHeader /> : <Header currentPlatform={platform} />}
          {uneditablePage && <div style={messageStyle}>This page is not editable</div>}
          {!uneditablePage &&
            children({
              containerConfig,
              containers,
              isEditionDisabled,
              isUsingEditor: true,
              onContainerClick: handlePageContainerClick,
              onContainerWrapperArrowClick: isEditionDisabled
                ? null
                : handleContainerWrapperArrowClick,
              onContainerWrapperRemoveClick: isEditionDisabled
                ? null
                : handleContainerRemove,
              onItemClick: handlePageItemClick,
              selectedEditorEntry: getSelectedEditorEntry()
            })}
        </ViewWrapper>
        <Panel
          isHidden={false}
          position={PanelPosition.Right}
          style={{
            top: EDITOR_TOP_BAR_HEIGHT,
            marginBottom: EDITOR_FOOTER_HEIGHT
          }}
          width={PANEL_WIDTH_STR}
        >
          <ContextualMenu
            availableItems={
              availableItems[
                selectedEntry?.entry?.query?.includes(BRIGHTCOVE_ENTRIES_QUERY)
                  ? 'brightcoveData'
                  : 'accedoData'
              ]
            }
            entry={selectedEntry?.entry}
            entryType={selectedEntry?.type}
            isEditionDisabled={isEditionDisabled}
            newVersionDisplayed={newVersionDisplayed}
            onItemClick={handleContainerItemEditClick}
            updateQueriesData={updateQueriesData}
            {...contextualMenuProps}
          />
        </Panel>
      </DragDropContext>
      <Footer
        editionMetadata={editionMetadata}
        onDashboardClick={handleDashboardClick}
        onUpdateEditionStatusClick={handleEditionStatusUpdate}
      />
    </>
  );
};

IntuitiveEditor.propTypes = {
  config: PropTypes.object,
  children: PropTypes.func,
  entry: PropTypes.object,
  entryUpdated: PropTypes.number,
  isUpdating: PropTypes.bool,
  location: PropTypes.object,
  onPageRefresh: PropTypes.func,
  queriesData: PropTypes.object,
  updateQueriesData: PropTypes.func,
  setPlatform: PropTypes.func,
  platform: PropTypes.string
  // event,
  // item,
  // containerId
};

export default connect(mapStateToProps, mapActionsToProps)(withConfig(IntuitiveEditor));
