import fetch from '@accedo/vdkweb-fetch';

import { validateOVPInterface } from '../../interface';
import appConfig from '#/config/app';

const prefix = appConfig.urlPrefix;

const CMS_PREFIX =
  __CLIENT__ && __USE_OVP_PROXY__
    ? `${prefix}brightcove-cms`
    : 'https://cms.api.brightcove.com';
const AUTH_PREFIX =
  __CLIENT__ && __USE_OVP_PROXY__
    ? `${prefix}brightcove-auth`
    : 'https://oauth.brightcove.com';

let loginData = {};
const accountId = '5076297043001';

const signIn = async () => {
  const url = `${AUTH_PREFIX}/v4/access_token`;
  // TODO: These should be in a better place
  const clientId = '51cb3c56-29b5-43bd-a0e2-fa404c8406d3';
  const clientSecret =
    'dIdmTlnanRII1KhgkHbCYSC51Cz-xZoElgNxispNPK8MqKjDa4bLujGMuc3t8KWEBcxpkAt-MR8afTD_OR2QyQ';

  const options = {
    headers: {
      Authorization: `Basic ${Buffer.from(
        `${clientId}:${clientSecret}`
      ).toString('base64')}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };
  options.method = 'POST';
  options.body = 'grant_type=client_credentials';

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const res = await response.json();
  loginData.accessToken = res.access_token;
  loginData.expiresIn = res.expires_in;
  loginData.tokenDate = Date.now();
  loginData.expiryDate = loginData.tokenDate + loginData.expiresIn * 1000;

  return loginData;
};

const validateToken = () => {
  if (loginData && Date.now() < loginData.expiryDate) {
    return Promise.resolve(loginData);
  }

  return signIn();
};

const convertModel = bcMovieData => {
  for (let i = 0; i < bcMovieData.length; i += 1) {
    const entry = bcMovieData[i];
    if (entry.images) {
      // grab highest res image
      const sources = entry?.images?.poster?.sources;
      if (sources) {
        let bestIdx = 0;
        let maxWidth = 0;
        for (let j = 0; j < sources.length; j += 1) {
          if (maxWidth < sources[j]?.width) {
            maxWidth = sources[j]?.width;
            bestIdx = j;
          }

          entry.images = [
            {
              url: (sources[bestIdx].src || '').replace(/^http:/, 'https:'),
              type: 'poster'
            }
          ];
        }
      }
    }
  }

  return bcMovieData.map(entry => {
    return {
      ...entry,
      displayText: entry.name,
      title: entry.name,
      type: 'movie'
    };
  });
};

const getBrightCoveCmsData = async (api, opts) => {
  const data = await validateToken();

  const { accessToken } = data;
  const params = opts?.params || null;
  const options = { headers: { Authorization: `Bearer ${accessToken}` } };
  let url = `${CMS_PREFIX}/v1/accounts/${accountId}/${api}`;
  if (params) {
    url += `?${params}`;
  }

  if (opts) {
    options.method = opts.method;
    if (opts.body) {
      options.body = JSON.stringify(opts.body);
    }
  }

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};

const getMovieData = async (tag = 'movies') => {
  const data = await getBrightCoveCmsData('videos', {
    params: `q=tags:${tag}`
  });
  return convertModel(data);
};

const getMovieById = async id => {
  const data = await getBrightCoveCmsData(`videos/${id}`);
  return convertModel(data);
};

const getTvShowData = () => {};

const getTvShowsByCategory = () => Promise.resolve();

const getMovieCategories = () => {};

const getMovieDataById = id => {
  return getMovieById(id);
};

const getMoviesByCategory = cat => {
  return getMovieData(cat);
};

const signOut = () => {
  loginData = {};
};

const getChannelData = () => {};

const getTvListings = ({ startTime, endTime, count = 4, offset = 0 }) => {
  console.log(startTime, endTime, count, offset); // to shut up linter temporarily
  return Promise.resolve();
};

const getPlaylists = () => {
  return getBrightCoveCmsData('playlists');
};

const getPlaylistVideos = async id => {
  const data = await getBrightCoveCmsData(`playlists/${id}/videos`);
  return convertModel(data);
};

const getPlaylistVideosByName = async name => {
  const data = await getBrightCoveCmsData('playlists');
  const res = data.find(item => item.reference_id === name);
  if (res) {
    return getPlaylistVideos(res.id);
  }

  return null;
};

const createOrUpdatePlaylist = async ({
  plId,
  name,
  description,
  refId,
  favorite,
  videoIds,
  update
}) => {
  const payload = {
    description,
    favorite,
    name,
    reference_id: refId
  };

  // Only explicit playlists have video ids defined
  if (videoIds && videoIds.length > 0) {
    payload.type = 'EXPLICIT';
    payload.video_ids = videoIds;
  }

  let path = 'playlists';
  let method = 'POST';
  if (plId || update === true) {
    if (!plId && refId) {
      const data = await getBrightCoveCmsData('playlists');
      const res = data.find(item => item.reference_id === refId);
      plId = res.id;
    }

    path += `/${plId}`;
    method = 'PATCH';
  }

  return getBrightCoveCmsData(path, { body: payload, method });
};

const createOrUpdateVideo = ({
  cuePoints,
  description,
  drm,
  economics,
  geo,
  longDescription,
  name,
  refId,
  schedule,
  state,
  tags,
  videoId
}) => {
  const payload = {
    cue_points: cuePoints,
    description,
    drm_disabled: drm,
    economics,
    geo,
    long_description: longDescription,
    name,
    reference_id: refId,
    schedule,
    state,
    tags
  };

  let path = 'videos';
  let method = 'POST';
  if (videoId) {
    path += `/${videoId}`;
    method = 'PATCH';
  }

  return getBrightCoveCmsData(path, { body: payload, method });
};

const deleteMovieById = async id => {
  const data = await getBrightCoveCmsData(`videos/${id}`, {
    body: null,
    method: 'DELETE'
  });

  return data;
};

export default validateOVPInterface({
  getChannelData,
  getMovieById,
  getMovieCategories,
  getMovieData,
  getMovieDataById,
  getMoviesByCategory,
  getTvListings,
  getTvShowData,
  getTvShowsByCategory,
  getPlaylists,
  getPlaylistVideos,
  getPlaylistVideosByName,
  createOrUpdatePlaylist,
  createOrUpdateVideo,
  deleteMovieById,
  signIn,
  signOut,
  validateToken
});

// testing movie data
//  getMovieData().then(res => {
//   getMovieData().then(res => {
//     console.dir(res[0].images);
//   });
//  });

// testing playlists
// getPlaylists().then(pls => {
//   console.log(pls[0].id);
//   getPlaylistVideos(pls[0].id).then(res => {
//     console.dir(res[0]);
//   });
// });

// testing creating playlist
// create playlist
// createOrUpdatePlaylist({
//     description: 'Playlist of Birds that Live by the Sea',
//     favorite: true,
//     name: "Test Example Playlist",
//     refId: "example_playlist",
//     type: "EXPLICIT",
//     videoIds: [
//     "5349234839001",
//     "5306235706001",
//     "5306231985001"
//     ]
// }).then( data => {
//     console.dir(data);
// });

// update playlist
// createOrUpdatePlaylist({
//     plId: 6066371716001,
//     description: 'Playlist of Birds that Live by the Sea',
//     favorite: true,
//     name: "Test Example Playlist",
//     refId: "example_playlist",
//     type: "EXPLICIT",
//     videoIds: [
//     "5349234839001",
//     "5306235706001",
//     "5306231985001"
//     ]
// }).then( data => {
//     console.dir(data);
// });

// create video
// createOrUpdateVideo(
// {
//   "cue_points": [
//   {
//     "time": 10.527,
//     "type": "AD",
//     "metadata": "string",
//     "name": "string"
//   }
// ],
// "description": "Laughing Gull",
// "drm_disabled": true,
// "economics": "AD_SUPPORTED",
// "geo": {
//   "countries": [
//     "us",
//     "ca",
//     "fr"
//   ],
//   "exclude_countries": true,
//   "restricted": true
// },
// "long_description": "Herring Gull near Fort Point Channel in Boston, MA, USA. 2019-04-25.",
// "name": "A happy herring gull",
// "reference_id": "test-video-2",
// "schedule": {
//   "ends_at": "2020-05-20T20:41:07.689Z",
//   "starts_at": "2019-05-20T20:41:07.689Z"
// },
// "state": "ACTIVE",
// "tags": [
//   "birds",
//   "sea"
// ]
// }).then(data => {console.dir(data); });

// update video
// createOrUpdateVideo({videoId: '6069609899001', name: 'test-player-22'}).then(data => {console.dir(data);});

// delete video
// deleteMovieById('6098494138001');
