import React from 'react';
import logger from '#/utils/logger';
import * as viewStyles from '../views.scss';

/*
 * Logger usage:
 * logo info will be output to browser console, as well as to Accedo One depending on log level
 *
 * logger.error('this is a error log');
 * logger.info('this is a info log');
 * logger.debug('this is a debug log');
 * logger.warn('this is a warm log');
 */

export const NoMatch = () => {
  logger.info("Oops. There's no spoon here.");
  logger.warn(
    'If you clicked a link on the site to get here, please report it as broken.'
  );

  return (
    <div className={viewStyles.pageContent}>
      <h1>Oops. There's no spoon here.</h1>
      <p>
        If you clicked a link on the site to get here, please report it as
        broken.
      </p>
    </div>
  );
};

export default NoMatch;
