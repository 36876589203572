import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './pageTitle.scss';
import { withTheme } from '#/theme/Theme';

export const PageTitle = ({ text, theme }) => {
  return (
    <div className={`${styles.titleContainer} ${styles[theme.name]}`}>
      <h1>{text}</h1>
    </div>
  );
};

PageTitle.propTypes = {
  text: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(PageTitle);
