import React from 'react';
import PropTypes from 'prop-types';

import logger from '#/utils/logger';
import {
  CONTAINER_ID_TEMPLATE_MAP,
  DEFAULT_TEMPLATE
} from '#/config/constants';
import {
  CONTAINER_ID_TO_COMPONENT_MAP,
  determineItemTypeByTemplate,
  determineAligment
} from './templatesMapper';

const Shelf = ({
  config,
  containerId,
  items,
  onItemClick,
  template,
  templates,
  carouselWidth,
  selectedEditorEntry
}) => {
  if (!CONTAINER_ID_TEMPLATE_MAP[template]) {
    logger.warn(`Template "${template}" is not supported`);
  }

  const containerTemplateId =
    CONTAINER_ID_TEMPLATE_MAP[template] ||
    CONTAINER_ID_TEMPLATE_MAP[DEFAULT_TEMPLATE];

  const ShelfComponent =
    CONTAINER_ID_TO_COMPONENT_MAP[containerTemplateId] ||
    CONTAINER_ID_TO_COMPONENT_MAP.Default;

  const itemType = determineItemTypeByTemplate(template);
  const aligment = determineAligment(template);

  return (
    <ShelfComponent
      items={items}
      itemType={itemType}
      gridAlignment={aligment}
      config={config}
      onItemClick={onItemClick}
      containerId={containerId}
      nav={{
        id: containerId
      }}
      templates={templates}
      carouselWidth={carouselWidth}
      selectedEditorEntry={selectedEditorEntry}
    />
  );
};

Shelf.propTypes = {
  config: PropTypes.object,
  containerId: PropTypes.string,
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  template: PropTypes.string,
  templates: PropTypes.object,
  carouselWidth: PropTypes.string,
  selectedEditorEntry: PropTypes.object
};

export default Shelf;
