import ovp from '@accedo/vdkweb-ovp-client-accedo';

import appConfig from '#/config/app';
import Show from '#/models/show/show';
import Movie from '#/models/movie/movie';

import { validateOVPInterface } from '../../interface';
import { movieCategories, tvShowCategories } from './dummyCategory';

const errorHandler = error => console.error(error);
const prefix = appConfig.urlPrefix;

ovp.ApiClient.prototype.getBaseUrl = () => {
  // We consider the case for TV here.  For static build, proxy server will not
  // be available unless using a custom server.  And as most TV do not have
  // cross domain restrictions, we will get the exact OVP API url.
  return __USE_OVP_PROXY__
    ? `${prefix}ovp`
    : 'https://accedoovp20x.ocs.ps.accedo.tv'; // 'https://vdk-ovp.ocs.demo.accedo.tv';
};

const movieDataParser = entry => ({
  ...entry,
  displayText: entry.title
});

const getMovieData = ({ category = '' } = {}) => {
  const apiInstance = new ovp.MovieApi();

  const opts = {
    pageSize: 15,
    pageNumber: 1
  };

  const requestPromise = category
    ? apiInstance.getMoviesByCategory(category, opts)
    : apiInstance.getAllMovies(opts);

  return requestPromise
    .then(data => {
      return data?.entries?.map((entry) => Movie(entry));
    })
    .catch(error => {
      console.error('getMovieData:', error);
    });
};

const getMovieById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.MovieApi();

  return apiInstance.getMovieById(id).catch(errorHandler);
};

const getMoviesByCategory = category => {
  if (!category) {
    return Promise.resolve(null);
  }

  return getMovieData({ category }).catch(errorHandler);
};

const getMovieDataById = id => getMovieById(id).then(movieDataParser).catch(errorHandler);

const getTvShowData = ({ category }) => {
  const apiInstance = new ovp.TVShowApi();

  const opts = {
    pageSize: 15,
    pageNumber: 1
  };

  const requestPromise = category
    ? apiInstance.getTvShowsByCategory(category, opts)
    : apiInstance.getAllTvShows(opts);

  return requestPromise
    .then(data => {
      return data?.entries?.map((entry) => Show(entry));
    })
    .catch(error => {
      console.error(error);
    });
};

const getTvShowsByCategory = category => {
  if (!category) {
    return Promise.resolve(null);
  }

  return getTvShowData({ category }).catch(errorHandler);
};

const getMovieCategories = () => {
  return movieCategories;
};

const getTvShowCategories = () => {
  return tvShowCategories;
};

const signIn = credentials => {
  return new ovp.AuthApi().authenticate(
    credentials.email,
    credentials.password
  );
};

const signOut = token => {
  return new ovp.AuthApi().invalidateToken(token);
};

const validateToken = (token, userId) => {
  return new ovp.AuthApi().validateToken(token, userId);
};

let channelDataCache;

const getChannelData = () => {
  if (channelDataCache) {
    return Promise.resolve(channelDataCache);
  }

  const apiInstance = new ovp.ChannelApi();

  return apiInstance
    .getAllChannels()
    .then(channelData => {
      channelDataCache = channelData;
      return channelData;
    })
    .catch(error => {
      console.error(error);
    });
};

const getTvListings = async ({ startTime, endTime, count = 4, offset = 0 }) => {
  const apiInstance = new ovp.TVListingApi();

  const params = {
    pageSize: count,
    pageNumber: 1 + Math.max(Math.round(offset / count), 0)
  };

  const channels = await getChannelData();

  const data = await apiInstance.getTvListing(startTime, endTime, params);

  return {
    ...data,
    entries: data.entries.map(entry => {
      const channelData = channels.entries.find(
        channel => channel.id === entry.channelId
      );

      return {
        ...(channelData || {}),
        ...entry,
        programs: entry.programs
          .filter(
            p =>
              p.endTime <= endTime &&
              p.startTime < endTime &&
              p.endTime > startTime
          )
          .map(p => ({
            ...p,
            title: p.id
          }))
      };
    })
  };
};

// Utility function for getItemsByQuery
const getQueryStringParameters = url => {
  if (url.split('?').length > 1) {
    const query = url.split('?')[1];

    return query.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
  }
};

// This function was added to comply with queries supported by Build/Elevate.
// @accedo/vdkweb-ovp-client-accedo, which is used here, does not support queries of type
// "/movie/11,1891,1892,1893,1894,1895,140607,181808,181812"
// ToDo: Investigate if we should replace the ovp handling with Elevate's?
const getItemsByQuery = async ({ query, itemsPerPage, pageNumber, sortBy }) => {
  if (!query) {
    return null;
  }

  const pageSize = itemsPerPage;

  const optionsRequest = {
    sortBy,
    pageSize,
    pageNumber
  };

  const queryParams = getQueryStringParameters(query);

  const searchParams = new URLSearchParams();
  Object.keys(optionsRequest).forEach(key => {
    if (!queryParams || !queryParams[key]) {
      // query could have params already, we do not want duplicate them
      optionsRequest[key] && searchParams.append(key, optionsRequest[key]);
    }
  });

  const hasParams = searchParams.toString().length > 0;
  const fetchUrl = hasParams
    ? `${ovp.ApiClient.prototype.getBaseUrl()}${query}?${searchParams.toString()}`
    : `${ovp.ApiClient.prototype.getBaseUrl()}${query}`;

  try {
    let items = [];
    let data = {};
    const response = await fetch(fetchUrl);
    data = await response.json();
    items = data.entries;

    return items;
  } catch (error) {
    errorHandler(error);
  }
};

export default validateOVPInterface({
  getChannelData,
  getMovieById,
  getMovieCategories,
  getMovieData,
  getMovieDataById,
  getMoviesByCategory,
  getTvListings,
  getTvShowCategories,
  getTvShowData,
  getTvShowsByCategory,
  getItemsByQuery,
  signIn,
  signOut,
  validateToken
});
