const movieCategories = [
  {
    id: 'movies-horror',
    title: 'Horror',
    displayText: 'Horror',
    description: 'Horror movies'
  },
  {
    id: 'movies-war',
    title: 'War',
    displayText: 'War',
    description: 'War movies'
  },
  {
    id: 'movies-animation',
    title: 'Animation',
    displayText: 'Animation',
    description: 'Animation movies'
  },
  {
    id: 'movies-adventure',
    title: 'Adventure',
    displayText: 'Adventure',
    description: 'Adventure movies'
  },
  {
    id: 'movies-tv-movie',
    title: 'TV Movie',
    displayText: 'TV Movie',
    description: 'TV Movie movies'
  },
  {
    id: 'movies-action',
    title: 'Action',
    displayText: 'Action',
    description: 'Action movies'
  },
  {
    id: 'movies-drama',
    title: 'Drama',
    displayText: 'Drama',
    description: 'Drama movies'
  },
  {
    id: 'movies-comedy',
    title: 'Comedy',
    displayText: 'Comedy',
    description: 'Comedy movies'
  },
  {
    id: 'movies-romance',
    title: 'Romance',
    displayText: 'Romance',
    description: 'Romance movies'
  },
  {
    id: 'movies-documentary',
    title: 'Documentary',
    displayText: 'Documentary',
    description: 'Documentary movies'
  },
  {
    id: 'movies-western',
    title: 'Western',
    displayText: 'Western',
    description: 'Western movies'
  },
  {
    id: 'movies-crime',
    title: 'Crime',
    displayText: 'Crime',
    description: 'Crime movies'
  },
  {
    id: 'movies-mystery',
    title: 'Mystery',
    displayText: 'Mystery',
    description: 'Mystery movies'
  },
  {
    id: 'movies-fantasy',
    title: 'Fantasy',
    displayText: 'Fantasy',
    description: 'Fantasy movies'
  },
  {
    id: 'movies-family',
    title: 'Family',
    displayText: 'Family',
    description: 'Family movies'
  },
  {
    id: 'movies-science-fiction',
    title: 'Science Fiction',
    displayText: 'Science Fiction',
    description: 'Science Fiction movies'
  },
  {
    id: 'movies-thriller',
    title: 'Thriller',
    displayText: 'Thriller',
    description: 'Thriller movies'
  },
  {
    id: 'movies-music',
    title: 'Music',
    displayText: 'Music',
    description: 'Music movies'
  },
  {
    id: 'movies-history',
    title: 'History',
    displayText: 'History',
    description: 'History movies'
  }
];

const tvShowCategories = [
  {
    id: 'tvshows-drama',
    title: 'Drama',
    displayText: 'Drama',
    description: 'Drama tvshows'
  },
  {
    id: 'tvshows-comedy',
    title: 'Comedy',
    displayText: 'Comedy',
    description: 'Comedy tvshows'
  },
  {
    id: 'tvshows-reality',
    title: 'Reality',
    displayText: 'Reality',
    description: 'Reality tvshows'
  },
  {
    id: 'tvshows-kids',
    title: 'Kids',
    displayText: 'Kids',
    description: 'Kids tvshows'
  },
  {
    id: 'tvshows-documentary',
    title: 'Documentary',
    displayText: 'Documentary',
    description: 'Documentary tvshows'
  },
  {
    id: 'tvshows-animation',
    title: 'Animation',
    displayText: 'Animation',
    description: 'Animation tvshows'
  },
  {
    id: 'tvshows-soap',
    title: 'Soap',
    displayText: 'Soap',
    description: 'Soap tvshows'
  },
  {
    id: 'tvshows-news',
    title: 'News',
    displayText: 'News',
    description: 'News tvshows'
  },
  {
    id: 'tvshows-family',
    title: 'Family',
    displayText: 'Family',
    description: 'Family tvshows'
  },
  {
    id: 'tvshows-crime',
    title: 'Crime',
    displayText: 'Crime',
    description: 'Crime tvshows'
  },
  {
    id: 'tvshows-action-and-adventure',
    title: 'Action & Adventure',
    displayText: 'Action & Adventure',
    description: 'Action & Adventure tvshows'
  },
  {
    id: 'tvshows-sci-fi-and-fantasy',
    title: 'Sci-Fi & Fantasy',
    displayText: 'Sci-Fi & Fantasy',
    description: 'Sci-Fi & Fantasy tvshows'
  },
  {
    id: 'tvshows-mystery',
    title: 'Mystery',
    displayText: 'Mystery',
    description: 'Mystery tvshows'
  },
  {
    id: 'tvshows-talk',
    title: 'Talk',
    displayText: 'Talk',
    description: 'Talk tvshows'
  },
  {
    id: 'tvshows-war-and-politics',
    title: 'War & Politics',
    displayText: 'War & Politics',
    description: 'War & Politics tvshows'
  },
  {
    id: 'tvshows-western',
    title: 'Western',
    displayText: 'Western',
    description: 'Western tvshows'
  }
];

export { movieCategories, tvShowCategories };
