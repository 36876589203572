import ENTITY from '@accedo/vdkweb-vikimap/lib/redux/entity';
import { getEntityForAction } from '@accedo/vdkweb-vikimap/lib/redux/reducer';

export const updateMenuReducer = (state, action, entity = ENTITY.MENU) => {
  const { id, items, menuItems } = action.payload;
  const stateEntity = getEntityForAction(action, entity);
  // const entry = state[stateEntity] ? state[stateEntity][id] : {};

  return {
    ...state,
    [stateEntity]: {
      ...state[stateEntity],
      [id]: {
        ...state[stateEntity][id],
        items
      }
    },
    [ENTITY.MENU_ITEM]: menuItems
  };
};
