import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import withConfig from '#/containers/utils/withConfig';
import HeaderComponent from '#/elevate/components/Header/TVHeader';
import modules from '#/redux/modules';
import appConfig from '#/config/app';
import { getTargetForMenuItem } from '../../../containers/Menu/vikimapMenuHelpers';

const prefix = appConfig.urlPrefix;

const mapMenuItem = item => {
  const { displayText, menuItems = [] } = item;
  const menuItem = {
    displayText,
    to: (item.to || getTargetForMenuItem(item)).replace(/^\//, prefix),
    items: menuItems.map(mapMenuItem),
    icon: item.icon && item.icon[0] ? item.icon[0].fileUrl : null
  };
  return menuItem;
};

const mapStateToProps = (state, ownProps) => {
  const { config } = ownProps;
  const { accedoOne = {} } = config;
  const id = accedoOne.mainMenuEntryId;

  const entry = modules.vikimap.selectors.getMenuById(state, id);
  const menuVisible = modules.menu.selectors.getMenuIsVisible(state);

  // If no entry is found, set 'loaded' to false
  // to signal that we're in loading state.
  if (!entry || !(entry.id || entry.content) || entry.__isError) {
    return {
      entryId: id,
      loaded: false,
      failedToLoad: !!(entry && entry.__isError),
      errorMessage: entry && entry.content ? entry.content.message : '',
      menuVisible
    };
  }

  // If no entry information is found set 'loaded' to true
  // to signal that the entry has been loaded.
  // Also propagate the entry to the props.
  return {
    entry,
    entryId: id,
    loaded: true,
    error: entry.__isError,
    menuVisible
  };
};

class Header extends Component {
  componentDidMount() {
    this.props.dispatch(modules.vikimap.actions.fetchMenu(this.props.entryId));
  }

  render() {
    const { entry = {}, loaded, ...others } = this.props;
    const { items, staticItems = [] } = entry;

    return (
      <HeaderComponent
        items={
          loaded && items ? items.concat(staticItems).map(mapMenuItem) : []
        }
        loaded={loaded}
        {...others}
      />
    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func,
  entry: PropTypes.object,
  entryId: PropTypes.string,
  loaded: PropTypes.bool,
  staticItems: PropTypes.array
};

const enhance = compose(
  withProps({ urlPrefix: appConfig.urlPrefix }),
  withConfig,
  connect(mapStateToProps)
);

export default enhance(Header);
