import React from 'react';
import PropTypes from 'prop-types';
import styles from './skeletons.scss';

const ITEM_TYPE = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape'
};

// GridSkeleton returns a grid of movie or show items to simulate that is trying to load elements.
// Depending of itemType will get different CSS styling.
const GridSkeleton = ({
  isCarousel,
  itemType = ITEM_TYPE.LANDSCAPE,
  withTitle,
  withSpacing,
  smallItems,
  // The skeleton is a little different for a movie or a show view
  isShow = false,
  isMyContent = false
}) => {
  const dir = 'ltr';

  const orientation =
    itemType === ITEM_TYPE.PORTRAIT ? ITEM_TYPE.PORTRAIT : ITEM_TYPE.LANDSCAPE;

  let itemsCount;
  if (isCarousel) {
    itemsCount = orientation === ITEM_TYPE.PORTRAIT ? 5 : 3;
  } else if (!isCarousel && (isShow || isMyContent)) {
    itemsCount = 4;
  } else {
    itemsCount = 5;
  }

  const seasons = 3;
  const baseColorStyle = styles['base-color'];
  const style = `${styles['item-skeleton']} ${styles[`${orientation}Skeleton`]}`;
  const itemStyle = smallItems ? `${style} ${styles['small-skeleton']}` : style;

  const containerStyle = `${withTitle ? styles['grid-skeleton'] : ''} ${
    withSpacing ? styles['spacing-grid'] : ''
  }`;

  const titleSkeletonStyle =
    withTitle && !isShow && !isMyContent
      ? `${styles['title-skeleton']}`
      : `${styles['title-skeleton-low-margin']}`;

  const posterListStyle =
    !isCarousel && !isShow && !isMyContent
      ? `${styles['poster-Llist']}`
      : `${styles['poster-List-Content-Left']}`;

  return (
    <div className={`${containerStyle} ${styles[dir]}`} dir={dir}>
      {withTitle && !isShow && (
        <div className={`${titleSkeletonStyle} ${baseColorStyle}`} />
      )}
      {!isCarousel && !isShow && (
        <div className={posterListStyle}>
          {[...Array(itemsCount)].map((item, index) => (
            <div
              key={`gridItem${index}${item}`}
              className={`${itemStyle} ${baseColorStyle}`}
            />
          ))}
        </div>
      )}
      {!isCarousel && isShow && (
        <div className={`${styles['show-container']}`}>
          <div className={`${styles['seasons-container']}`}>
            <div className={`${styles.text} ${baseColorStyle}`} />
            <div className={`${styles['seasons-list']}`}>
              {[...Array(seasons)].map((item, index) => (
                <div
                  key={`season${index}${item}`}
                  className={`${styles['season-item']} ${baseColorStyle}`}
                />
              ))}
            </div>
          </div>
          <div className={`${styles['show-poster-list']}`}>
            {[...Array(itemsCount)].map((item, index) => (
              <div
                key={`gridItem${index}${item}`}
                className={`${itemStyle} ${baseColorStyle}`}
              />
            ))}
          </div>
        </div>
      )}
      {isCarousel && (
        <div>
          {[...Array(itemsCount)].map((item, index) => (
            <div
              key={`gridItem${index}${item}`}
              className={`${itemStyle} ${baseColorStyle}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

GridSkeleton.propTypes = {
  itemType: PropTypes.string,
  isCarousel: PropTypes.bool,
  smallItems: PropTypes.bool,
  withTitle: PropTypes.bool,
  withSpacing: PropTypes.bool,
  // Get the skeleton for a movie or a show detail view
  isShow: PropTypes.bool,
  // Get the skeleton for My content view
  isMyContent: PropTypes.bool
};

GridSkeleton.item_type = ITEM_TYPE;

export default GridSkeleton;
