import React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import Container from '#/containers/Container/Container';
import ContainerWrapper from '#/containers/IntuitiveEditor/components/ContainerWrapper';
import { NOOP } from '#/utils/general';

const ContainerPage = ({
  containerConfig,
  containers,
  isUsingEditor,
  isEditionDisabled,
  location,
  onContainerClick,
  onContainerWrapperArrowClick,
  onContainerWrapperRemoveClick,
  onItemClick,
  selectedEditorEntry
}) => {
  const query = qs.parse((location.search || '').replace(/^\?/, ''));

  if (query.mode === 'edit') {
    return (
      <Droppable droppableId="containerPage">
        {providedTop => (
          <div
            style={{ minHeight: 600 }}
            {...providedTop.droppableProps}
            ref={providedTop.innerRef}
          >
            {(containers || []).map((container, index) => {
              return (
                <Draggable
                  draggableId={`${container.id}x`}
                  index={index}
                  isDragDisabled={isEditionDisabled}
                  key={container.id}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => onContainerClick(container)}
                      key={container.id}
                    >
                      <ContainerWrapper
                        container={container}
                        shouldHaveTopArrow={index !== 0 && !isEditionDisabled}
                        shouldHaveBottomArrow={
                          index < containers.length - 1 && !isEditionDisabled
                        }
                        onContainerWrapperArrowClick={
                          onContainerWrapperArrowClick
                        }
                        onContainerWrapperRemoveClick={
                          onContainerWrapperRemoveClick
                        }
                        selectedEditorEntry={selectedEditorEntry}
                      >
                        <Container
                          {...container}
                          isUsingEditor={isUsingEditor}
                          onItemClick={onItemClick}
                          selectedEditorEntry={selectedEditorEntry}
                          containerConfig={containerConfig}
                        />
                      </ContainerWrapper>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {providedTop.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  return (
    <React.Fragment>
      {(containers || []).map(container => {
        return (
          <div onClick={() => onContainerClick(container)} key={container.id}>
            <Container
              {...container}
              containerConfig={containerConfig}
              onItemClick={onItemClick}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
};

ContainerPage.propTypes = {
  containerConfig: PropTypes.object,
  containers: PropTypes.array,
  isUsingEditor: PropTypes.bool,
  isEditionDisabled: PropTypes.bool,
  location: PropTypes.object,
  onContainerClick: PropTypes.func,
  onContainerWrapperArrowClick: PropTypes.func,
  onContainerWrapperRemoveClick: PropTypes.func,
  onItemClick: PropTypes.func,
  selectedEditorEntry: PropTypes.object
};

ContainerPage.defaultProps = {
  onContainerClick: NOOP
};

export default ContainerPage;
