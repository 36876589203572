import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '#/redux/modules/platform';

import VikimapPage from '#/views/VikimapPage/VikimapPage';
import ElevatePage from '#/elevate/views/ElevatePage/ElevatePage';

const mapStateToProps = state => ({
  platform: selectors.getPlatform(state)
});

class Page extends Component {
  render() {
    const { platform } = this.props;

    return platform === 'ctv' ? (
      <ElevatePage {...this.props} />
    ) : (
      <VikimapPage {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(Page);
