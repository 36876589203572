import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@accedo/vdkweb-ui';

import Shelf from '#/elevate/components/Shelf';
import Button from '#/components/Button/Button';
import modules from '#/redux/modules';
import * as templates from '#/config/templates';

import * as spinnerTheme from './spinner.scss';

const SHOW_SPINNER_DELAY_MS = 200;

const mapStateToProps = (state, ownProps) => {
  const dataKey = ownProps.query;

  if (!dataKey) {
    return;
  }

  let data = modules.vikimapQueries.selectors.getData(state, dataKey);

  if (!data) {
    data = state[dataKey];
  }

  if (data && data.__isError) {
    return {
      dataKey,
      failedToLoad: data.content
    };
  }

  if (!data || !data.content || data.__isFetching) {
    return {
      dataKey,
      loaded: false,
      isLoading: data ? data.__isFetching : false
    };
  }

  return {
    dataKey,
    queryData: data.content,
    loaded: true
  };
};

class QueryDataShelf extends React.Component {
  static loadData({ dispatch, dataKey, failedToLoad, isLoading, staticData }) {
    if (failedToLoad || isLoading || staticData) {
      return;
    }

    if (!dataKey) {
      return;
    }

    return dispatch(modules.vikimapQueries.actions.getData(dataKey));
  }

  constructor() {
    super();
    this.state = { showSpinner: false };
  }

  componentDidMount() {
    QueryDataShelf.loadData(this.props);
  }

  componentDidUpdate(prevProps) {
    QueryDataShelf.loadData(this.props);
    const { loaded } = this.props;
    if (!loaded && !this.spinnerTimeoutId) {
      this.spinnerTimeoutId = setTimeout(
        () => this.setState({ showSpinner: true }),
        SHOW_SPINNER_DELAY_MS
      );
    } else if (!prevProps.loaded && loaded) {
      clearTimeout(this.spinnerTimeoutId);
      this.spinnerTimeoutId = null;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showSpinner: false });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.spinnerTimeoutId);
  }

  render() {
    const {
      containerId,
      queryData,
      loaded,
      isUsingEditor,
      template,
      failedToLoad,
      onRetry
    } = this.props;

    if (failedToLoad) {
      return <Button onClick={onRetry}>Failed to load. Please retry.</Button>;
    }

    return loaded ? (
      <Shelf
        config={this.props.config}
        containerId={containerId}
        isUsingEditor={isUsingEditor}
        itemType="query"
        items={queryData}
        onItemClick={this.props.onItemClick}
        selectedEditorEntry={this.props.selectedEditorEntry}
        template={template}
        templates={templates}
      />
    ) : (
      this.state.showSpinner && <Spinner theme={spinnerTheme} />
    );
  }
}

QueryDataShelf.propTypes = {
  config: PropTypes.object,
  containerId: PropTypes.string,
  failedToLoad: PropTypes.any,
  isUsingEditor: PropTypes.bool,
  loaded: PropTypes.any,
  onItemClick: PropTypes.func,
  onRetry: PropTypes.func,
  queryData: PropTypes.any,
  selectedEditorEntry: PropTypes.object,
  template: PropTypes.string
};

export default connect(mapStateToProps)(QueryDataShelf);
