import fetch from '@accedo/vdkweb-fetch';
import qs from 'qs';

import { getAPIBaseUrl } from '#/config/helpers';

const baseUrl = getAPIBaseUrl();

const requestVersioning = async (
  endpoint,
  { method = 'GET', headers = {}, body, query = null } = {}
) => {
  const queryString = query ? `?${qs.stringify(query)}` : '';
  const response = await fetch(
    `${baseUrl}editions-versioning/${endpoint}${queryString}`,
    {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      ...(body
        ? {
            body: JSON.stringify(body)
          }
        : {})
    }
  );

  try {
    const json = await response.json();

    return json;
  } catch (_) {
    return {};
  }
};

const getStatus = () => {
  return requestVersioning('health');
};

const init = () => {
  return requestVersioning('init');
};

const createEdition = ({ fromEditionId, name }) => {
  return requestVersioning('createEdition', {
    method: 'POST',
    body: {
      fromEditionId,
      name
    }
  });
};

const saveVersion = ({ editionId, data, name }) => {
  return requestVersioning('saveVersion', {
    method: 'POST',
    body: {
      editionId,
      data,
      name
    }
  });
};

const getAllEditions = () => {
  return requestVersioning('getAllEditions');
};

const rollbackEdition = ({ editionId }) => {
  return requestVersioning('rollbackEdition', {
    method: 'POST',
    body: {
      editionId
    }
  });
};

const removeEdition = ({ editionId }) => {
  return requestVersioning('removeEdition', {
    method: 'POST',
    body: {
      editionId
    }
  });
};

const compareEditions = ({ editionId, otherEditionId }) => {
  return requestVersioning('compareEditions', {
    query: {
      editionId,
      otherEditionId
    }
  });
};

const updateEditionMetadata = ({
  description,
  editionId,
  name,
  status,
  tags,
  updatedKeys
}) => {
  return requestVersioning('updateEditionMetadata', {
    method: 'POST',
    body: {
      description,
      editionId,
      name,
      status,
      tags,
      updatedKeys
    }
  });
};

const getEditionVersions = ({ editionId }) => {
  return requestVersioning(`editionVersions?editionId=${editionId}`);
};

const getEditionMetadata = ({ editionId }) => {
  return requestVersioning(`editionMetadata?editionId=${editionId}`);
};

const restoreVersion = ({ editionId, versionId }) => {
  return requestVersioning('restoreVersion', {
    method: 'POST',
    body: {
      editionId,
      versionId
    }
  });
};

export {
  compareEditions,
  createEdition,
  getAllEditions,
  getEditionMetadata,
  getEditionVersions,
  getStatus,
  init,
  removeEdition,
  restoreVersion,
  rollbackEdition,
  saveVersion,
  updateEditionMetadata
};
