import React from 'react';
import { Redirect } from 'react-router-dom'; // v5.2

import config from '#/config';
import ViewContainer from '#/containers/View/ViewContainer';
import Page from '#/containers/View/Page';
import DebugPage from '#/containers/View/DebugPage';
import {
  About,
  EditionsDashboard,
  MovieDetails,
  NoMatch,
  ToBeImplemented
} from '#/views';

import appConfig from './app';

const prefix = appConfig.urlPrefix;

const routes = [
  {
    component: ViewContainer,
    routes: [
      {
        path: `${prefix}`,
        exact: true,
        component: () => <Redirect to={`${prefix}home`} />
      },
      {
        path: `${prefix}movie/:id`,
        component: MovieDetails
      },
      {
        path: `${prefix}movie-details/:id`,
        component: MovieDetails
      },
      {
        path: `${prefix}tvshow/:id`,
        component: MovieDetails
      },
      {
        path: `${prefix}series-details/:id`,
        component: ToBeImplemented
      },
      {
        path: `${prefix}category/:id`,
        component: ToBeImplemented
      },
      {
        path: `${prefix}about`,
        component: About
      },
      {
        path: `${prefix}dashboard`,
        component: EditionsDashboard
      },
      {
        path: `${prefix}:id`,
        component: config.app.vikimap.debugMode ? DebugPage : Page
      },
      {
        path: `${prefix}*`,
        component: NoMatch
      }
    ]
  }
];

export default routes;
