import PropTypes from 'prop-types';

export const containersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    displayText: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.array,
    query: PropTypes.string,
    title: PropTypes.string.isRequired
  })
);

export const templatesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  })
);
