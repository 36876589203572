import { createAction as createActionCreator } from 'redux-actions';

export const UPDATE_MENU = 'vdk/vikimap/UPDATE_MENU';

const entryMetaCreator = (id, services, meta) => {
  return meta;
};

const toAction = (
  actionType,
  payloadCreator = x => x,
  metaCreator = entryMetaCreator
) => {
  return createActionCreator(actionType, payloadCreator, metaCreator);
};

export const updateMenu = toAction(UPDATE_MENU);
