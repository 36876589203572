import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-mini';

const createClickHandler = fn => e => {
  e.stopPropagation();
  fn();
};

const BUTTON_STYLE = {
  backgroundColor: '#000',
  border: '1px solid rgba(61,255,216,1)',
  borderRadius: 5,
  color: '#eee',
  cursor: 'pointer',
  fontSize: 11,
  height: 22,
  outline: 'none',
  width: 50
};

const ROLLBACK_TEXT_STYLE = { position: 'absolute', bottom: 0, right: 0 };
const NAME_TEXT_STYLE = { textAlign: 'left', margin: '0 0 5px' };

const getBackground = ({ isDragged, isRollback }) => {
  if (isDragged) {
    return 'rgba(20, 85, 72, 0.6)';
  }

  return isRollback ? 'lightblue' : 'lightgrey';
};

const getWrapperStyle = ({ isDragged, isRollback }) => ({
  background: getBackground({ isDragged, isRollback }),
  border: isDragged
    ? '2px solid rgba(61, 255, 216, 0.5)'
    : '2px solid lightgray',
  borderRadius: 3,
  color: isDragged ? 'rgba(61, 255, 216, 1)' : '#606060',
  height: 95,
  padding: '4px 8px',
  position: 'relative',
  userSelect: 'none',
  width: '100%'
});

const EditionsDashboardItem = ({
  columnId,
  isDragged,
  item,
  onOpenInEditorClick
}) => {
  return (
    <div style={getWrapperStyle({ isDragged, isRollback: item.isRollback })}>
      <h4 style={NAME_TEXT_STYLE}>{item.name}</h4>
      {item.isRollback && <span style={ROLLBACK_TEXT_STYLE}>Rollback</span>}
      <div
        style={{
          fontSize: 11,
          display: '-webkit-box',
          maxWidth: '100%',
          height: 26,
          marginBottom: 10,
          lineHeight: 1.2,
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {item.description}
      </div>
      <div style={{ fontSize: 10, color: '#888' }}>
        Edited on {moment(item.lastVersion.date).format('YYYY-MM-DD HH:mm:SS')}
      </div>
      <div style={{ position: 'absolute', right: 2, bottom: 2 }}>
        {columnId !== '3' && item.id !== 'master' && (
          <button
            style={BUTTON_STYLE}
            onClick={createClickHandler(() => onOpenInEditorClick?.(item))}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

EditionsDashboardItem.propTypes = {
  columnId: PropTypes.string,
  isDragged: PropTypes.bool,
  item: PropTypes.object,
  onOpenInEditorClick: PropTypes.func
};

export default EditionsDashboardItem;
