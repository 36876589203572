import { ACCEDO_ENTRIES_QUERY } from '../editorConstants';

const CUSTOM_QUERY_DETECTION = {
  NO: 0,
  MAYBE: 1,
  YES: 2
};

const DATA_SOURCE_OPTIONS = [
  {
    value: 'accedo-one',
    label: 'Accedo One',
    queryValue: ACCEDO_ENTRIES_QUERY
  },
  {
    value: 'accedo-ovp',
    label: 'Accedo OVP',
    collections: [
      {
        value: 'movies-categories',
        label: 'Movies Categories',
        queries: [
          ['movies-horror', 'Horror'],
          ['movies-war', 'War'],
          ['movies-animation', 'Animation'],
          ['movies-adventure', 'Adventure'],
          ['movies-tv-movie', 'TV Movie'],
          ['movies-action', 'Action'],
          ['movies-drama', 'Drama'],
          ['movies-comedy', 'Comedy'],
          ['movies-romance', 'Romance'],
          ['movies-documentary', 'Documentary'],
          ['movies-western', 'Western'],
          ['movies-crime', 'Crime'],
          ['movies-mystery', 'Mystery'],
          ['movies-fantasy', 'Fantasy'],
          ['movies-family', 'Family'],
          ['movies-science-fiction', 'Science Fiction'],
          ['movies-thriller', 'Thriller'],
          ['movies-music', 'Music'],
          ['movies-history', 'History']
        ].map(([id, title]) => ({
          // Old: moviesByCategory{movies-comedy}
          // New: /category/movies-comedy/movie
          // value: `moviesByCategory{${id}}`,
          value: `/category/${id}/movie`,
          label: `${title} Movies`
        }))
      },
      {
        value: 'movies-playlists',
        label: 'Movies Playlists',
        queries: [
          {
            label: 'All movies',
            value: '/movie/'
          }
        ]
      },
      {
        value: 'show-categories',
        label: 'Shows Categories',
        queries: [
          ['tvshows-drama', 'Drama'],
          ['tvshows-comedy', 'Comedy'],
          ['tvshows-reality', 'Reality'],
          ['tvshows-kids', 'Kids'],
          ['tvshows-documentary', 'Documentary'],
          ['tvshows-animation', 'Animation'],
          ['tvshows-soap', 'Soap'],
          ['tvshows-news', 'News'],
          ['tvshows-family', 'Family'],
          ['tvshows-crime', 'Crime'],
          ['tvshows-action-and-adventure', 'Action & Adventure'],
          ['tvshows-sci-fi-and-fantasy', 'Sci-Fi & Fantasy'],
          ['tvshows-mystery', 'Mystery'],
          ['tvshows-talk', 'Talk'],
          ['tvshows-war-and-politics', 'War & Politics'],
          ['tvshows-western', 'Western']
        ].map(([id, title]) => ({
          // Old: `tvshowsByCategory{${id}}`,
          value: `/category/${id}/tvshow`,
          label: `${title} Shows`
        }))
      },
      {
        convertInputValueToQuery: v => v,
        convertQueryToInputValue: v => v,
        detectQuery: () => CUSTOM_QUERY_DETECTION.MAYBE,
        label: 'Custom',
        queryType: 'input',
        value: 'ovp-custom'
      }
    ]
  },
  /*
  {
    value: 'brightcove',
    label: 'Brightcove',
    collections: [
      {
        label: 'Movies Categories',
        value: 'movies-categories',
        queries: [
          {
            value: 'brightcove:moviesByCategory{comedy}',
            label: 'Comedy Movies'
          },
          {
            value: 'brightcove:moviesByCategory{action}',
            label: 'Action Movies'
          },
          {
            value: 'brightcove:moviesByCategory{drama}',
            label: 'Drama Movies'
          }
        ]
      },
      {
        label: 'Playlists',
        value: 'playlists',
        queries: [
          {
            value: 'brightcove:moviesByPlaylistName{playlist_1}',
            label: 'Playlist 1'
          },
          {
            value: 'brightcove:moviesByPlaylistName{playlist_2}',
            label: 'Playlist 2'
          }
        ]
      },
      {
        convertInputValueToQuery: v => `brightcove:${v}`,
        convertQueryToInputValue: v => (v || '').replace(/^brightcove:/, ''),
        detectQuery: v =>
          /^brightcove:/.test(v)
            ? CUSTOM_QUERY_DETECTION.YES
            : CUSTOM_QUERY_DETECTION.NO,
        label: 'Custom',
        queryType: 'input',
        value: 'brightcove-custom'
      }
    ]
  },
  */
  /*
  {
    value: 'tmdb',
    label: 'TMDB',
    collections: [
      {
        label: 'Movies Categories',
        value: 'movies-categories',
        queries: [
          ['action', 'Action'],
          ['adventure', 'Adventure'],
          ['animation', 'Animation'],
          ['comedy', 'Comedy'],
          ['crime', 'Crime'],
          ['documentary', 'Documentary'],
          ['drama', 'Drama'],
          ['family', 'Family'],
          ['fantasy', 'Fantasy'],
          ['history', 'History'],
          ['horror', 'Horror'],
          ['music', 'Music'],
          ['mystery', 'Mystery'],
          ['romance', 'Romance'],
          ['science fiction', 'Science Fiction'],
          ['tv movie', 'TV'],
          ['thriller', 'Thriller'],
          ['war', 'War'],
          ['western', 'Western']
        ].map(([id, title]) => ({
          value: `tmdb:moviesByCategory{${id}}`,
          label: `${title} Movies`
        }))
      },
      {
        label: 'Shows Categories',
        value: 'shows-categories',
        queries: [
          ['action & adventure', 'Action & adventure'],
          ['animation', 'Animation'],
          ['comedy', 'Comedy'],
          ['crime', 'Crime'],
          ['documentary', 'Documentary'],
          ['drama', 'Drama'],
          ['family', 'Family'],
          ['kids', 'Kids'],
          ['mystery', 'Mystery'],
          ['news', 'News'],
          ['reality', 'Reality'],
          ['sci-fi & fantasy', 'Sci-fi & fantasy'],
          ['soap', 'Soap'],
          ['talk', 'Talk'],
          ['war & politics', 'War & politics'],
          ['western', 'Western']
        ].map(([id, title]) => ({
          label: `${title} Shows`,
          value: `tmdb:tvshowsByCategory{${id}}`
        }))
      },
      {
        label: 'Movies Playlists',
        value: 'movies-playlists',
        queries: [
          {
            value: 'tmdb:movies',
            label: 'Movies'
          },
          {
            value: 'tmdb:nowPlayingMovies',
            label: 'Now Playing Movies'
          },
          {
            value: 'tmdb:popularMovies',
            label: 'Popular Movies'
          },
          {
            value: 'tmdb:topRatedMovies',
            label: 'Top Rated Movies'
          },
          {
            value: 'tmdb:upcomingMovies',
            label: 'Upcoming Movies'
          }
        ]
      },
      {
        label: 'Shows Playlists',
        value: 'shows-playlists',
        queries: [
          {
            value: 'tmdb:tvshows',
            label: 'Shows'
          },
          {
            value: 'tmdb:popularTvShows',
            label: 'Popular Shows'
          },
          {
            value: 'tmdb:topRatedTvShows',
            label: 'Top Rated Shows'
          }
        ]
      }
    ]
  },
  */
  {
    value: 'placeholderData',
    label: 'None',
    queryValue: 'placeholderData'
  }
];

DATA_SOURCE_OPTIONS.push({
  label: 'Any',
  value: 'any',
  collections: DATA_SOURCE_OPTIONS.reduce((acc, dataSource) => {
    if (dataSource.collections) {
      dataSource.collections.forEach(col => {
        acc.push({
          ...col,
          label: `${dataSource.label}: ${col.label}`
        });
      });
    }

    return acc;
  }, [])
});

export { DATA_SOURCE_OPTIONS, CUSTOM_QUERY_DETECTION };
