import React from 'react';
import PropTypes from 'prop-types';

import useHover from '#/components/hooks/useHover';

import * as styles from './containerWrapper.scss';
import trashIcon from '#/static/images/remove-thrash-bin.png';
import chevronUp from '#/static/images/chevron-up.png';
import chevronDown from '#/static/images/chevron-down.png';

const ARROW_SIZE = 26;

const Arrow = ({ direction, onClick }) => {
  const isUp = direction === 'up';

  return (
    <div
      style={{
        ...(isUp ? { top: -18 } : { bottom: -20 })
      }}
      className={styles.arrowWrapper}
    >
      <div
        style={{
          cursor: 'pointer',
          lineHeight: isUp ? '30px' : '30px'
        }}
        onClick={onClick}
        className={styles.arrowContent}
      >
        <img
          src={isUp ? chevronUp : chevronDown}
          style={{ width: ARROW_SIZE, height: ARROW_SIZE }}
          alt={isUp ? 'move up' : 'move down'}
        />
      </div>
    </div>
  );
};

Arrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down']),
  onClick: PropTypes.func
};

const Trash = ({ onClick }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${trashIcon})`
      }}
      className={styles.trash}
      onClick={onClick}
    />
  );
};

Trash.propTypes = {
  onClick: PropTypes.func
};

const FULL_BORDER = 3;
const HOVER_BORDER = 1;

const getContainerBorder = ({ isSelected, isHovered }) => {
  if (isSelected) {
    return `${FULL_BORDER}px solid rgba(61, 255, 216, 0.5)`;
  }

  if (isHovered) {
    return `${HOVER_BORDER}px solid rgb(61, 255, 216)`;
  }

  return `${FULL_BORDER}px solid transparent`;
};

const ContainerWrapper = ({
  children,
  container,
  onContainerWrapperArrowClick,
  selectedEditorEntry,
  shouldHaveBottomArrow,
  shouldHaveTopArrow,
  onContainerWrapperRemoveClick
}) => {
  const [hoverRef, isHovered] = useHover();
  const isSelected =
    !!container.id &&
    (selectedEditorEntry?.id === container.id ||
      selectedEditorEntry?.parentContainer?.id === container.id);
  const onArrowClick = direction => {
    onContainerWrapperArrowClick(container, direction);
  };
  const onUpClick = () => onArrowClick('up');
  const onDownClick = () => onArrowClick('down');
  const onRemoveClick = () => onContainerWrapperRemoveClick(container);

  return (
    <div
      className={`${styles.wrapper}${isSelected?' container-selected':''}`}
      ref={hoverRef}
      style={{
        background: isSelected ? 'rgba(20, 85, 72, 0.6)' : '',
        border: getContainerBorder({ isSelected, isHovered }),
        padding: isHovered && !isSelected ? FULL_BORDER - HOVER_BORDER : 0
      }}
    >
      {isSelected && shouldHaveTopArrow && (
        <Arrow direction="up" onClick={onUpClick} />
      )}
      {isSelected && onContainerWrapperRemoveClick && (
        <Trash onClick={onRemoveClick} />
      )}
      {children}
      {isSelected && shouldHaveBottomArrow && (
        <Arrow direction="down" onClick={onDownClick} />
      )}
    </div>
  );
};

ContainerWrapper.propTypes = {
  children: PropTypes.node,
  container: PropTypes.object,
  onContainerWrapperArrowClick: PropTypes.func,
  shouldHaveTopArrow: PropTypes.bool,
  shouldHaveBottomArrow: PropTypes.bool,
  selectedEditorEntry: PropTypes.object,
  onContainerWrapperRemoveClick: PropTypes.func
};

export default ContainerWrapper;
