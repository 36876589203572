import { handleActions } from 'redux-actions';
import { SET_PLATFORM } from './actions';

/**
 * The initial state if no state is provided.
 * @type {Object}
 */
const initialState = {
  platform: 'ctv'
};

const setPlatform = (state = initialState, action) => {
  const platforms = ["desktop", "mobile", "ctv"];

  if (platforms.includes(action.payload)) {
    return {
      ...state,
      platform: action.payload
    };
  }
  
  return {
    ...state,
    platform: 'desktop'
  };
};

const reducer = handleActions(
  {
    [SET_PLATFORM]: setPlatform
  },
  initialState
);

export default reducer;
