import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import dragAndDropIcon from '#/static/images/drag-and-drop.svg';
import { IMAGES_MAP } from '#/containers/IntuitiveEditor/components/ContainerCreator';
import handleIcon from '#/static/images/reorder-rearrange-transp.png';

import { PanelTitle } from './Panel';
import Divider from './Divider';
import { containersPropType } from './commonPropTypes';
import * as styles from './minimap.scss';
import Button, { BUTTON_TYPE } from './Button';

const getImageUrlById = id => {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    return `url("${IMAGES_MAP[id]}")`;
  } catch (_) {
    console.log(`Unsupported image: ${id}`);
    return `url("${require(`#/static/images/hero-banner-component.png`)}")`;
  }
};

const getBackgroundTemplateImage = id => ({
  backgroundImage: getImageUrlById(id),
  backgroundSize: 'cover'
});

const Minimap = ({
  containers,
  isEditionDisabled,
  onAddNewContainer,
  onContainerClick,
  selectedEntryId
}) => {
  return (
    <Droppable droppableId="minimap">
      {provided => (
        <div className={styles.wrapper}>
          <Divider icon={dragAndDropIcon} style={{ marginTop: 20 }} />
          <PanelTitle>Mini-Map</PanelTitle>
          <div className={styles.description}>
            The minimap shows the structure of your page. You can add, rearrange
            and remove the containers according to your needs.
          </div>
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={styles.mmBlock}
          >
            {containers.map((container, containerIndex) => (
              <Draggable
                draggableId={container.id}
                index={containerIndex}
                isDragDisabled={isEditionDisabled}
                key={container.id}
              >
                {(_provided, _snapshot) => (
                  <div
                    ref={_provided.innerRef}
                    {..._provided.draggableProps}
                    {..._provided.dragHandleProps}
                    style={{
                      ..._provided.draggableProps.style,
                      cursor: isEditionDisabled ? 'default' : 'pointer'
                    }}
                    className={[
                      _snapshot.isDragging ? styles.dragging : '',
                      styles.mmContainer,
                      container.id === selectedEntryId ? styles.selected : ''
                    ].join(' ')}
                    onClick={() => onContainerClick(container)}
                  >
                    <div className={styles.mmIndex}>{containerIndex + 1}</div>
                    <div className={styles.mmComponent}>
                      <div
                        className={styles.mmComponentImg}
                        style={getBackgroundTemplateImage(container.template)}
                      />
                      <div className={styles.mmComponentDetails}>
                        <b>{container.displayText}</b>
                        <p>{container.template}</p>
                      </div>
                    </div>
                    <div
                      className={styles.handle}
                      style={{
                        background: `url("${handleIcon}")`,
                        backgroundSize: 'cover'
                      }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
          <div style={{ display: 'flex', padding: '10px 48px 10px 38px' }}>
            <Button
              disabled={isEditionDisabled}
              onClick={onAddNewContainer}
              style={{ flexGrow: 1 }}
              type={BUTTON_TYPE.EMPTY}
            >
              + Add New Container
            </Button>
          </div>
        </div>
      )}
    </Droppable>
  );
};

Minimap.propTypes = {
  containers: containersPropType,
  isEditionDisabled: PropTypes.bool,
  onAddNewContainer: PropTypes.func,
  onContainerClick: PropTypes.func,
  selectedEntryId: PropTypes.string
};

Minimap.defaultProps = {
  onAddNewContainer: () => null
};

export default Minimap;
