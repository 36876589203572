import { validateHasDefaultKey } from '#/utils/validations';

const ElevateViewsIds = {
  AccedoOneContainer: 'AccedoOneContainer',
  Default: 'Default'
};

validateHasDefaultKey(ElevateViewsIds);

export default ElevateViewsIds;
