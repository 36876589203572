import React from 'react';
import PropTypes from 'prop-types';
import FocusDiv from '@accedo/vdkweb-tv-ui/lib/FocusDiv/FocusDiv';

import AssetItem from '#/elevate/components/Item/AssetItem/AssetItem';
import { ITEM_SIZE } from '#/elevate/components/Item/itemHelper';
import ContentGrid from '#/elevate/components/ContentGrid/ContentGrid';
import getResolution from '#/utils/getResolution';
import { R720p } from '#/elevate/theme/variables/breakpoints';
import { getGridSettingMap } from './utils';

import shelfTheme from './gridShelf.scss';

const GRID_ALIGNMENT = {
  VERTICAL: 'grid-align-vertical',
  HORIZONTAL: 'grid-align-horizontal'
};
const { width: screenWidht } = getResolution();
const ASSET_ITEM_MARGIN = screenWidht > R720p ? 10 : 7;

const getNavIds = navId => ({
  CONTAINER_ID: navId,
  SPOTLIGHT_ITEM_ID: `${navId}-SPOTLIGHT_ITEM`,
  GRID_ID: `${navId}-GRID`
});

const checkEnableSpotlight = template => template.includes('asym');

const Separator = () => {
  return (
    <div style={{ display: 'inline-block', width: ASSET_ITEM_MARGIN * 2 }} />
  );
};

const separatorEl = <Separator />;

const GridShelf = ({
  onItemClick,
  keyProperty = 'id',
  config,
  itemType,
  enableSpotlight,
  items,
  nav,
  DisplayObject = AssetItem,
  theme = shelfTheme,
  gridAlignment = GRID_ALIGNMENT.HORIZONTAL,
  assetItemMargin = ASSET_ITEM_MARGIN,
  selectedEditorEntry,
  containerId
}) => {
  const { id: shelfId, template } = config;

  const navIds = getNavIds(shelfId);
  const { CONTAINER_ID, SPOTLIGHT_ITEM_ID, GRID_ID } = navIds;

  const itemSizeDefault = ITEM_SIZE[itemType];

  // We would check if `enableSpotlight is explicitly set.
  // Otherwise we will check template config to evaluatate
  // if we should use spotlight.
  enableSpotlight =
    enableSpotlight !== undefined
      ? enableSpotlight
      : checkEnableSpotlight(template);

  const itemSize = { ...itemSizeDefault };

  const getComponentNav = navId => {
    const compNavs = {
      [CONTAINER_ID]: {
        ...nav,
        id: CONTAINER_ID
      },
      [SPOTLIGHT_ITEM_ID]: {
        id: SPOTLIGHT_ITEM_ID,
        parent: CONTAINER_ID,
        nextright: GRID_ID
      },
      [GRID_ID]: {
        id: GRID_ID,
        parent: CONTAINER_ID,
        nextleft: enableSpotlight ? SPOTLIGHT_ITEM_ID : '',
        useLastFocus: true
      }
    };

    return compNavs[navId];
  };

  const getContainer = children => {
    return (
      <div
        className={theme['inner-container']}
      >
        {children}
      </div>
    );
  };

  const getAssetItem = () => {
    const { width, height } = itemSize;

    return (
      <AssetItem
        nav={getComponentNav(SPOTLIGHT_ITEM_ID)}
        data={items[0]}
        width={width * 2 + ASSET_ITEM_MARGIN}
        height={height * 2 + ASSET_ITEM_MARGIN * 2}
        onClick={() => onItemClick(items[0])}
      />
    );
  };

  const getFocusGrid = () => {
    const gridConfig = getGridSettingMap(itemType, false);

    const { width: itemWidth, height: itemHeight } = itemSize;
    const {
      headPadding,
      tailPadding,
      crossOffset,
      onChange,
      useInternalArrows,
      gridRef: ref,
      initialState,
      spacing,
      width = 1835,
      height = 860
    } = config;

    return (
      <ContentGrid
        theme={theme}
        ref={ref}
        onChange={onChange}
        useInternalArrows={useInternalArrows}
        nav={getComponentNav(GRID_ID)}
        initialState={{
          position: 0,
          id: items?.[0]?.[keyProperty],
          ...initialState
        }}
        dir={'ltr'}
        keyProperty={keyProperty}
        horizontal={gridAlignment === GRID_ALIGNMENT.HORIZONTAL}
        vertical={gridAlignment === GRID_ALIGNMENT.VERTICAL}
        width={
          gridAlignment === GRID_ALIGNMENT.HORIZONTAL
            ? width
            : Math.min(
                width,
                (itemWidth + assetItemMargin * 2) * gridConfig.col
              )
        }
        height={Math.min(
          height,
          (itemHeight + assetItemMargin * 2) * gridConfig.row
        )}
        itemWidth={itemWidth}
        itemHeight={itemHeight}
        data={items}
        buffer={3}
        spacing={spacing || assetItemMargin * 2}
        headPadding={headPadding || 0}
        tailPadding={tailPadding || 30}
        crossOffset={crossOffset || 0}
        onClick={onItemClick}
        DisplayComponent={DisplayObject}
        displayComponentProps={{
          type: itemType,
          style: { itemWidth, itemHeight },
          containerId
        }}
        selectedEditorEntry={selectedEditorEntry}
      />
    );
  };

  return (
    <FocusDiv
      className={theme['outer-container']}
    >
      {enableSpotlight
        ? getContainer(
            <>
              {getAssetItem()}
              {separatorEl}
              {getFocusGrid()}
            </>
          )
        : getFocusGrid()}
    </FocusDiv>
  );
};

GridShelf.propTypes = {
  onItemClick: PropTypes.func,
  items: PropTypes.array,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayText: PropTypes.string,
    template: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    itemOptions: PropTypes.object,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headPadding: PropTypes.number,
    tailPadding: PropTypes.number,
    crossOffset: PropTypes.number,
    onChange: PropTypes.func,
    useInternalArrows: PropTypes.bool,
    gridRef: PropTypes.object,
    initialState: PropTypes.object,
    spacing: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    dir: PropTypes.string
  }).isRequired,
  keyProperty: PropTypes.string,
  nav: PropTypes.object,
  itemType: PropTypes.string,
  theme: PropTypes.object,
  enableSpotlight: PropTypes.bool,
  DisplayObject: PropTypes.func,
  gridAlignment: PropTypes.string,
  assetItemMargin: PropTypes.number,
  selectedEditorEntry: PropTypes.object,
  containerId: PropTypes.string
};

GridShelf.GRID_ALIGNMENT = GRID_ALIGNMENT;

export default GridShelf;
