import fetch from '@accedo/vdkweb-fetch';

import { getAPIBaseUrl } from '#/config/helpers';

const baseUrl = getAPIBaseUrl();

let queue = Promise.resolve();

const mergeDefinedProperties = (objA, objB) => {
  return {
    ...objA,
    ...Object.keys(objB).reduce((acc, key) => {
      if (typeof objB[key] !== 'undefined') {
        acc[key] = objB[key];
      }

      return acc;
    }, {})
  };
};

// this is NOT for management api. It applies similar parsing of entries as
// vdkweb-vikimap
const parseAccedoOneAPIEntry = entry => {
  const newEntry = { ...entry };

  newEntry.displayText = newEntry.displaytext;
  newEntry.id = newEntry._meta.id;
  newEntry.alias = newEntry._meta.entryAlias;

  delete newEntry.displaytext;

  return newEntry;
};

const requestEntriesEditor = ({ uri, body }) => {
  return new Promise((resolve, reject) => {
    const fn = async () => {
      const data = await fetch(`${baseUrl}editor${uri}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        credentials: 'same-origin',
        locale: 'en'
      });

      // TODO: this try catch fails very often, if not always(?),
      // because the called methods don't return anything.
      // Investigate if there are any cases where we get a json response.
      // See src/server/editorMiddleware/index.js and
      // src/server/editorMiddleware/updateHandlers/accedo/middlewareFeedManagement.js

      try {
        const json = await data.json();
        resolve(json);
      } catch (e) {
        console.error('[editorClient] requestEntriesEditor', e, uri, body, data);
        resolve({});
      }
    };

    queue = queue.then(fn).catch(reject);
  });
};

const changeEntry = ({ entryId, values, alias, editionId, entryType }) => {
  return requestEntriesEditor({
    uri: '/changeEntry',
    body: mergeDefinedProperties(
      {
        entryId,
        editionId,
        entryType
      },
      {
        values,
        alias
      }
    )
  });
};

const resetEntry = ({ entryId, values, alias, contentTypeId, editionId }) => {
  return requestEntriesEditor({
    uri: '/resetEntry',
    body: {
      alias,
      contentTypeId,
      editionId,
      entryId,
      values: values.map(v => ({
        ...v,
        ...(typeof v.value === 'undefined' || v.value === ''
          ? { resetToDefault: true, value: undefined }
          : {})
      }))
    }
  });
};

const createEntryContainerInPage = async ({
  container,
  containersIds,
  editionId,
  pageId
}) => {
  const { id } = await requestEntriesEditor({
    uri: '/createContainer',
    body: {
      container,
      containersIds,
      // ToDo: How should editionId be handled when using Accedo One as backend?
      editionId: editionId || 'abcdef',
      pageId,
      locale: 'en'
    }
  });

  return id;
};

const createEntryItemInMenu = async ({
  menuItem,
  menuItemsIds,
  editionId,
  menuId
}) => {
  const { id } = await requestEntriesEditor({
    uri: '/createMenuItem',
    body: {
      menuItem,
      menuItemsIds,
      // ToDo: How should editionId be handled when using Accedo One as backend?
      editionId: editionId || 'abcdef',
      menuId,
      locale: 'en'
    }
  });

  return id;
};

const removeEntry = ({ entryId, editionId }) => {
  return requestEntriesEditor({
    uri: '/removeEntry',
    body: {
      editionId,
      entryId
    }
  });
};

const createEntryPage = ({ page, editionId }) => {
  return requestEntriesEditor({
    uri: '/createEntryPage',
    body: { page, editionId }
  });
};

const getAllItems = async ({ editionId }) => {
  const items = await requestEntriesEditor({
    uri: '/getAllItems',
    body: {
      editionId
    }
  });

  items.accedoData = items.accedoData?.map(parseAccedoOneAPIEntry);

  return items;
};

const getAllMenus = async ({ editionId }) => {
  const menus = await requestEntriesEditor({
    uri: '/getAllMenus',
    body: {
      editionId
    }
  });

  return menus.map(parseAccedoOneAPIEntry);
};

const getAllPages = async ({ editionId }) => {
  const pages = await requestEntriesEditor({
    uri: '/getAllPages',
    body: {
      editionId
    }
  });

  return pages.map(parseAccedoOneAPIEntry);
};

export {
  changeEntry,
  createEntryContainerInPage,
  createEntryItemInMenu,
  createEntryPage,
  getAllItems,
  getAllMenus,
  getAllPages,
  removeEntry,
  resetEntry
};
