/**
 * Creates a Vikimap service which fetches Vikimap content from a given source.
 *
 * There are few different ways of initiating your Vikimap service:
 *
 * - vikimap.getAccedoOneService(accedoOneClient)
 * - vikimap.getMiddlewareService(middlewareRelativePath | middlewareAbsoluteUrl)
 * - vikimap.getJsonService(jsonObject | Promise)
 *
 * Examples of each can be seen below:
 *
 * ### Directly from Accedo One (without middleware proxy)
 *
 * ```
 * import { getAccedoOneClient } from '../accedoOne/accedoOne';
 * const vikimapService = vikimap.getAccedoOneService(getAccedoOneClient());
 * ````
 *
 * ### With middleware using a relative path
 * (NOTE: this won't work with server side rendering where fetch is expecting an absolute path)
 *
 *  ```
 * const vikimapService = vikimap.getMiddlewareService('/vikimap');
 * ````
 *
 * ### With middleware using an abslute URL
 *
 *  ```
 * const vikimapService = vikimap.getMiddlewareService('http://localhost:3000/vikimap');
 * ````
 *
 * ### Synchronous JSON load
 *
 * ```
 * import json from './map.json';
 * const vikimapService = vikimap.getJsonService(json);
 * ```
 *
 * ### Asynchronous JSON load
 * (getRemoteJson() returns a Promise)
 *
 * ```
 * import { getRemoteJson } from '<your-remote-json-service>';
 * const vikimapService = vikimap.getJsonService(getRemoteJson());
 * ```
 *
 * Note that you can also serve different services to different contexts.
 * E.g. you can use the global __CLIENT__ check to see if this is rendered
 * on the client and return a relative path middleware service. Otherwise
 * return an Accedo One service.
 *
 * ```
 * let vikimapService;
 * if (typeof __CLIENT__ !== 'undefined' && __CLIENT__) {
 *   return vikimap.getMiddlewareService('/vikimap');
 * }
 *
 * return vikimap.getAccedoOneService(accedoOneClient);
 * ```
 *
 * @returns {VikimapService} A Vikimap service for fetching Vikimap content
 */

// import * as vikimap from '@accedo/vdkweb-vikimap';

// const getVikimapService = accedoOneClient => {
//   return vikimap.getAccedoOneService(accedoOneClient);
// };

// export default getVikimapService;

import fetch from '@accedo/vdkweb-fetch';
import * as vikimap from '@accedo/vdkweb-vikimap';

const getVersioningService = ({ edition, onHomeRedirect }) => {
  const getJSON = async () => {
    const url = process.env.VERSIONING_SERVICE_URL.replace(/\/$/, '').concat(
      '/'
    );
    let fullUrl = `${url}editions-versioning/editionData?platformId=web&${
      edition ? `editionId=${edition}` : ''
    }`;

    if (__CLIENT__ && localStorage) {
      const rawItem = localStorage.getItem('versionId');
      const versionId = rawItem ? JSON.parse(rawItem) : null;

      fullUrl += versionId ? `&versionId=${versionId}` : '';
    }

    const response = await fetch(fullUrl);

    if (response.status === 500) {
      onHomeRedirect();
    }

    return response.json();
  };

  return Promise.resolve().then(() => vikimap.getJsonService(getJSON()));
};

const getVikimapService = (query, accedoOneClient, onHomeRedirect) => {
  const { edition } = query;

  if (
    !process.env.VERSIONING_SERVICE_URL ||
    (!edition && !process.env.DEFAULT_TO_PUBLISHED_EDITION)
  ) {
    return vikimap.getAccedoOneService(accedoOneClient);
  }

  const getService = () => {
    if (process.env.VERSIONING_SERVICE_URL) {
      return getVersioningService({ edition, onHomeRedirect });
    }

    return Promise.resolve().then(() =>
      vikimap.getAccedoOneService(accedoOneClient)
    );
  };

  return {
    getMenu: (...args) => {
      return getService()
        .then(service => {
          return service.getMenu(...args);
        })
        .catch(e => {
          console.error('[getVikimapService] getMenu', e);
          return {};
        });
    },
    getPageByAlias: (...args) => {
      return getService()
        .then(service => {
          return service.getPageByAlias(...args);
        })
        .catch(e => {
          console.error('[getVikimapService] getPageByAlias', e);
          return {};
        });
    }
  };
};

export default getVikimapService;
