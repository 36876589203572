import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useSelector, useDispatch, connect } from 'react-redux';
import reduxModules from '#/redux/modules';
import kebabCase from 'lodash/kebabCase';
import { MENUITEM_STATE } from '#/containers/IntuitiveEditor/components/editorConstants';

import { redirectToUrl, createRandomString } from '#/utils/general';
import { menuItemEntryTypeId } from '#/config/accedoOneManagement';
import {
  changeEntry,
  createEntryPage,
  getAllMenus,
  getAllPages,
  removeEntry,
  resetEntry,
  createEntryItemInMenu
} from '#/services/editorClient/editorClient';
import { getTargetForMenuItem } from '#/containers/Menu/vikimapMenuHelpers';
import withConfig from '#/containers/utils/withConfig';
import appSwitcher from '#/config/appSwitcher';
import PageModalComp from './components/PageModal';
import {
  getEditionFromLocation,
  getHelperMenuStructures,
  getMenuPages,
  createUnsavedEntryId
} from './intuitiveEditorHelpers';

const getValuesFromEntry = (entry) => {
  return Object.keys(entry)
    .map((key) => {
      let newKey = key;

      if (newKey === 'displayText') {
        newKey = 'displaytext';
      }

      if (['id', '_meta', 'icon', 'alias', 'menuitems'].includes(key)) {
        return null;
      }

      return {
        key: newKey,
        ...(newKey === 'displaytext' ? { locale: 'en' } : {}),
        value: entry[key]
      };
    })
    .filter((p) => !!p);
};

const defaultPages = [];
const defaultMenus = [];

const getPageIdToEntryMap = (pages) => {
  return pages.reduce((acc, page) => {
    acc[page.id] = page;

    return acc;
  }, {});
};

const mapActionsToProps = {
  updateMenuData: reduxModules.vikimap.actions.updateMenu
};

const PageModal = ({
  currentPageEntry,
  location,
  config,
  updateMenuData,
  ...props
}) => {
  const compRef = useRef({});

  const [pageTitleValue, setPageTitle] = useState('');
  const [pages, setPages] = useState(defaultPages);
  const [menus, setMenus] = useState(defaultMenus);
  const [menuPagesState, setMenuPagesState] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [isCreatePageDisabled, setIsCreatePageDisabled] = useState(false);

  const menuItemsObject = useSelector((state) => state.modules.vikimap.menuItems);
  const pagesObject = useSelector((state) => state.modules.vikimap.pages);
  const editionId = getEditionFromLocation(location);
  const mainMenuId = config.accedoOne.mainMenuEntryId;

  const dispatch = useDispatch();

  // Initialise variables
  useEffect(() => {
    // mainMenuItemsObj is an object with itemIds as keys to each menuItem
    setMenuPagesState(getMenuPages({ menuItemsObject }));
    // parsedMenuItems and menuItems are arrays of menuItem objects
    const { parsedMenuItems } = getHelperMenuStructures({
      menuItemsObject,
      pagesObject
    });
    setMenuItems(parsedMenuItems);
    console.info('[PageModal] Initialise variables', parsedMenuItems);
  }, []);

  useEffect(() => {
    const fn = async () => {
      const [pagesResponse, menusResponse] = await Promise.all([
        getAllPages({ editionId }),
        getAllMenus({ editionId })
      ]);

      const pageIdToEntryMap = getPageIdToEntryMap(pagesResponse);

      const parsedMenus = menusResponse.map((menuItem) => {
        return {
          ...menuItem,
          page: pageIdToEntryMap[menuItem.page]
        };
      });

      // sometimes response from A1 didn't give the current page if just
      // created
      const hasCurrentPage = !!pagesResponse.find(
        (p) => p.id === currentPageEntry.id
      );

      setPages(pagesResponse.concat(hasCurrentPage ? [] : [currentPageEntry]));
      setMenus(parsedMenus);
    };

    fn();
  }, [currentPageEntry?.id]);

  compRef.current.urlValue = compRef.current.urlValue || createRandomString();
  const { urlValue } = compRef.current;

  const handleMenuChange = ({ menu, value, currentPageId }) => {
    const currentPage = getPageIdToEntryMap(pages)[currentPageId];
    let newPages = pages.slice(0);

    if (currentPage?.alias) {
      changeEntry({
        entryId: currentPage.id,
        editionId,
        alias: createRandomString()
      });

      newPages = newPages.map((page) =>
        page.id === currentPage.id
          ? {
              ...page,
              alias: ''
            }
          : page
      );

      setPages(newPages);
    }

    if (!value) {
      const newMenus = menus.map((m) =>
        m.id === menu.id
          ? {
              ...m,
              page: null
            }
          : m
      );

      setMenus(newMenus);

      resetEntry({
        entryId: menu.id,
        editionId,
        alias: menu.alias,
        contentTypeId: menuItemEntryTypeId,
        values: getValuesFromEntry({
          ...menu,
          page: undefined
        })
      });

      return;
    }

    const newSelectedPage = getPageIdToEntryMap(newPages)[value];

    newSelectedPage.alias = currentPage?.alias || kebabCase(menu.displayText);

    const newMenus = menus.map((m) =>
      m.id === menu.id
        ? {
            ...m,
            page: newSelectedPage
          }
        : m
    );

    setMenus(newMenus);

    changeEntry({
      entryId: newSelectedPage.id,
      editionId,
      alias: newSelectedPage.alias
    });

    newPages = newPages.map((p) =>
      p.id === newSelectedPage.id ? newSelectedPage : p
    );

    setPages(newPages);

    changeEntry({
      entryId: menu.id,
      editionId,
      values: [
        {
          key: 'page',
          value: newSelectedPage.id
        }
      ]
    });
  };

  const handleCreatePageClick = async (template) => {
    setIsCreatePageDisabled(true);
    const result = await createEntryPage({
      editionId,
      page: {
        title: pageTitleValue,
        alias: urlValue,
        template
      }
    });

    if (result.error) {
      setIsCreatePageDisabled(false);
      setPageTitle('');
      // @TODO: Handle error
    }

    let url = `/${urlValue}?mode=edit`;
    if (appSwitcher.backend === 'git') {
      url += `&edition=${editionId}`;
    }

    redirectToUrl(url);
  };

  const updateMenu = async (nextMenuItemsObject, nextMenuItems, nextMenuPagesState) => {
    const menuItemIds = nextMenuItems.map(item => item.id);
    await dispatch(
      updateMenuData({
        id: mainMenuId,
        items: menuItemIds,
        menuItems: nextMenuItemsObject
      })
    );
    setMenuPagesState(nextMenuPagesState);
  };

  // Used in the page section to add a menuItem for the page into the main menu
  const handleCreateMenuItemClick = async ({ page }) => {
    if (Object.keys(menuItemsObject).indexOf(page.id) > -1) {
      console.warn('Attempt to add duplicate page in menu!');
      return;
    }
    setIsCreatePageDisabled(true);
    setMenuPagesState({
      ...menuPagesState,
      ...{ [page.id]: MENUITEM_STATE.PENDING }
    });
    try {
      const tempMenuItemId = createUnsavedEntryId();

      const newMenuItem = {
        id: tempMenuItemId,
        displayText: page.displayText || page.title,
        title: page.title || `MenuItem - New ${createRandomString()}`,
        itemGroup: 'primary',
        requiresAuthentication: false,
        route: tempMenuItemId,
        page
      };

      const newMenuItems = menuItems.slice(0);

      newMenuItems.splice(menuItems.length, 0, newMenuItem);

      const newId = await createEntryItemInMenu({
        menuItem: newMenuItem,
        menuItemsIds: newMenuItems.map((c) => c.id),
        editionId,
        menuId: mainMenuId
      });

      const menuItemsWithNewId = newMenuItems.map((menuItem) => {
        return menuItem.id === newMenuItem.id
          ? {
              ...menuItem,
              id: newId
            }
          : menuItem;
      });

      setMenuItems(menuItemsWithNewId);
      const menuItemWithNewId = menuItemsWithNewId.find((c) => c.id === newId);
      // setSelectedMenuItem(menuItemWithNewId);

      menuItemWithNewId.page = menuItemWithNewId.page.id;
      const nextMenuItemsObject = {
        ...menuItemsObject,
        ...{ [menuItemWithNewId.id]: menuItemWithNewId }
      };

      const nextMenuPagesState = {
        ...menuPagesState,
        ...{ [page.id]: MENUITEM_STATE.LOADED }
      };

      updateMenu(nextMenuItemsObject, menuItemsWithNewId, nextMenuPagesState);
    } catch (e) {
      setIsCreatePageDisabled(false);
      console.error(`[PageModal (container)] createEntryMenuItem ${e}`);
      // setPageTitle('');
      // @TODO: Handle error
    }
  };

  // Used in the page section to remove a menuItem for a page from the main menu
  const handleRemoveMenuItemClick = async ({ page }) => {
    setIsCreatePageDisabled(true);

    let itemId;
    let pageId;
    let nextMenuItemsObject = { ...menuItemsObject };
    Object.entries(nextMenuItemsObject).forEach(([id, item]) => {
      if (item.page === page.id) {
        itemId = id;
        pageId = page.id;
        delete nextMenuItemsObject[id];
      }
    });
    setMenuPagesState({
      ...menuPagesState,
      ...{ [pageId]: MENUITEM_STATE.DELETED }
    });
    try {
      await changeEntry({
        entryId: mainMenuId,
        entryType: 'menu',
        editionId,
        values: [
          {
            key: 'items',
            value: Object.keys(nextMenuItemsObject)
          }
        ]
      });
      await removeEntry({ entryId: itemId, editionId });
      const { parsedMenuItems } = getHelperMenuStructures({
        menuItemsObject: nextMenuItemsObject,
        pagesObject
      });
      setMenuItems(parsedMenuItems);
      const nextMenuPagesState = { ...menuPagesState };
      delete nextMenuPagesState[pageId];
      updateMenu(nextMenuItemsObject, parsedMenuItems, nextMenuPagesState);
    } catch (e) {
      setMenuPagesState({
        ...menuPagesState,
        ...{ [pageId]: MENUITEM_STATE.LOADED }
      });
      setIsCreatePageDisabled(false);
      console.error(`[PageModal (container)] removeEntryMenuItem ${e}'}`);
      // setPageTitle('');
      // @TODO: Handle error
    }
  };

  const handlePageTitleClick = (page) => {
    const edition = getEditionFromLocation(location);

    redirectToUrl(`/${page._meta.entryAlias}?mode=edit&edition=${edition}`);
  };

  const handlePageDeleteClick = async (page) => {
    const newPages = pages.filter((p) => p.id !== page.id);

    // optimistically remove the page
    setPages(newPages);

    const promise = removeEntry({
      editionId,
      entryId: page.id
    });

    if (page.id === currentPageEntry.id) {
      const newAlias = newPages[0]._meta.entryAlias;
      const newUrl = `/${newAlias}?mode=edit`;

      redirectToUrl(newUrl);
    }

    const result = await promise;

    if (result.error) {
      setPages(pages);
      // @TODO: Handle error
    }
  };

  return (
    <PageModalComp
      {...props}
      currentPageEntry={currentPageEntry}
      getTargetForMenuItem={getTargetForMenuItem}
      isCreatePageDisabled={isCreatePageDisabled}
      menus={menus}
      onCreatePageClick={handleCreatePageClick}
      onDeletePageClick={handlePageDeleteClick}
      onPageTitleClick={handlePageTitleClick}
      onCreateMenuItemClick={handleCreateMenuItemClick}
      onRemoveMenuItemClick={handleRemoveMenuItemClick}
      onMenuChange={handleMenuChange}
      onSetPages={setPages}
      pageTitleValue={pageTitleValue}
      pages={pages}
      setPageTitle={setPageTitle}
      urlValue={urlValue}
      menuPagesState={menuPagesState}
    />
  );
};

PageModal.propTypes = {
  currentPageEntry: PropTypes.object,
  location: PropTypes.object,
  config: PropTypes.object,
  updateMenuData: PropTypes.func
};

export default connect(null, mapActionsToProps)(withRouter(withConfig(PageModal)));
