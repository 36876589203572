import { connect } from 'react-redux';
import { selectors } from '#/redux/modules/platform';
import ViewContainer from '#/views/ViewContainer/ViewContainer';
;

const mapStateToProps = state => ({
  platform: selectors.getPlatform(state)
});

export default connect(mapStateToProps)(ViewContainer);
