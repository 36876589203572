import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  EDITION_STATUS,
  EDITION_TAGS_GROUPS,
  EDITION_TAG_ID_TO_NAME
} from '../editionsConstants';

import VersionsList from './VersionsList';
import Divider from './Divider';
import Modal from './Modal';

const INPUT_STYLE = {
  background: 'transparent',
  border: 'none',
  color: 'white',
  fontSize: 30,
  fontWeight: 777,
  outline: 'none',
  marginBottom: 15
};

const TagItem = ({ children, tagId }) => {
  return (
    <div
      style={{
        border: '1px solid #444',
        borderRadius: 5,
        display: 'inline',
        fontSize: 10,
        marginLeft: 8,
        padding: 5,
        paddingLeft: 2
      }}
    >
      {children}
      {EDITION_TAG_ID_TO_NAME[tagId]}
    </div>
  );
};

TagItem.propTypes = {
  children: PropTypes.node,
  tagId: PropTypes.string
};

const DashboardItemModal = ({
  editions,
  getMaxPublishedId,
  modalEdition,
  onCompareClick,
  onEditionDescriptionChange,
  onEditionNameChange,
  onNewClick,
  onOpenEditorClick,
  onOpenLivePreviewClick,
  onRemoveClick,
  onRollbackClick,
  onTagChange,
  versions,

  ...props
}) => {
  const comparableEditions = editions.filter(e => e.id !== modalEdition?.id);
  const getDefaultComparison = () => comparableEditions[0]?.id;
  const [selectedComparison, setSelectedComparison] = useState(
    getDefaultComparison()
  );
  const [isLastPublished, setIsLastPublished] = useState(null);

  useEffect(() => {
    const maxPublishedId = getMaxPublishedId();

    const isLastPublishedVal =
      (modalEdition?.published || []).indexOf(maxPublishedId) !== -1;

    setIsLastPublished(isLastPublishedVal);

    setSelectedComparison(getDefaultComparison());
  }, [modalEdition?.id]);

  const editionIdToNameMap = editions.reduce((acc, edition) => {
    acc[edition.id] = edition.name;

    return acc;
  }, {});
  const BASIC_MARGIN = '35px 15px 20px 35px';

  // todo: reuse Intuitive Button.
  const BUTTON_STYLE = {
    backgroundColor: '#000',
    border: '1px solid rgba(61,255,216,.7)',
    borderRadius: 5,
    color: '#eee',
    cursor: 'pointer',
    fontSize: 12,
    height: 30,
    marginTop: 8,
    width: 150
  };

  if (!modalEdition) {
    return null;
  }

  const isPublished = modalEdition.status === EDITION_STATUS.PUBLISHED;

  return (
    <Modal
      {...props}
      style={{
        content: {
          backgroundColor: '#111',
          border: 'solid 1px #414141',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
          color: '#eee',
          left: '20%',
          padding: 0,
          right: '20%'
        }
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          width: '77%',
          height: '100%'
        }}
      >
        <div style={{ margin: BASIC_MARGIN }}>
          <input
            onChange={onEditionNameChange}
            style={INPUT_STYLE}
            type="text"
            value={modalEdition.name}
          />
          <div>
            {EDITION_TAGS_GROUPS.map(editionTagGroup => {
              return (
                <div
                  key={editionTagGroup.id}
                  style={{
                    marginBottom: 2,
                    padding: 5,
                    paddingLeft: 1
                  }}
                >
                  <div
                    style={{
                      display: 'inline',
                      fontWeight: 'bold',
                      fontSize: 13
                    }}
                  >
                    {editionTagGroup.name}
                  </div>
                  <div
                    style={{
                      display: 'inline'
                    }}
                  >
                    {editionTagGroup.items.map(editionTagItem => {
                      const checked = !!modalEdition.tags.find(
                        t => t.id === editionTagItem.id
                      );

                      if (!isPublished) {
                        return (
                          <TagItem
                            key={editionTagItem.id}
                            tagId={editionTagItem.id}
                          >
                            <input
                              type="checkbox"
                              onChange={e =>
                                onTagChange({
                                  tagId: editionTagItem.id,
                                  checked: e.target.checked
                                })
                              }
                              checked={checked}
                            />
                          </TagItem>
                        );
                      }

                      return (
                        checked && (
                          <TagItem
                            key={editionTagItem.id}
                            tagId={editionTagItem.id}
                          />
                        )
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div style={{ margin: BASIC_MARGIN }}>
          <h2>Description</h2>
          <textarea
            value={modalEdition.description || ''}
            onChange={onEditionDescriptionChange}
            style={{
              backgroundColor: '#eaeaea',
              borderRadius: 3,
              fontSize: 12,
              minHeight: 100,
              padding: 7,
              width: '100%'
            }}
          />
        </div>

        <div style={{ margin: BASIC_MARGIN }}>
          <h2>Activity</h2>
          <VersionsList
            editionCreatedOn={modalEdition.createdOn}
            extraActivities={modalEdition.extraActivities}
            versions={versions}
          />
        </div>
      </div>

      <div
        style={{
          height: '100%',
          left: '77%',
          paddingTop: 146,
          position: 'absolute',
          width: '23%'
        }}
      >
        <div
          style={{
            margin: '0 0 20px 0'
          }}
        >
          <h4
            style={{
              margin: '0 1px'
            }}
          >
            EDIT
          </h4>

          <React.Fragment>
            <button
              style={BUTTON_STYLE}
              onClick={() => onOpenEditorClick?.(modalEdition)}
            >
              Open in Editor
            </button>
            <button
              style={BUTTON_STYLE}
              onClick={() => onOpenLivePreviewClick?.(modalEdition)}
            >
              Open Live Preview
            </button>
          </React.Fragment>
          {isPublished && isLastPublished === false && (
            <button
              style={BUTTON_STYLE}
              onClick={() => onRollbackClick?.(modalEdition)}
            >
              Rollback
            </button>
          )}
        </div>

        <Divider />

        <div
          style={{
            margin: '0 0 20px 0'
          }}
        >
          <h4
            style={{
              margin: '0 1px'
            }}
          >
            REVIEW
          </h4>
          {editions.length > 1 && (
            <div>
              <button
                style={BUTTON_STYLE}
                disabled={!selectedComparison}
                onClick={() => {
                  onCompareClick?.({
                    item: modalEdition,
                    otherItem: editions.find(e => e.id === selectedComparison)
                  });
                }}
              >
                Compare With
              </button>
              <select
                style={{
                  width: 151,
                  marginTop: 8,
                  color: '#eee',
                  border: '1px solid #444',
                  backgroundColor: 'rgb(34, 34, 34)'
                }}
                value={selectedComparison}
                onChange={e => {
                  setSelectedComparison(e.target.value);
                }}
              >
                {editions.map(otherItem => {
                  return otherItem.id !== modalEdition.id ? (
                    <option key={otherItem.id} value={otherItem.id}>
                      {editionIdToNameMap[otherItem.id]}
                    </option>
                  ) : null;
                })}
              </select>
            </div>
          )}
        </div>

        <Divider />

        <div>
          <h4
            style={{
              margin: '0 1px'
            }}
          >
            ACTIONS
          </h4>

          <div>
            <button
              style={BUTTON_STYLE}
              onClick={() => onNewClick?.(modalEdition)}
            >
              Create Duplicated
            </button>
          </div>

          {!isPublished && modalEdition.id !== 'master' && (
            <div>
              <button
                style={BUTTON_STYLE}
                onClick={() => onRemoveClick?.({ item: modalEdition })}
              >
                Remove
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

DashboardItemModal.propTypes = {
  editions: PropTypes.array,
  getMaxPublishedId: PropTypes.func,
  modalEdition: PropTypes.object,
  onCompareClick: PropTypes.func,
  onEditionDescriptionChange: PropTypes.func,
  onEditionNameChange: PropTypes.func,
  onNewClick: PropTypes.func,
  onOpenEditorClick: PropTypes.func,
  onOpenLivePreviewClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onRollbackClick: PropTypes.func,
  onTagChange: PropTypes.func,
  versions: PropTypes.array
};

export default DashboardItemModal;
