import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '#/redux/modules/platform';

import VikimapDebugPage from '#/views/VikimapDebugPage/VikimapDebugPage';
import ElevateDebugPage from '#/elevate/views/ElevateDebugPage/ElevateDebugPage';

const mapStateToProps = state => ({
  platform: selectors.getPlatform(state)
});

class DebugPage extends Component {
  render() {
    const { platform } = this.props;

    return platform === 'ctv' ? (
      <ElevateDebugPage {...this.props} />
    ) : (
      <VikimapDebugPage {...this.props} />
    )
  }
}

export default connect(mapStateToProps)(DebugPage);
