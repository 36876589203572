import accedoOne, { brightcove, placeholder, tmdb } from '#/services/ovp';
import { AccedoOneFeedQuery } from '#/config/templates';

// https://accedobroadband.jira.com/wiki/spaces/VDK/pages/571900189/QA+Application+Entry+Alignment#QAApplicationEntryAlignment-queryContainerQuery

const ovps = [accedoOne, brightcove, tmdb];

const executeQuery = query => {
  if (query === 'placeholderData') {
    return placeholder.getData();
  }

  let ovp = ovps[0];

  if (/^brightcove:/i.test(query)) {
    [, ovp] = ovps;
  } else if (/^tmdb:/i.test(query)) {
    [, , ovp] = ovps;
  }

  if (AccedoOneFeedQuery.MovieCategories?.test(query)) {
    return ovp.getMovieCategories();
  }

  if (AccedoOneFeedQuery.MoviesByIds?.test(query)) {
    return ovp.getItemsByQuery({
      query,
      itemsPerPage: 20,
      pageNumber: 1,
      sortBy: 'date'
    });
  }

  if (AccedoOneFeedQuery.TvShowsByCategory?.test(query)) {
    const category = AccedoOneFeedQuery.TvShowsByCategory.exec(query)?.[1];

    if (category) {
      return ovp.getTvShowsByCategory(category);
    }
  }

  if (AccedoOneFeedQuery.MoviesByCategory?.test(query)) {
    const category = AccedoOneFeedQuery.MoviesByCategory.exec(query)?.[1];

    if (category) {
      return ovp.getMoviesByCategory(category);
    }
  }

  if (AccedoOneFeedQuery.MoviesByPlaylistName?.test(query)) {
    const plId = AccedoOneFeedQuery.MoviesByPlaylistName.exec(query)?.[1];

    if (plId) {
      return ovp.getPlaylistVideosByName(plId);
    }
  }

  if (
    AccedoOneFeedQuery.NowPlayingMovies?.test(query) &&
    ovp.getNowPlayingMovies
  ) {
    return ovp.getNowPlayingMovies();
  }

  if (AccedoOneFeedQuery.PopularMovies?.test(query) && ovp.getPopularMovies) {
    return ovp.getPopularMovies();
  }

  if (AccedoOneFeedQuery.TopRatedMovies?.test(query) && ovp.getTopRatedMovies) {
    return ovp.getTopRatedMovies();
  }

  if (AccedoOneFeedQuery.UpcomingMovies?.test(query) && ovp.getUpcomingMovies) {
    return ovp.getUpcomingMovies();
  }

  if (AccedoOneFeedQuery.PopularTvShows?.test(query) && ovp.getPopularTvShows) {
    return ovp.getPopularTvShows();
  }

  if (
    AccedoOneFeedQuery.TopRatedTvShows?.test(query) &&
    ovp.getTopRatedTvShows
  ) {
    return ovp.getTopRatedTvShows();
  }

  // TODO: This call breaks the app. What should we do instead?
  if (AccedoOneFeedQuery.TvShows?.test(query)) {
    return ovp.getTvShowData(query);
  }
  console.info('[queryParser], No match for query:', query);

  return ovp.getMovieData();
};

export default { executeQuery };
