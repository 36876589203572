import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { Link } from 'react-router-dom';

import getResolution from '#/utils/getResolution';
import { R720p } from '#/elevate/theme/variables/breakpoints';
import ImageWithPreload from '#/elevate/components/ImageWithPreload/ImageWithPreload';
import useItemStyle from '#/elevate/hooks/useItemStyle';
import { scaledPixel } from '#/elevate/theme/scale';
import {
  CONTAINER_ITEM_TYPES,
  ITEM_TYPES,
  IMAGE_TYPE
} from '#/config/constants';
import assetItemTheme from './assetItem.scss';

const Div = props => <div {...props} />;

const CONTAINER_MARGIN = 10;

const OrigAssetItem = ({
  containerStyle,
  data,
  displayType,
  height,
  label,
  nav,
  onClick,
  onFocus,
  style,
  theme,
  type,
  width,
  containerId,
  isSelected,
  isContainerSelected
}) => {
  const { width: screenWidth } = getResolution();
  const customStyle = {
    ...(R720p >= scaledPixel(screenWidth) && {
      fontSize: '20px',
      padding: '5px 5px',
      lineHeight: '40px'
    })
  };
  const TITLE_CONTAINER_HEIGHT = 0.05 * scaledPixel(1920);
  let typeItem;
  switch (type) {
    case ITEM_TYPES.Wide:
      typeItem = 'backdrop';
      break;
    case ITEM_TYPES.GridLandscape:
      typeItem = type;
      break;

    case ITEM_TYPES.SearchEpisode:
      typeItem = IMAGE_TYPE.BACKDROP;
      break;

    case ITEM_TYPES.SearchMovie:
    default:
      typeItem = IMAGE_TYPE.POSTER;
      break;
  }

  const { itemStyle } = useItemStyle({
    data,
    width,
    height,
    type: `${typeItem}`
  });

  const { viewAll, displayText } = data;
  const typeClassName = theme[type] || '';

  const backgroundClassName = `${theme.background}
    ${isSelected ? theme.selected : ''}
    ${nav.isFocused ? theme['background-focused'] : ''}
    ${viewAll ? theme['view-all-background'] : ''}
    ${isContainerSelected ? theme['container-selected'] : ''}`;

  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    ...(displayType ? { display: displayType } : { display: 'inline-block' }),
    margin: CONTAINER_MARGIN
  };

  const finalImageHeight = `${containerStyle.height - TITLE_CONTAINER_HEIGHT}px`;

  const titleTheme =
    type === CONTAINER_ITEM_TYPES.Bookmark
      ? `${theme.title} ${theme['title-continue-watching']}`
      : `${theme.title} ${theme['title-default']}`;

  const titleContainerClassName = nav.isFocused
    ? `${titleTheme} ${theme['title-focused']}`
    : titleTheme;

  const Wrapper = onClick ? Div : Link;
  const props = {
    onClick: event => {
      return onClick({
        event,
        item: data,
        containerId
      });
    }
  };

  React.useEffect(() => {
    isSelected && onFocus();
  }, [isSelected]);

  return (
    <Wrapper {...props} style={wrapperStyle}>
      <div
        className={backgroundClassName}
        style={style}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        <ImageWithPreload
          src={itemStyle.imageUrl}
          height={finalImageHeight}
          width={containerStyle?.width}
          // Change the default placeholder
          viewAll={viewAll || data.type === 'live' || false}
        />
        {viewAll ? (
          <div className={theme['view-all']}>
            <div>{'viewAll'}</div>
            <div>{displayText}</div>
          </div>
        ) : (
          <div className={`${theme['content-container']} ${typeClassName}`}>
            {data.progress && (
              <div className={theme.progress}>
                <span style={{ width: `${data.progress}%` }} />
              </div>
            )}
          </div>
        )}
        <button
          className={theme['click-area']}
          aria-label={label}
          onClick={onClick}
          style={{ height: `${TITLE_CONTAINER_HEIGHT}px` }}
        />
        {!viewAll && data.type !== 'live' && (
          <div
            style={{ ...customStyle, height: `${TITLE_CONTAINER_HEIGHT}px` }}
            className={titleContainerClassName}
          >
            {data.title}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

OrigAssetItem.propTypes = {
  containerStyle: PropTypes.object,
  data: PropTypes.object.isRequired,
  displayType: PropTypes.string,
  height: PropTypes.any,
  label: PropTypes.string,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  style: PropTypes.any,
  theme: PropTypes.object,
  type: PropTypes.string,
  width: PropTypes.any,
  containerId: PropTypes.string,
  isSelected: PropTypes.bool,
  isContainerSelected: PropTypes.bool
};

OrigAssetItem.defaultProps = {
  theme: assetItemTheme,
  label: '',
  nav: {}
};

const AssetItem = withFocus(OrigAssetItem);

// istanbul ignore else
if (__TEST__) {
  AssetItem._test = {
    OrigAssetItem
  };
}

export default AssetItem;
