import accedoOne from '@accedo/accedo-one';
import * as cookie from 'react-cookie';
import * as vikimap from '@accedo/vdkweb-vikimap';

import accedoOneConfig, {
  APPLICATION_KEY,
  DEVICE_ID,
  SESSION_KEY,
  SIXTY_YEARS_IN_MS
} from '#/config/accedoOne';
import * as configuration from '#/services/configuration/configuration';
import * as status from '#/services/status/status';

let client;

export const cleanAccedoOneSessionInClient = () => {
  const appKey = cookie.load(APPLICATION_KEY);

  if (appKey !== accedoOneConfig.appKey && accedoOneConfig.appKey) {
    cookie.remove(APPLICATION_KEY);
    cookie.remove(DEVICE_ID);
    cookie.remove(SESSION_KEY);

    cookie.save(APPLICATION_KEY, accedoOneConfig.appKey, {
      path: '/',
      maxAge: SIXTY_YEARS_IN_MS
    });
  }
};

export const cleanAccedoOneSessionOnServer = __CLIENT__
  ? null
  : ({ req, res }) => {
      const appKey = req?.cookies?.[APPLICATION_KEY];

      if (appKey !== accedoOneConfig.appKey && accedoOneConfig.appKey) {
        res.clearCookie(APPLICATION_KEY);
        res.clearCookie(DEVICE_ID);
        res.clearCookie(SESSION_KEY);

        res.cookie(APPLICATION_KEY, accedoOneConfig.appKey, {
          httpOnly: false,
          maxAge: SIXTY_YEARS_IN_MS
        });
      }
    };

export const getAccedoOneClient = (customizedConfig = null) => {
  if (!accedoOneConfig?.appKey && !customizedConfig?.accedoOne?.appKey) {
    console.error('No Accedo One settings available..');
    return null;
  }

  let accedoOneSettings = customizedConfig || accedoOneConfig;

  if (__CLIENT__) {
    accedoOneSettings = Object.assign(accedoOneSettings, {
      browserInfoProvider: () => ({
        deviceId: cookie.load(DEVICE_ID),
        sessionKey: cookie.load(SESSION_KEY)
      }),
      onDeviceIdGenerated: id => {
        cookie.save(DEVICE_ID, id, { path: '/', maxAge: SIXTY_YEARS_IN_MS });
      },
      onSessionKeyChanged: key => {
        cookie.save(SESSION_KEY, key, {
          path: '/',
          maxAge: SIXTY_YEARS_IN_MS
        });
      }
    });

    client = client || accedoOne(accedoOneSettings);

    return client;
  }

  client = accedoOne(accedoOneSettings);

  return client;
};

export const getAccedoOneServices = accedoOneClient => ({
  configuration: configuration.getAccedoOneService(accedoOneClient),
  status: status.getAccedoOneService(accedoOneClient),
  vikimap: vikimap.getAccedoOneService(accedoOneClient)
});
