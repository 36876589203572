import React from 'react';

import { FooterBar } from './HorizontalBar/HorizontalBar';
import { DASHBOARD_FOOTER_HEIGHT } from './dashboardConstants';

const DashboardFooter = () => {
  return <FooterBar height={DASHBOARD_FOOTER_HEIGHT} />;
};

export default DashboardFooter;
