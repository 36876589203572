import * as vikimapOrig from '@accedo/vdkweb-vikimap/lib/redux';
import { extendReducer } from '@accedo/vdkweb-vikimap/lib/redux/reducer';
import { UPDATE_MENU, updateMenu } from './actions';
import { updateMenuReducer } from './reducer';

const vikimap = { ...vikimapOrig };

// Extend vikimap
if (vikimap.actions) {
  vikimap.actions.updateMenu = updateMenu;
  vikimap.reducer = extendReducer({ [UPDATE_MENU]: updateMenuReducer });
}

export default vikimap;
