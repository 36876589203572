import React from 'react';
import PropTypes from 'prop-types';

import { withFocus } from '@accedo/vdkweb-navigation';
import FocusDiv from '@accedo/vdkweb-tv-ui/lib/FocusDiv/FocusDiv';
import { navIdMap } from '#/utils/navigationHelper';
import toKebabCase from '#/utils/toKebabCase';
import styles from './menu.scss';
import MenuItem from './MenuItem';

const NAV_IDS = navIdMap.MENU.HEADER;

/**
 * Nav Component
 * @param {Object} props Component props
 * @param {React.ReactNode} props.children Component children
 * @param {String} props.className Component css class
 * @returns {React.ReactElement} Nav React Component
 */
const Nav = ({ children, className, dir = 'ltr' }) => (
  <nav className={className} dir={dir}>
    {children}
  </nav>
);

Nav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dir: PropTypes.string
};

const FocusNav = withFocus(Nav);

const Menu = ({ items, nav }) => {
  /**
   * Helper function to push to a new route when it is pressed/clicked
   * @param {String} route Route destination
   * @returns {void}
   */
  // const onMenuClick = ({ page }) => {

  // };

  if (!items) {
    return <div />;
  }

  const primaryItemNavPrefix = `${NAV_IDS.MENU_ITEM}primary-item-`;

  return (
    <FocusNav className={styles.menu} nav={nav}>
      <FocusDiv
        className={`${styles.menu} ${styles['main-menu']}`}
        nav={{
          id: `${NAV_IDS.MENU_ITEM}0`,
          parent: NAV_IDS.MENU,
          forwardFocus: `${primaryItemNavPrefix}${toKebabCase(
            items[0].displayText
          )}`,
          useLastFocus: true,
          nextleft: '',
          nextright: `${NAV_IDS.MENU_ITEM}1`
        }}
      >
        {items.map((item, index) => {
          const primaryItemId = `${primaryItemNavPrefix}${toKebabCase(
            item.displayText
          )}`;
          const secondayNextItem = ``;

          const primaryItemNextleft =
            index === 0
              ? secondayNextItem
              : `${primaryItemNavPrefix}${toKebabCase(
                  items[index - 1].displayText
                )}`;
          const primaryItemNextRight =
            index >= items.length - 1
              ? ''
              : `${primaryItemNavPrefix}${toKebabCase(
                  items[index + 1].displayText
                )}`;
          return (
            <MenuItem
              style={styles}
              data={item}
              key={primaryItemId}
              nav={{
                id: primaryItemId,
                nextleft: primaryItemNextleft,
                nextright: primaryItemNextRight,
                parent: `${NAV_IDS.MENU_ITEM}0`
              }}
            />
          );
        })}
      </FocusDiv>
    </FocusNav>
  );
};

Menu.propTypes = {
  items: PropTypes.array,
  nav: PropTypes.object.isRequired
};

export default React.memo(Menu);
