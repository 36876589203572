import placeholderImagePortrait from '#/static/images/placeholder_portrait.png';
import placeholderImageLandscape from '#/static/images/placeholder_landscape.png';
import placeholderImageBanner from '#/static/images/placeholder_banner.png';

const dataArr = [...Array(10)];
const placeholderData = dataArr.map((x, i) => ({
  title: '',
  description: '',
  images: [
    {
      type: 'thumbnail',
      url: placeholderImagePortrait
    },
    {
      type: 'backdrop',
      url: placeholderImageLandscape
    },
    {
      type: 'banner',
      url: placeholderImageBanner
    }
  ],
  id: `placeholder-item-${i + 1}`
}));

const getData = () => {
  return Promise.resolve(placeholderData);
};

export default { getData };
