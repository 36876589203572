import React from 'react';
import ReactModal from 'react-modal';
import Scrollbar from 'react-scrollbars-custom';
import PropTypes from 'prop-types';

import VersionsList from '#/views/EditionsDashboard/components/VersionsList';

import { EDITOR_TOP_BAR_HEIGHT } from '../editorConstants';
import { PanelTitle } from '../Panel';

import * as styles from './versionsModal.scss';

const VersionsModal = ({
  editionCreatedOn,
  getShowVersionRestoreButton,
  highlightedVersionId,
  onRestoreClick,
  onVersionClick,
  versions,
  width
}) => {
  if (!versions) {
    return null;
  }

  return (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={{
        content: {
          top: EDITOR_TOP_BAR_HEIGHT,
          width
        }
      }}
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <PanelTitle style={{ color: 'rgb(211, 211, 211)' }}>HISTORY</PanelTitle>
      <Scrollbar style={{ height: 275, width: 315, left: 1 }}>
        <VersionsList
          editionCreatedOn={editionCreatedOn}
          getShowRestoreButton={getShowVersionRestoreButton}
          highlightedVersionId={highlightedVersionId}
          onRestoreClick={onRestoreClick}
          onVersionClick={onVersionClick}
          versions={versions}
        />
      </Scrollbar>
    </ReactModal>
  );
};

VersionsModal.propTypes = {
  editionCreatedOn: PropTypes.number,
  getShowVersionRestoreButton: PropTypes.func,
  highlightedVersionId: PropTypes.string,
  onRestoreClick: PropTypes.func,
  onVersionClick: PropTypes.func,
  versions: PropTypes.array,
  width: PropTypes.string
};

VersionsModal.defaultProps = {};

export default VersionsModal;
