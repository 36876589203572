import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getColorForItem } from '#/utils/itemHelper';

import * as styles from './item.scss';

const Div = props => <div {...props} />;

const AssetItem = ({
  containerId,
  height,
  isUsingEditor,
  isHoverableInEditor,
  item: itemProp,
  onClick,
  selectedEditorEntry,
  template: itemTemplate,
  templates,
  type,
  width
}) => {
  const item = { ...itemProp };
  const {
    AccedoOneItemActionToRoute,
    AccedoOneItemAction,
    OVPItemType,
    OVPItemTypeRoutes
  } = templates;
  const itemStyle = { width, height };

  // adapt to both images and image attributes.
  // return images or image attribute and fallback to empty array.

  const images = [
    ...(item.images instanceof Array ? item.images : []),
    ...(item.image instanceof Array ? item.image : [])
  ];

  let imageUrl;

  if (images.length > 0) {
    imageUrl = images[0].url || images[0].fileUrl;
  }

  const templateClassName = styles[itemTemplate] || '';
  const typeClassName = styles[type] || '';
  const editorClassName = isUsingEditor
    ? isHoverableInEditor && styles.editor
    : styles.nonEditor;
  const { id: selectedId, parentContainer } = selectedEditorEntry || {};
  const selected =
    selectedId === item.id && parentContainer?.id === containerId;
  const selectedClassName = selected ? styles.selected : '';

  if (type === OVPItemType.Portrait) {
    item.subTitle = item.categories
      ? item.categories
          .slice(0, 2)
          .map(category => category.title)
          .join(', ')
      : '';

    const image = images.find(
      x => x.type === 'poster' || x.type === 'thumbnail'
    );
    imageUrl = image?.url || imageUrl;
  } else if (type === OVPItemType.Wide) {
    if (item.tvSeasonCount) {
      item.subTitle = `Season ${item.tvSeasonCount}`;
    } else if (item.categories) {
      item.subTitle = item.categories
        .slice(0, 2)
        .map(category => category.title)
        .join(', ');
    } else {
      item.subTitle = item.description;
    }

    const image = images.find(x => x.type === 'backdrop');
    imageUrl = image?.url || imageUrl;
  } else if (type === OVPItemType.Category || type === OVPItemType.Square) {
    itemStyle.backgroundColor = getColorForItem(item);
  }

  itemStyle.backgroundImage = `url(${imageUrl})`;

  let link = '';

  if (AccedoOneItemActionToRoute[item.action] && item.actiondata) {
    link = AccedoOneItemActionToRoute[item.action].replace(
      ':action-data:',
      item.actiondata
    );
  } else if (
    OVPItemTypeRoutes[item.type] &&
    (type !== OVPItemType.Square || item.action !== AccedoOneItemAction.Play)
  ) {
    link = OVPItemTypeRoutes[item.type].replace(':item-id:', item.id);
  } else if (item.type && !OVPItemTypeRoutes[item.type] && __CLIENT__) {
    console.debug(`Route not handled for item type: ${item.type}`);
  }

  const Wrapper = onClick ? Div : Link;
  const props = onClick
    ? {
        onClick: event =>
          onClick({
            event,
            item: itemProp,
            containerId
          })
      }
    : { to: link, style: { textDecoration: 'none' } };

  return (
    <Wrapper {...props}>
      <div
        className={`${styles.itemCard} ${templateClassName} ${typeClassName} ${editorClassName} ${selectedClassName}`}
        style={itemStyle}
      >
        <span
          className={`${styles.content} ${styles.itemTextContainer}`}
          style={{ width, height }}
        >
          <div className={styles.itemText}>{item.displayText || ''}</div>
          <div className={styles.itemSubtitle}>{item.subTitle}</div>
        </span>
      </div>
    </Wrapper>
  );
};

AssetItem.propTypes = {
  containerId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isHoverableInEditor: PropTypes.bool,
  isUsingEditor: PropTypes.bool,
  item: PropTypes.object,
  onClick: PropTypes.func,
  selectedEditorEntry: PropTypes.object,
  template: PropTypes.string,
  templates: PropTypes.object.isRequired,
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

AssetItem.defaultProps = {
  type: 'unknown'
};

export default AssetItem;
