import React, { useEffect, useRef } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import Header from '#/containers/Header/Header';
import * as styles from '../views.scss';

// https://reacttraining.com/react-router/web/guides/scroll-restoration
const useScrollOnLocationChange = location => {
  const ref = useRef('');
  useEffect(() => {
    if (
      ref.current &&
      location.pathname !== ref.current &&
      typeof document !== 'undefined'
    ) {
      // Currently the page scroll is in the root div
      const el = document.getElementById('root-view');
      if (el) {
        // scrollTo doesn't work on MS Edge: VDK-144
        el.scrollTop = 0;
      }
    }

    ref.current = location.pathname;
  });
};

const ViewContainer = ({ route, location }) => {
  useScrollOnLocationChange(location);

  const query = qs.parse((location.search || '').replace(/^\?/, ''));
  const shouldShowHeader =
    query?.mode !== 'edit' && location.pathname !== '/dashboard';

  return (
    <div className={styles.viewport}>
      <div className={styles.wrapper}>
        {shouldShowHeader && <Header location={location} />}
        <div className={styles.content}>{renderRoutes(route.routes)}</div>
      </div>
    </div>
  );
};

ViewContainer.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object
};

export default ViewContainer;
