import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from '#/theme/Theme';

import * as styles from './container.scss';

const Container = ({ displayText, template, style, theme, children }) => {
  const templateClassName = styles[template] || '';

  return (
    <div
      className={`${styles.outerContainer} ${templateClassName} ${
        styles[theme.name]
      }`}
      style={style}
    >
      {displayText ? (
        <div className={styles.containerTitle}>{displayText}</div>
      ) : (
        ''
      )}
      <div className={styles.innerContainer}>{children}</div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  displayText: PropTypes.string,
  style: PropTypes.object,
  template: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(Container);
