import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@accedo/vdkweb-carousel';

import { itemSizeMap } from '#/utils/itemHelper';
import { getItemsMap } from '#/components/utils/itemsMap';
import { XS, SM, MD, LG, XL, XXL } from '#/theme/variables/breakpoints';

const TEMPLATE_CAROUSEL = 'carousel';
const bigNumber = 1000000;
const breakpointsInPixels = [XS, SM, MD, LG, XL, XXL, bigNumber];

const defaultResponsiveItemCount = {
  [XS]: 3,
  [SM]: 3,
  [MD]: 3,
  [LG]: 4,
  [XL]: 6,
  [XXL]: 6,
  [bigNumber]: 8
};

const getResponsiveItemCount = OVPItemType => ({
  [OVPItemType.Wide]: {
    [XS]: 2,
    [SM]: 2,
    [MD]: 3,
    [LG]: 3,
    [XL]: 4,
    [XXL]: 4,
    [bigNumber]: 6
  }
});

const getResponsiveSettings = (itemType, OVPItemType, shouldEnforceMobile) => {
  const itemCount =
    getResponsiveItemCount(OVPItemType)[itemType] || defaultResponsiveItemCount;

  return breakpointsInPixels.map(breakpoint => {
    const num = shouldEnforceMobile ? itemCount[SM] : itemCount[breakpoint];

    return {
      breakpoint,
      settings: {
        slidesToShow: num,
        slidesToScroll: num
      }
    };
  });
};

const getOptionsForTemplate = () => {
  return {
    dots: false,
    infinite: false,
    speed: 500,
    draggable: false,
    arrows: true
  };
};

const CarouselShelf = ({
  config = {},
  containerId,
  isUsingEditor,
  itemType,
  items = [],
  onItemClick,
  selectedEditorEntry,
  templates
}) => {
  if (!items.length) {
    return <span>No items to show</span>;
  }

  const { OVPItemType } = templates;

  const Item = getItemsMap(OVPItemType)[itemType];

  const { width, height } = { ...itemSizeMap[itemType], ...config.itemOptions };
  const carouselOptions = {
    itemWidth: width,
    itemHeight: height,
    responsive: getResponsiveSettings(
      itemType,
      OVPItemType,
      config.shouldEnforceMobile
    ),
    ...getOptionsForTemplate(),
    ...config.carouselOption
  };

  return (
    <div>
      <Carousel
        items={items}
        displayObject={
          <Item
            containerId={containerId}
            onClick={onItemClick}
            isUsingEditor={isUsingEditor}
            isHoverableInEditor={config.isHoverableInEditor}
            selectedEditorEntry={selectedEditorEntry}
            template={TEMPLATE_CAROUSEL}
            templates={templates}
            type={itemType}
          />
        }
        keyProperty="id"
        options={carouselOptions}
      />
    </div>
  );
};

CarouselShelf.propTypes = {
  config: PropTypes.object.isRequired,
  containerId: PropTypes.string,
  isUsingEditor: PropTypes.bool,
  itemType: PropTypes.string,
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  selectedEditorEntry: PropTypes.object,
  templates: PropTypes.object.isRequired
};

export default CarouselShelf;
