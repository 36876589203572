import React, { useEffect, useRef, useState } from 'react';
import _debounce from 'lodash/debounce';
import { Spinner } from '@accedo/vdkweb-ui';

import * as versioning from '#/services/editorClient/editionsVersioningClient';
import appConfig from '#/config/app';

import { DASHBOARD_TOP_BAR_HEIGHT } from './components/dashboardConstants';
import ColumnsDraggableItems from './components/ColumnsDraggableItems';
import EditionsDashboardItem from './components/EditionsDashboardItem';
import DashboardItemModal from './components/DashboardItemModal';
import {
  EDITION_STATUS_ORDER,
  EDITION_STATUS_NAME,
  EDITION_STATUS
} from './editionsConstants';
import DashboardHeader from './components/DashboardHeader';
import DashboardFooter from './components/DashboardFooter';

const DESTINATIONS = [[1], [0, 2], [0, 1, 3], []];
const EMPTY_COLUMNS = [[], [], [], []];
const COLUMN_NAMES = EDITION_STATUS_ORDER.map(e => EDITION_STATUS_NAME[e]);

const COLUMN_IDX_TO_STATUS = [
  EDITION_STATUS.IN_PROGRESS,
  EDITION_STATUS.REVIEW,
  EDITION_STATUS.READY_TO_PUBLISH,
  EDITION_STATUS.PUBLISHED
];

const STATUS_TO_COLUMN_IDX = COLUMN_IDX_TO_STATUS.reduce((acc, status, idx) => {
  acc[status] = idx;

  return acc;
}, {});

const publishedColumndIdx = STATUS_TO_COLUMN_IDX[EDITION_STATUS.PUBLISHED];

const getMaxPublishedId = columns => {
  return columns[publishedColumndIdx].reduce((max, edition) => {
    return Math.max(max, ...edition.published);
  }, 0);
};

const getNextPublished = columns => {
  return getMaxPublishedId(columns) + 1;
};

const parseColumns = columns => {
  const publishedIds = [];
  const publishedIdToEditionMap = {};
  const rollbackEditions = {};

  columns[publishedColumndIdx].forEach(edition => {
    const min = Math.min(...edition.published);

    edition.published.forEach(publishedId => {
      publishedIdToEditionMap[publishedId] = edition;
      publishedIds.push(publishedId);

      rollbackEditions[publishedId] = publishedId !== min;
    });
  });

  publishedIds.sort().reverse();

  return columns.map((column, columnIdx) =>
    columnIdx === publishedColumndIdx
      ? publishedIds.map(publishedId => ({
          ...publishedIdToEditionMap[publishedId],
          isRollback: rollbackEditions[publishedId],
          publishedId
        }))
      : column
  );
};

const getColumnsWithoutRollbacks = columns => {
  return columns.map((column, columnIdx) =>
    columnIdx === publishedColumndIdx
      ? column.filter(e => !e.isRollback)
      : column
  );
};

const handleOpenEditor = item => {
  window.open(`/home?mode=edit&edition=${item.id}`);
};

const handleOpenLivePreview = item => {
  window.open(`/home?mode=live-preview&edition=${item.id}`);
};

const extractItemHelpers = ({ items }) => {
  const editionIdToColumnIndexes = {};
  const editionIdToEdition = {};

  items.forEach((editions, firstIndex) => {
    editions.forEach((edition, secondIndex) => {
      editionIdToColumnIndexes[edition.id] = [firstIndex, secondIndex];
      editionIdToEdition[edition.id] = edition;
    });
  });

  const maxPublishedId = items[publishedColumndIdx].reduce((max, edition) => {
    return Math.max(max, ...edition.published);
  }, 0);

  return { editionIdToColumnIndexes, editionIdToEdition, maxPublishedId };
};

const EditionsDashboard = () => {
  const { current: compRef } = useRef({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [columns, setColumns] = useState(EMPTY_COLUMNS.slice(0));
  const [modalEdition, setModalEdition] = useState(null);
  const [editionVersions, setEditionVersions] = useState(null);

  compRef.runDebounced = compRef.runDebounced || _debounce(fn => fn(), 2000);
  const parsedColumns = parseColumns(columns);

  useEffect(() => {
    let unmounted = false;

    const fn = async () => {
      const { editions } = await versioning.getAllEditions();

      if (!unmounted) {
        const newColumns = editions.reduce((acc, edition) => {
          const idx = STATUS_TO_COLUMN_IDX[edition.status];
          acc[idx] = acc[idx].concat([edition]);
          return acc;
        }, EMPTY_COLUMNS.slice(0));

        setColumns(newColumns);

        setHasLoaded(true);
      }
    };

    fn();

    return () => {
      unmounted = true;
    };
  }, []);

  if (!hasLoaded) {
    return <Spinner />;
  }

  const { editionIdToColumnIndexes, editionIdToEdition } = extractItemHelpers({
    items: columns
  });

  const updateEdition = (editionId, newEdition) => {
    const [columnIndex, inColumnIndex] = editionIdToColumnIndexes[editionId];

    const newColumns = columns.map((editions, firstIndex) =>
      firstIndex === columnIndex
        ? editions.map((edition, secondIndex) =>
            secondIndex === inColumnIndex ? newEdition : edition
          )
        : editions
    );

    setColumns(newColumns);
  };

  const handleNewClick = async item => {
    const result = await versioning.createEdition({
      fromEditionId: item.id,
      name: `${item.name} Copy`
    });

    const newColumns = columns.slice(0);
    newColumns[0] = newColumns[0].concat([result]);

    setModalEdition(null);
    setColumns(newColumns);
  };

  const handleColumnsUpdate = ({ newColumns, updatedColumn, updatedItem }) => {
    if (typeof updatedColumn !== 'number') {
      return;
    }

    const updatedColumns = getColumnsWithoutRollbacks(newColumns);

    updatedColumns[updatedColumn] = updatedColumns[updatedColumn].map(
      edition => {
        if (edition.id === updatedItem.id) {
          return {
            ...updatedItem,
            status: COLUMN_IDX_TO_STATUS[updatedColumn],
            published: edition.published.concat(
              updatedColumn === publishedColumndIdx
                ? getNextPublished(updatedColumns)
                : []
            )
          };
        }

        return edition;
      }
    );

    versioning.updateEditionMetadata({
      editionId: updatedItem.id,
      status: COLUMN_IDX_TO_STATUS[updatedColumn],
      updatedKeys: ['status']
    });

    setColumns(updatedColumns);
  };

  const handleRollbackClick = ({ id }) => {
    versioning.rollbackEdition({ editionId: id });

    const newColumns = columns.slice(0);
    newColumns[publishedColumndIdx] = columns[publishedColumndIdx].map(
      edition =>
        edition.id === id
          ? {
              ...edition,
              published: edition.published.concat([
                getNextPublished(newColumns)
              ])
            }
          : edition
    );

    setColumns(newColumns);
    setModalEdition(null);
  };

  const handleRemoveClick = ({ item }) => {
    const { id } = item;
    versioning.removeEdition({ editionId: id });

    const newColumns = columns.slice(0);
    const columnId = STATUS_TO_COLUMN_IDX[item.status];

    newColumns[columnId] = newColumns[columnId].filter(i => {
      return i.id !== id;
    });

    setColumns(newColumns);
    setModalEdition(null);
  };

  const handleItemClick = async item => {
    const editionMetadata = await versioning.getEditionMetadata({
      editionId: item.id
    });

    setModalEdition({
      ...editionMetadata,
      id: item.id
    });
    setEditionVersions(null);

    const { versions } = await versioning.getEditionVersions({
      editionId: item.id
    });

    setEditionVersions(versions);
  };

  const handleEditionsComparison = async ({ item, otherItem }) => {
    const result = await versioning.compareEditions({
      editionId: item?.id,
      otherEditionId: otherItem?.id
    });

    console.info('result', result);

    result.diff?.forEach(diffItem => {
      diffItem.hunks.forEach(hunk => {
        console.log(hunk);
      });
    });
  };

  const handleRequestModalClose = () => {
    setModalEdition(null);
  };

  const updateMetadataValue = key => e => {
    const { value } = e.target;
    const newEdition = { ...editionIdToEdition[modalEdition.id], [key]: value };

    updateEdition(modalEdition.id, newEdition);
    setModalEdition({
      ...modalEdition,
      [key]: value
    });

    compRef.runDebounced(() => {
      versioning.updateEditionMetadata({
        editionId: newEdition.id,
        [key]: newEdition[key],
        updatedKeys: [key]
      });
    });
  };

  const handleEditionNameChange = updateMetadataValue('name');
  const handleEditionDescriptionChange = updateMetadataValue('description');
  const handleTagChange = ({ tagId, checked }) => {
    const newEdition = {
      ...editionIdToEdition[modalEdition.id],
      tags: checked
        ? modalEdition.tags.concat([
            {
              id: tagId
            }
          ])
        : modalEdition.tags.filter(p => p.id !== tagId)
    };

    updateEdition(modalEdition.id, newEdition);
    setModalEdition({
      ...modalEdition,
      tags: newEdition.tags
    });

    compRef.runDebounced(() => {
      versioning.updateEditionMetadata({
        editionId: newEdition.id,
        tags: newEdition.tags,
        updatedKeys: ['tags']
      });
    });
  };

  return (
    <React.Fragment>
      <DashboardHeader userInfo={appConfig.userInfo} />
      <ColumnsDraggableItems
        style={{ marginTop: DASHBOARD_TOP_BAR_HEIGHT }}
        columnIndexToDestinations={DESTINATIONS}
        columnNames={COLUMN_NAMES}
        getKeyFromItem={i =>
          i.publishedId ? `${i.publishedId}-${i.id}` : i.id
        }
        itemProps={{
          onOpenInEditorClick: handleOpenEditor
        }}
        ColumnItem={EditionsDashboardItem}
        getIsDragDisabled={i => i.id === 'master'}
        columns={parsedColumns}
        onColumnsUpdate={handleColumnsUpdate}
        onItemClick={handleItemClick}
      />
      <DashboardItemModal
        editions={columns.reduce((acc, editions) => {
          return acc.concat(editions);
        }, [])}
        getMaxPublishedId={() => getMaxPublishedId(columns)}
        isOpen={!!modalEdition}
        modalEdition={modalEdition}
        versions={editionVersions}
        onCompareClick={handleEditionsComparison}
        onNewClick={handleNewClick}
        onRemoveClick={handleRemoveClick}
        onTagChange={handleTagChange}
        onRollbackClick={handleRollbackClick}
        onOpenEditorClick={handleOpenEditor}
        onEditionNameChange={handleEditionNameChange}
        onEditionDescriptionChange={handleEditionDescriptionChange}
        onOpenLivePreviewClick={handleOpenLivePreview}
        onRequestClose={handleRequestModalClose}
      />
      <DashboardFooter />
    </React.Fragment>
  );
};

export default EditionsDashboard;
