const viewportWidth = 1920;

let scale = 0;

function getScale() {
  if (!scale) {
    scale = (window.innerWidth * 0.56) / viewportWidth;
  }
  return scale;
}

// scaled pixel value from a 1080 based pixel value
export function scaledPixel(value) {
  return value * getScale();
}

export function vw(value) {
  return `${(value / viewportWidth) * 100}vw`;
}
