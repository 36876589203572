import {
  validateAreKeysDefined,
  validateAreValuesDefined,
  validateContainsAllKeys
} from '#/utils/validations';

import VikimapViewsIds from '#/views/VikimapPage/VikimapViewsIds';
import ShelfContainersIds from '#/components/Shelf/ShelfContainersIds';

import appSwitcher from './appSwitcher';

import appConfig from './app';

const { urlPrefix } = appConfig;

const { AccedoOneContainer } = VikimapViewsIds;

const AccedoOnePageTemplateMap = {
  home: AccedoOneContainer
};

const { HeroBanner, Carousel, Grid } = ShelfContainersIds;

const AccedoOneContainerTemplates = appSwitcher.containerTemplates;

const AccedoOneContainerTemplateMap = {
  branding: null,
  carousel: Carousel,
  'carousel-banner': null,
  'carousel-link': Carousel,
  [AccedoOneContainerTemplates.CarouselPortrait]: Carousel,
  [AccedoOneContainerTemplates.CarouselWide]: Carousel,
  grid: Grid,
  'grid-banner': null,
  [AccedoOneContainerTemplates.GridPortrait]: Grid,
  'grid-portrait-asym': Grid,
  'grid-portrait-lazyload': Grid,
  'grid-portrait-loadmore': Grid,
  [AccedoOneContainerTemplates.GridWide]: Grid,
  'grid-wide-asym': Grid,
  'grid-wide-loadmore': Grid,
  hero: HeroBanner,
  [AccedoOneContainerTemplates.HeroBanner]: HeroBanner,
  'hero-portrait': null,
  'hero-vertical': HeroBanner,
  'hero-vertical-signup': HeroBanner,
  'marvel-carousel': null,
  'marvel-hero': null,
  promo: null
};

const OVPItemType = {
  Category: 'category',
  Marvel: 'marvel',
  Movie: 'movie',
  Portrait: 'portrait',
  Square: 'square',
  TvShow: 'tvshow',
  Wide: 'wide'
};

const AccedoOneItemAction = {
  MovieDetails: 'movie-details',
  Play: 'play',
  SeriesDetails: 'series-details'
};

const AccedoOneItemActionToRoute = {
  [AccedoOneItemAction.MovieDetails]: `${urlPrefix}movie-details/:action-data:`,
  [AccedoOneItemAction.Play]: null,
  [AccedoOneItemAction.SeriesDetails]: `${urlPrefix}series-details/:action-data:`
};

const OVPItemTypeRoutes = {
  [OVPItemType.Category]: `${urlPrefix}category-details/:item-id:`,
  [OVPItemType.Marvel]: null,
  [OVPItemType.Movie]: `${urlPrefix}movie-details/:item-id:`,
  [OVPItemType.Portrait]: null,
  [OVPItemType.Square]: `${urlPrefix}category/:item-id:`,
  [OVPItemType.TvShow]: `${urlPrefix}series-details/:item-id:`,
  [OVPItemType.Wide]: null
};

/*  Deprecated version - kept for reference. ToDo:
    We might need to add some of these keys to the new version below

const AccedoOneFeedQueryV1 = {
  ChannelCategories: /channelCategories/i,
  Channels: /channels/i,
  ChannelsByCategory: /channelsByCategory\{.*\}/i,
  ChannelsByIds: /channelsByIds\{.*\}/i,
  MovieCategories: /movieCategories/i,
  Movies: /movies/i,
  NowPlayingMovies: /nowPlayingMovies/i,
  PopularMovies: /popularMovies/i,
  TopRatedMovies: /topRatedMovies/i,
  UpcomingMovies: /upcomingMovies/i,
  MoviesByCategory: /moviesByCategory\{(.*)\}/i,
  MoviesByIds: /moviesByIds\{.*\}/i,
  MoviesByPlaylistName: /moviesByPlaylistName\{(.*)\}/i,
  TvShowCategories: /tvshowCategories/i,
  TvShows: /tvshows/i,
  PopularTvShows: /popularTvShows/i,
  TopRatedTvShows: /topRatedTvShows/i,
  TvShowsByCategory: /tvshowsByCategory\{(.*)\}/i,
  TvShowsByIds: /tvshowsByIds\{.*\}i/
};
*/

// New version, v2
const AccedoOneFeedQuery = {
  Categories: /\/category/i,
  Channels: /\/channel/i,
  ChannelsByIds: /\/channel\/(.*)/i,
  Movies: /\/movie/i,
  MoviesByCategory: /\/category\/(.*)\/movie/i,
  MoviesByIds: /\/movie\/(.*)/i,
  TvShows: /\/tvshow/i,
  TvShowsByCategory: /\/category\/(.*)\/tvshow/i,
  TvShowsByIds: /\/tvshow\/.*/i,
  TvShowSeasons: /\/tvshow\/.*\/tvseason/i,
  TvSeasonsByIds: /\/tvseason\/.*/i,
  TvSeasonEpisodes: /\/tvseason\/.*\/episodes/i
};

const AccedoOneTheme = {
  dark: 'dark',
  light: 'light'
};

const ThemeDefaults = {
  name: AccedoOneTheme.dark
};

validateAreValuesDefined(AccedoOnePageTemplateMap);
validateAreValuesDefined(AccedoOneContainerTemplateMap);
validateAreValuesDefined(ThemeDefaults);

validateContainsAllKeys(OVPItemTypeRoutes, OVPItemType);
validateContainsAllKeys(AccedoOneItemActionToRoute, AccedoOneItemAction);

validateAreKeysDefined(OVPItemTypeRoutes);
validateAreKeysDefined(AccedoOneItemActionToRoute);

export {
  AccedoOneContainerTemplateMap,
  AccedoOneContainerTemplates,
  AccedoOneFeedQuery,
  AccedoOneItemAction,
  AccedoOneItemActionToRoute,
  AccedoOnePageTemplateMap,
  AccedoOneTheme,
  OVPItemType,
  OVPItemTypeRoutes,
  ThemeDefaults
};
