import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { AccedoOneContainerTemplates } from '#/config/templates';

import carouselPortraitImg from '#/static/images/carousel-portrait-component.png';
import gridPortraitImg from '#/static/images/grid-portrait-component.png';
import carouselWideImg from '#/static/images/carousel-wide-component.png';
import heroBannerImg from '#/static/images/hero-banner-component.png';
import gridWideImg from '#/static/images/grid-wide-component.png';

import * as tooltip from '#/components/utils/tooltip.scss';
import {
  AVAILABLE_CONTAINER_TEMPLATES,
  SOLO_CONTAINER_TEMPLATE_IDS
} from './editorConstants';
import { templatesPropType } from './commonPropTypes';
import * as styles from './containerCreator.scss';

import { PanelTitle } from './Panel';

export const IMAGES_MAP = {
  [AccedoOneContainerTemplates.HeroBanner]: heroBannerImg,
  [AccedoOneContainerTemplates.CarouselPortrait]: carouselPortraitImg,
  [AccedoOneContainerTemplates.CarouselWide]: carouselWideImg,
  [AccedoOneContainerTemplates.GridPortrait]: gridPortraitImg,
  [AccedoOneContainerTemplates.GridWide]: gridWideImg
};

// For supporting drag-copy: https://github.com/atlassian/react-beautiful-dnd/issues/216
// The effect is not yet polished

const grid = 8;
const dragColorSettings = {
  defaultBackground: 'lightgray',
  dragBackground: '#333333',
  dragColor: '#3dffd8'
};

const getDroppingBack = _snapshot =>
  _snapshot.isDropAnimating && _snapshot.draggingOver === null;

const getItemStyle = ({
  draggableStyle = {},
  draggingFromThisWith,
  isDragging,
  isStaticSquare,
  templateIdMatch
}) => {
  const { defaultBackground, dragBackground, dragColor } = dragColorSettings;
  const isDraggedIcon = isDragging && !isStaticSquare;
  const isStaticIconOfTemplate =
    draggingFromThisWith && isStaticSquare && templateIdMatch;
  const isActiveIcon = isStaticIconOfTemplate || isDraggedIcon;

  const border =
    (isDraggedIcon && `solid 1px ${dragColor}`) ||
    (isStaticIconOfTemplate && `dashed 1px ${dragColor}`) ||
    'solid 1px rgba(0,0,0,0)';

  return {
    background: isActiveIcon ? dragBackground : defaultBackground,
    border,
    color: isActiveIcon && dragColor,
    opacity: isStaticSquare ? 1 : 0,
    padding: `0 ${grid}px`,
    transition: 'background 0.25s ease, color 0.25s ease',
    ...draggableStyle
  };
};

const getListStyle = () => ({
  padding: grid,
  maxWidth: '44em'
});

const getBackgroundTemplateImage = ({
  draggingFromThisWith,
  droppingBack,
  isDragging,
  templateId
}) => {
  const componentUrl = `url("${IMAGES_MAP[templateId]}")`;
  const isStaticIconOfTemplate = draggingFromThisWith === templateId;
  const isActiveIcon = (isStaticIconOfTemplate || isDragging) && !droppingBack;

  const backgroundColor = isActiveIcon && dragColorSettings.dragColor;
  const backgroundImage = !isActiveIcon && componentUrl;
  const webkitMaskImage = isActiveIcon && componentUrl;

  return {
    backgroundColor,
    backgroundImage,
    backgroundSize: 'cover',
    WebkitMaskImage: webkitMaskImage,
    WebkitMaskSize: 'cover'
  };
};

const Content = ({
  availableTemplate,
  draggingFromThisWith,
  droppingBack,
  isDragging
}) => (
  <React.Fragment>
    <div
      className={styles.componentImg}
      style={getBackgroundTemplateImage({
        draggingFromThisWith,
        droppingBack,
        isDragging,
        templateId: availableTemplate.id
      })}
    />
    <div>{availableTemplate.text}</div>
  </React.Fragment>
);

Content.propTypes = {
  availableTemplate: PropTypes.object,
  draggingFromThisWith: PropTypes.string,
  droppingBack: PropTypes.bool,
  isDragging: PropTypes.bool
};

class DraggableWrapper extends React.PureComponent {
  render() {
    return (
      <div style={{ opacity: this.props.droppingBack ? 0 : 1 }}>
        {this.props.children}
      </div>
    );
  }
}
DraggableWrapper.propTypes = {
  children: PropTypes.node,
  droppingBack: PropTypes.bool
};

const ContainerCreator = ({
  availableTemplates,
  isEditionDisabled,
  isSoloPage
}) => {
  return (
    <Droppable droppableId="containerCreator" isDropDisabled>
      {(provided, snapshot) => (
        <div className={styles.leftPanel}>
          <PanelTitle>Containers</PanelTitle>
          <div className={styles.description}>
            Containers are the building blocks of your page. You can drag-n-drop
            them on your page or on the mini-map.
          </div>

          <div style={{ position: 'relative' }}>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                position: 'absolute',
                top: 0,
                ...getListStyle()
              }}
              className={styles.componentPanel}
            >
              {availableTemplates.map((availableTemplate) => {
                return (
                  <div
                    style={{ position: 'relative' }}
                    key={availableTemplate.id}
                  >
                    <div
                      className={styles.componentContainer}
                      style={getItemStyle({
                        draggingFromThisWith: snapshot.draggingFromThisWith,
                        isStaticSquare: true,
                        templateIdMatch:
                          snapshot.draggingFromThisWith === availableTemplate.id
                      })}
                    >
                      <Content
                        availableTemplate={availableTemplate}
                        draggingFromThisWith={snapshot.draggingFromThisWith}
                        droppingBack={getDroppingBack(snapshot)}
                      />
                    </div>
                  </div>
                );
              })}
              {provided.placeholder}
            </div>

            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle()}
              className={styles.componentPanel}
            >
              {availableTemplates.map((availableTemplate, index) => {
                let styleObject = { position: 'relative' };
                let tooltipText = null;
                let tooltipClass = null;
                const disableGrids =
                  isSoloPage === true ||
                  (isSoloPage === false &&
                    SOLO_CONTAINER_TEMPLATE_IDS.indexOf(availableTemplate.id) > -1);
                if (disableGrids) {
                  styleObject = {
                    ...styleObject,
                    opacity: '0.5',
                    background: 'lightgray',
                    borderRadius: '10px'
                  };
                  tooltipText = 'A grid should not be mixed with other containers on a page';
                  tooltipClass = 'tooltip--bottom';
                }

                return (
                  <div data-tooltip={tooltipText} className={tooltipClass}>
                    <div style={styleObject} key={availableTemplate.id}>
                      <Draggable
                        data-test="Draggable"
                        draggableId={availableTemplate.id}
                        index={index}
                        isDragDisabled={isEditionDisabled || disableGrids}
                      >
                        {(_provided, _snapshot) => {
                          const droppingBack = getDroppingBack(_snapshot);
                          return (
                            <DraggableWrapper droppingBack={droppingBack}>
                              <div
                                ref={_provided.innerRef}
                                {..._provided.draggableProps}
                                {..._provided.dragHandleProps}
                                style={getItemStyle({
                                  draggableStyle:
                                    _provided.draggableProps.style,
                                  isDragging: _snapshot.isDragging,
                                  isStaticSquare: false
                                })}
                                className={styles.componentContainer}
                              >
                                <Content
                                  availableTemplate={availableTemplate}
                                  droppingBack={droppingBack}
                                  isDragging={_snapshot.isDragging}
                                />
                              </div>
                            </DraggableWrapper>
                          );
                        }}
                      </Draggable>
                    </div>
                  </div>
                );
              })}
              {provided.placeholder}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

ContainerCreator.propTypes = {
  availableTemplates: templatesPropType,
  isEditionDisabled: PropTypes.bool,
  isSoloPage: PropTypes.bool
};

ContainerCreator.defaultProps = {
  availableTemplates: AVAILABLE_CONTAINER_TEMPLATES
};

export default ContainerCreator;
