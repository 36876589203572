import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { models } from '@accedo/vdkweb-vikimap/lib/ui';
import { Menu } from '@accedo/vdkweb-ui';

import modules from '#/redux/modules';
import appConfig from '#/config/app';

import { getTargetForMenuItem } from './vikimapMenuHelpers';

const prefix = appConfig.urlPrefix;

const mapMenuItem = (item = {}) => {
  const { displayText, menuItems = [] } = item;
  const menuItem = {
    displayText,
    to: (item.to || getTargetForMenuItem(item)).replace(/^\//, prefix),
    items: menuItems.map(mapMenuItem),
    icon: item.icon && item.icon[0] ? item.icon[0].fileUrl : null
  };
  return menuItem;
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const entry = modules.vikimap.selectors.getMenuById(state, id);

  // If no entry is found, set 'loaded' to false
  // to signal that we're in loading state.
  if (!entry || !(entry.id || entry.content) || entry.__isError) {
    return {
      entryId: id,
      loaded: false,
      failedToLoad: !!(entry && entry.__isError),
      errorMessage: entry && entry.content ? entry.content.message : ''
    };
  }

  // If no entry information is found set 'loaded' to true
  // to signal that the entry has been loaded.
  // Also propagate the entry to the props.
  return {
    entry,
    entryId: id,
    loaded: true,
    error: entry.__isError
  };
};

class VikimapMenu extends Component {
  componentDidMount() {
    this.props.dispatch(modules.vikimap.actions.fetchMenu(this.props.entryId));
  }

  render() {
    const { entry = {}, loaded, ...others } = this.props;
    const { items, staticItems = [] } = entry;

    return (
      <Menu
        items={
          loaded && items ? items.concat(staticItems).map(mapMenuItem) : []
        }
        loaded={loaded}
        {...others}
      />
    );
  }
}

VikimapMenu.propTypes = {
  dispatch: PropTypes.func,
  entry: PropTypes.object,
  entryId: PropTypes.string,
  items: PropTypes.arrayOf(models.MenuItem),
  loaded: PropTypes.bool,
  staticItems: PropTypes.array
};

export default connect(mapStateToProps)(VikimapMenu);
