export const EDITION_STATUS = {
  IN_PROGRESS: 'in-progress',
  REVIEW: 'review',
  READY_TO_PUBLISH: 'ready',
  PUBLISHED: 'published'
};

export const EDITION_STATUS_ORDER = [
  EDITION_STATUS.IN_PROGRESS,
  EDITION_STATUS.REVIEW,
  EDITION_STATUS.READY_TO_PUBLISH,
  EDITION_STATUS.PUBLISHED
];

export const EDITION_STATUS_NAME = {
  [EDITION_STATUS.IN_PROGRESS]: 'In Progress',
  [EDITION_STATUS.REVIEW]: 'Review',
  [EDITION_STATUS.READY_TO_PUBLISH]: 'Ready to publish',
  [EDITION_STATUS.PUBLISHED]: 'Published'
};

export const ACTIVITY_TYPE = {
  STATUS_CHANGED: 'statusChanged'
};

export const EDITION_TAGS_GROUPS = [
  {
    name: 'Platforms',
    id: 'platforms',
    items: [
      { id: 'android', name: 'Android' },
      { id: 'ios', name: 'iOS' },
      { id: 'stb', name: 'STB' },
      { id: 'web', name: 'Web' }
    ]
  },
  {
    name: 'Locations',
    id: 'location',
    items: [
      { id: 'singapore', name: 'Singapore' },
      { id: 'hong-kong', name: 'Hong Kong' }
    ]
  }
];

export const EDITION_TAGS_GROUP_ID_TO_NAME = EDITION_TAGS_GROUPS.reduce(
  (acc, tagGroup) => {
    acc[tagGroup.id] = tagGroup.name;
    return acc;
  },
  {}
);

export const EDITION_TAG_ID_TO_NAME = EDITION_TAGS_GROUPS.reduce(
  (acc, tagGroup) => {
    (tagGroup.items || []).forEach(tagItem => {
      acc[tagItem.id] = tagItem.name;
    });

    return acc;
  },
  {}
);

EDITION_TAGS_GROUPS.reduce((acc, tagGroup) => {
  (tagGroup.items || []).forEach(tagItem => {
    if (acc[tagItem.id]) {
      throw new Error(
        `Registering duplicate tag id: ${tagItem.id} - ${acc[tagItem.id]} - ${
          tagGroup.id
        }`
      );
    }

    acc[tagItem.id] = tagGroup.id;
  });

  return acc;
});
