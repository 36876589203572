import {
  validateAreValuesDefined,
  validateContainsAllKeys
} from '#/utils/validations';

import ShelfContainersIds from './ShelfContainersIds';
import CarouselShelf from './CarouselShelf';
import GridShelf from './GridShelf';
import HeroShelf from './HeroShelf';

export const determineItemTypeByTemplate = ({ template, OVPItemType }) => {
  if (template.includes('portrait')) {
    return OVPItemType.Portrait;
  }
  if (template.includes('wide') || template.includes('hero-banner')) {
    return OVPItemType.Wide;
  }
  return OVPItemType.Square;
};

export const ContainerIdToComponent = {
  [ShelfContainersIds.Carousel]: CarouselShelf,
  [ShelfContainersIds.Default]: GridShelf,
  [ShelfContainersIds.Grid]: GridShelf,
  [ShelfContainersIds.HeroBanner]: HeroShelf
};

validateAreValuesDefined(ContainerIdToComponent);
validateContainsAllKeys(ContainerIdToComponent, ShelfContainersIds);
