import appSwitcher from './appSwitcher';

const menuItemEntryTypeId =
  process.env.ACCEDO_ONE_ENTRY_TYPE_MENU_ITEM || appSwitcher.entryTypeIds.menuItem;
const containerEntryTypeId =
  process.env.ACCEDO_ONE_ENTRY_TYPE_CONTAINER || appSwitcher.entryTypeIds.container;
const pageEntryTypeId =
  process.env.ACCEDO_ONE_ENTRY_TYPE_PAGE || appSwitcher.entryTypeIds.page;
const itemEntryTypeId =
  process.env.ACCEDO_ONE_ENTRY_TYPE_ITEM || appSwitcher.entryTypeIds.item;

export {
  containerEntryTypeId,
  itemEntryTypeId,
  menuItemEntryTypeId,
  pageEntryTypeId
};
