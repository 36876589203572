import React from 'react';
import moment from 'moment-mini';
import PropTypes from 'prop-types';

import { ACTIVITY_TYPE, EDITION_STATUS_NAME } from '../editionsConstants';

const parseDate = time => moment(time).format('YYYY-MM-DD HH:mm:SS');

const getActivityTimestamp = v =>
  v.isVersion ? moment(v.date).valueOf() : v.when;

const VersionsList = ({
  editionCreatedOn,
  extraActivities,
  getShowRestoreButton,
  highlightedVersionId,
  onRestoreClick,
  onVersionClick,
  versions
}) => {
  const getActivityMessage = (activity, idx, arr) => {
    if (activity.isVersion) {
      return idx === arr.length - 1
        ? `Edition Created (${parseDate(new Date(editionCreatedOn))})`
        : `${activity.message} - ${parseDate(activity.date)}`;
    }

    if (activity.type === ACTIVITY_TYPE.STATUS_CHANGED) {
      return `From "${EDITION_STATUS_NAME[activity.value.from]}" to "${
        EDITION_STATUS_NAME[activity.value.to]
      }" - ${parseDate(activity.when)}`;
    }

    return `${activity.type} - ${parseDate(activity.when)}`;
  };

  const activities = (versions || [])
    .map(v => ({
      ...v,
      isVersion: true
    }))
    .concat(extraActivities.map(a => ({ ...a, isVersion: false })))
    // @TODO: This sort can be more efficient as both arrays are sorted
    .sort((a, b) => {
      const timeA = getActivityTimestamp(a);
      const timeB = getActivityTimestamp(b);

      return timeB - timeA;
    })
    .map((activity, idx, arr) => {
      return {
        id: activity.isVersion ? activity.id : activity.when,
        isVersion: activity.isVersion,
        message: getActivityMessage(activity, idx, arr)
      };
    });

  return versions ? (
    activities.map((activity, idx) => (
      <div
        key={activity.id}
        onClick={() => onVersionClick(versions[idx])}
        style={{
          backgroundColor:
            activity.id === highlightedVersionId ? '#777' : '#222',
          color: '#ddd',
          cursor: onVersionClick ? 'pointer' : 'default',
          fontSize: 12,
          marginBottom: 12,
          minHeight: 10,
          padding: 7,
          width: '100%'
        }}
      >
        {activity.message}
        {getShowRestoreButton({ version: versions[idx], index: idx }) && (
          <button
            onClick={e => {
              e.stopPropagation();
              onRestoreClick(versions[idx]);
            }}
          >
            Restore
          </button>
        )}
      </div>
    ))
  ) : (
    <div>Loading ...</div>
  );
};

VersionsList.propTypes = {
  editionCreatedOn: PropTypes.number,
  extraActivities: PropTypes.array,
  getShowRestoreButton: PropTypes.func,
  highlightedVersionId: PropTypes.string,
  onRestoreClick: PropTypes.func,
  onVersionClick: PropTypes.func,
  versions: PropTypes.array
};

VersionsList.defaultProps = {
  extraActivities: [],
  getShowRestoreButton: () => false
};

export default VersionsList;
