import React from 'react';
import PropTypes from 'prop-types';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

const SliderTheme = {
  trackSize: 8,
  trackColor: 'rgba(200, 200, 200, 0.55)',
  selectionColor: '#e20074',
  rippleColor: 'transparent',
  handleSizeDisabled: 0,
  handleSizeActive: 0,
  handleColorZero: 'transparent',
  handleFillColor: '#e20074'
};

const createPlayerControls = ({
  AudioTracks,
  ClosedCaptions,
  FullScreen,
  PlayPause,
  PlayerControls,
  SeekBar,
  VolumeControls
}) => {
  const CustomPlayerControls = props => {
    const showCc = props.allowCc && props.hasCcTracks;
    const showAudioTracks =
      props.allowAudioTrackChange && props.hasMultipleAudioTracks;
    const showFullScreen = props.allowFullScreen;

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(SliderTheme)}>
        <PlayerControls {...props}>
          <PlayPause />
          <VolumeControls />
          <SeekBar />
          {showCc && <ClosedCaptions />}
          {showAudioTracks && <AudioTracks />}
          {showFullScreen && <FullScreen />}
        </PlayerControls>
      </MuiThemeProvider>
    );
  };

  CustomPlayerControls.propTypes = {
    allowAudioTrackChange: PropTypes.bool,
    allowCc: PropTypes.bool,
    allowFullScreen: PropTypes.bool,
    hasCcTracks: PropTypes.bool,
    hasMultipleAudioTracks: PropTypes.bool
  };

  return CustomPlayerControls;
};

export default createPlayerControls;
