import React, { useEffect, useRef, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import PropTypes from 'prop-types';

import * as cssStyles from './panel.scss';

const PanelPosition = {
  Left: 'left',
  Right: 'right'
};

const PanelTitle = ({ children, style }) => {
  return (
    <div
      style={{
        fontSize: 15,
        fontWeight: 600,
        padding: 8,
        textTransform: 'uppercase',
        ...style
      }}
    >
      {children}
    </div>
  );
};

PanelTitle.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
};

PanelTitle.defaultProps = {
  style: {}
};

const Panel = ({
  animationSecs,
  children,
  isHidden,
  onTriggerClick,
  position,
  style,
  width
}) => {
  const stateRef = useRef({});
  const [renderChildren, setRenderChildren] = useState(!isHidden);

  useEffect(() => {
    const { current } = stateRef;
    clearTimeout(current.timeoutId);

    if (isHidden && stateRef.current.isHidden === false) {
      current.timeoutId = setTimeout(() => {
        setRenderChildren(false);
      }, animationSecs * 1000);
    } else if (!isHidden && !renderChildren) {
      setRenderChildren(true);
    }

    current.isHidden = false;

    return () => {
      clearTimeout(current.timeoutId);
    };
  }, [isHidden]);

  const transition = `ease-out ${animationSecs}s all`;
  return (
    <React.Fragment>
      {onTriggerClick && (
        <div
          onClick={onTriggerClick}
          className={cssStyles.trigger}
          style={{
            [position]: isHidden ? 0 : width,
            transition
          }}
        >
          Trigger
        </div>
      )}
      <div
        className={cssStyles.wrapper}
        style={{
          [position]: isHidden ? -width : 0,
          boxShadow: `inset ${
            position === PanelPosition.Left ? -1 : 1
          }px 0 0 0 rgba(255, 255, 255, 0.2)`,
          transition,
          width,

          ...style
        }}
      >
        <Scrollbar disableTracksWidthCompensation={true}>{renderChildren && children}</Scrollbar>
      </div>
    </React.Fragment>
  );
};

Panel.propTypes = {
  animationSecs: PropTypes.number,
  children: PropTypes.node,
  isHidden: PropTypes.bool,
  onTriggerClick: PropTypes.func,
  position: PropTypes.oneOf([PanelPosition.Left, PanelPosition.Right]),
  style: PropTypes.object,
  width: PropTypes.any // @TODO: this was number, fix this to support percentage
};

Panel.defaultProps = {
  animationSecs: 0.5,
  position: PanelPosition.Left,
  style: {}
};

export { PanelPosition, PanelTitle };

export default Panel;
