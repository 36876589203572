import React from 'react';
import PropTypes from 'prop-types';

import { scaledPixel } from '#/elevate/theme/scale';
import { EDITOR_FOOTER_HEIGHT, PLATFORMS } from './editorConstants';
import * as styles from './scrollbar.scss';

const { desktop, mobile, ctv } = PLATFORMS;

const ViewWrapper = ({ currentPlatform, top, children, left }) => {
  const width = 100 - left * 2;
  const viewWidth = `${width}%`;
  const viewLeft = `${left}%`;

  let platformStyling = {};
  if (currentPlatform === mobile.value) {
    platformStyling = {
      left: 0,
      margin: 'auto',
      overflowX: 'hidden',
      overflowY: 'auto',
      right: 0,
      top: 60,
      width: 396,
      height: 706,
      backgroundColor: '#222'
    };
  } else if (currentPlatform === ctv.value) {
    platformStyling = {
      margin: 'auto',
      overflowX: 'hidden',
      overflowY: 'auto',
      width: scaledPixel(1920),
      height: scaledPixel(1080),
      borderBottom: '3px solid gray'
    };
  }

  return (
    <div style={{
      paddingTop: top,
      left: viewLeft,
      width: viewWidth,
      position: 'relative',
      height: '100%'
    }}>
      <div
        id='preview'
        key={currentPlatform}
        className={styles.bar}
        style={{
          position: 'relative',
          marginBottom: EDITOR_FOOTER_HEIGHT,
          ...platformStyling
        }}
      >
        {children}
      </div>
    </div>
  );
};

ViewWrapper.propTypes = {
  children: PropTypes.node,
  currentPlatform: PropTypes.oneOf([
    desktop.value,
    mobile.value,
    ctv.value
  ]),
  left: PropTypes.any,
  top: PropTypes.any
};

export default ViewWrapper;
