import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Scrollbar from 'react-scrollbars-custom';

const createNewOrderedArray = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'rgba(20, 85, 72, 0.6)' : 'lightgray',
  position: 'relative',
  width: '100%',
  borderRadius: 3,
  height: 95,
  color: isDragging ? 'rgba(61, 255, 216, 1)' : '#606060',
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'rgba(20, 85, 72, 0.6)' : '#23282d',
  margin: grid,
  width: 250
});

const Column = ({
  ColumnItem,
  getIsDragDisabled,
  getKeyFromItem,
  id,
  isDropDisabled,
  itemProps,
  items,
  name,
  onItemClick
}) => {
  return (
    <div style={{ display: 'inline-block', flexGrow: 1 }}>
      <h3 style={{ textAlign: 'left', margin: 12 }}>{name}</h3>

      <Droppable droppableId={id} isDropDisabled={isDropDisabled}>
        {(providedTop, snapshotTop) => (
          <div
            ref={providedTop.innerRef}
            style={{
              ...getListStyle(snapshotTop.isDraggingOver),
              border: '1px blue',
              borderRadius: 5,
              height: '100%',
              minHeight: 500,
              overflow: 'auto',
              padding: 10
            }}
          >
            <Scrollbar>
              {items.map((item, index) => (
                <Draggable
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={getIsDragDisabled(item)}
                  key={getKeyFromItem(item)}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      onClick={() => onItemClick?.(item)}
                    >
                      <ColumnItem
                        item={item}
                        index={index}
                        columnId={id}
                        isDragged={snapshot.isDragging}
                        {...itemProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {providedTop.placeholder}
            </Scrollbar>
          </div>
        )}
      </Droppable>
    </div>
  );
};

Column.propTypes = {
  ColumnItem: PropTypes.func,
  getIsDragDisabled: PropTypes.func,
  getKeyFromItem: PropTypes.func,
  id: PropTypes.string,
  isDropDisabled: PropTypes.bool,
  itemProps: PropTypes.object,
  items: PropTypes.array,
  name: PropTypes.string,
  onItemClick: PropTypes.func
};

const ColumnsDraggableItems = ({
  ColumnItem,
  columnIndexToDestinations,
  columnNames,
  columns,
  getIsDragDisabled,
  getKeyFromItem,
  itemProps,
  onColumnsUpdate,
  onItemClick,
  style
}) => {
  const [draggingSource, setDraggingSource] = useState(null);
  const onDragStart = opts => {
    setDraggingSource(opts.source);
  };
  const onDragEnd = resultTop => {
    const { source, destination } = resultTop;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const updatedItem = columns[source.droppableId][source.index];

    if (source.droppableId === destination.droppableId) {
      const newSubItems = createNewOrderedArray(
        columns[source.droppableId],
        source.index,
        destination.index
      );
      const newColumns = [...columns];
      newColumns[source.droppableId] = newSubItems;

      onColumnsUpdate({ newColumns, updatedItem, updatedColumn: null });
    } else {
      const result = move(
        columns[source.droppableId],
        columns[destination.droppableId],
        source,
        destination
      );
      const newColumns = [...columns];
      newColumns[source.droppableId] = result[source.droppableId];
      newColumns[destination.droppableId] = result[destination.droppableId];
      const updatedColumn = Number(destination.droppableId);

      onColumnsUpdate({ newColumns, updatedItem, updatedColumn });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        ...style
      }}
    >
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {columns.map((columnItems, columnIndex) => {
          const destinations =
            columnIndexToDestinations[draggingSource?.droppableId];
          const isDropDisabled = !(destinations || []).includes(columnIndex);

          return (
            <Column
              ColumnItem={ColumnItem}
              getIsDragDisabled={getIsDragDisabled}
              getKeyFromItem={getKeyFromItem}
              id={columnIndex.toString()}
              isDropDisabled={isDropDisabled}
              itemProps={itemProps}
              items={columnItems}
              key={columnIndex}
              name={columnNames[columnIndex]}
              onItemClick={onItemClick}
            />
          );
        })}
      </DragDropContext>
    </div>
  );
};

ColumnsDraggableItems.propTypes = {
  ColumnItem: PropTypes.func,
  columnIndexToDestinations: PropTypes.array,
  columnNames: PropTypes.array,
  columns: PropTypes.array,
  getIsDragDisabled: PropTypes.func,
  getKeyFromItem: PropTypes.func,
  itemProps: PropTypes.object,
  onColumnsUpdate: PropTypes.func,
  onItemClick: PropTypes.func,
  style: PropTypes.object
};

ColumnsDraggableItems.defaultProps = {
  getIsDragDisabled: () => false,
  getKeyFromItem: i => i.id
};

export default ColumnsDraggableItems;
