import React from 'react';
import PropTypes from 'prop-types';

import ContentGrid from '#/elevate/components/ContentGrid/ContentGrid';
import HeroItem from '#/elevate/components/Item/HeroItem/HeroItem';

import heroBannerTheme from './heroBanner.scss';

const itemWidth = 1908;
const itemHeight = 864;

const HeroShelf = ({
  onItemClick,
  items,
  config = {},
  nav = {},
  containerId,
  selectedEditorEntry
}) => {
  const dir = 'ltr';

  return (
    <ContentGrid
      data={items}
      dir={dir}
      keyProperty="id"
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      width={itemWidth}
      loop
      theme={heroBannerTheme}
      onClick={onItemClick}
      DisplayComponent={HeroItem}
      displayComponentProps={{
        height: itemHeight,
        containerId,
        buttonText: 'More Details'
      }}
      nav={{
        ...nav,
        id: config.id || nav.id
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0
      }}
      showDots
      selectedEditorEntry={selectedEditorEntry}
    />
  );
};

HeroShelf.propTypes = {
  onItemClick: PropTypes.func,
  items: PropTypes.array,
  config: PropTypes.object,
  nav: PropTypes.object,
  containerId: PropTypes.string,
  selectedEditorEntry: PropTypes.object
};

export default HeroShelf;
