import React from 'react';
import PropTypes from 'prop-types';

import AsyncComponent from '#/components/Async/AsyncComponent';

import createPlayerControls from './createPlayerControls';

const Player = props => (
  <AsyncComponent
    moduleProvider={() =>
      import(/* webpackChunkName: "player" */ '@accedo/vdkweb-player')
    }
  >
    {PlayerModule => {
      if (!PlayerModule) {
        return null;
      }

      const { withRedux, Player: HtmlPlayer } = PlayerModule;
      const CustomPlayerControls = createPlayerControls(PlayerModule);
      const ReduxPlayer = withRedux(HtmlPlayer);

      return (
        <ReduxPlayer {...props} src={props.mediaUrl}>
          <CustomPlayerControls />
        </ReduxPlayer>
      );
    }}
  </AsyncComponent>
);

Player.propTypes = {
  mediaUrl: PropTypes.string
};

export default Player;
