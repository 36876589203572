import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@accedo/vdkweb-ui';
import { compose } from 'recompose';

import modules from '#/redux/modules';
import withConfig from '#/containers/utils/withConfig';

import Maintenance from '#/containers/Maintenance/Maintenance';
import { withTheme } from '#/theme/Theme';

const MAINTENANCE_STATUS = 'maintenance';
import setCssVariables from '#/utils/setCssVariables';
import defaultLocalTheme from '#/elevate/theme/defaultTheme.json';

import styles from './app.scss';

const mapStateToProps = state => {
  const data = modules.status.selectors.getRootSelector()(state);

  if (!data || !data.content || data.__isFetching) {
    return {
      loaded: false,
      isLoading: data ? data.__isFetching : false
    };
  }

  return {
    status: data.content,
    loaded: true
  };
};

const mapDispatchToProps = dispatch => ({
  getDataAction: () => dispatch(modules.status.actions.getData())
});

// The entry point of your application. This is where
// you can control rendering based on received application
// status or configurations.

class AppContent extends React.Component {
  componentDidMount() {
    const { getDataAction, isLoading } = this.props;
    if (isLoading) {
      return;
    }

    return getDataAction();
  }

  render() {
    const { children, status, config, theme } = this.props;
    
    // If we haven't yet received an application status, we'll
    // simply return an empty div.
    // Can preferably be replaced with a SplashScreen component.
    if (!status) {
      return <Spinner />;
    }

    // If we're in maintenance mode, we'll display
    // the given message or fall back to a default one.
    if (status.status === MAINTENANCE_STATUS) {
      return <Maintenance message={status.message} />;
    }

    // We require the configuration to have been
    // provided before we render the actual app content.
    // If we don't have it yet we simply display the spinner.
    // Can preferably be replaced with a SplashScreen component.
    if (!config) {
      return <Spinner />;
    }

    setCssVariables(config.theme || defaultLocalTheme);

    // At this point we have both an active status and
    // a configuration object. Let's render the app!
    return (
      <div className={`${styles.root} ${styles[theme.name]}`} id="root-view">
        {children}
      </div>
    );
  }
}

AppContent.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object,
  getDataAction: PropTypes.func,
  isLoading: PropTypes.bool,
  status: PropTypes.object,
  theme: PropTypes.object
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
  withConfig
);

// Finally also make sure that the app configuration is requested
// for the AppEntry. This enables us to get e.g. Accedo One configs
// before we actually render any app content.
export default enhance(AppContent);
