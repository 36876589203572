import { AccedoOneContainerTemplates } from '#/config/templates';

export const AVAILABLE_CONTAINER_TEMPLATES = [
  { id: AccedoOneContainerTemplates.CarouselPortrait, text: 'Carousel Portrait' },
  { id: AccedoOneContainerTemplates.CarouselWide, text: 'Carousel Wide' },
  { id: AccedoOneContainerTemplates.HeroBanner, text: 'Hero Banner' },
  { id: AccedoOneContainerTemplates.GridPortrait, text: 'Grid Portrait' },
  { id: AccedoOneContainerTemplates.GridWide, text: 'Grid Wide' }
];

export const SOLO_CONTAINER_TEMPLATE_IDS = [
  AccedoOneContainerTemplates.GridPortrait,
  AccedoOneContainerTemplates.GridWide
];

export const EDITOR_TOP_BAR_HEIGHT = 36;
export const EDITOR_FOOTER_HEIGHT = 30;

export const ACCEDO_ENTRIES_QUERY = 'ACCEDO_ENTRIES';
export const BRIGHTCOVE_ENTRIES_QUERY = 'brightcove';

export const STORAGE_KEY_VERSION_PREVIEW = 'versionId';

export const ENTRY_TAGS_GROUPS = [
  {
    name: 'User Tier',
    id: 'user-tier',
    items: [
      { id: 'tier-1', name: 'Platinum' },
      { id: 'tier-2', name: 'Gold' },
      { id: 'tier-3', name: 'Silver' }
    ]
  }
];

export const PLATFORMS = {
  desktop: {
    value: 'desktop',
    label: 'Desktop'
  },
  mobile: {
    value: 'mobile',
    label: 'Mobile'
  },
  ctv: {
    value: 'ctv',
    label: 'CTV'
  }
};

export const MENUITEM_STATE = {
  LOADED: 'loaded',
  DELETED: 'deleted',
  PENDING: 'pending'
};
export const FETCH_INTERVAL = 10000;
