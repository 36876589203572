import config from '#/config';

const prefix = config.app.urlPrefix;

export const NOOP = () => null;

export const createRandomString = () => {
  return Math.random()
    .toString(36)
    .substr(2, 10);
};

export const redirectToUrl = url => {
  setTimeout(() => {
    const newUrl = prefix + url.replace(/^\//, '');

    // found some issues with redirecting with react router
    global.location.replace(newUrl);
  }, 100);
};
