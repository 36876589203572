import React from 'react';
import PropTypes from 'prop-types';

import unknownUser from '#/static/images/unknown-profile.svg';

const UserInfo = ({ userInfo }) => {
  return (
    <React.Fragment>
      <div
        style={{
          color: '#eee',
          display: 'inline-block',
          fontSize: 12,
          margin: '0 20px'
        }}
      >
        {userInfo.OIDC_CLAIM_NAME || 'Guest'}
      </div>
      <div
        style={{
          display: 'inline-block',
          width: 30
        }}
      >
        <img
          src={userInfo.OIDC_CLAIM_PICTURE || unknownUser}
          alt="avatar"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 10,
            ...(userInfo.OIDC_CLAIM_NAME
              ? {
                  maxWidth: 30,
                  borderRadius: 30
                }
              : {})
          }}
        />
      </div>
    </React.Fragment>
  );
};

UserInfo.propTypes = {
  userInfo: PropTypes.object
};

UserInfo.defaultProps = {
  userInfo: {}
};

export default UserInfo;
