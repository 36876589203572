import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { withFocus } from '@accedo/vdkweb-navigation';
import {
  SearchIcon,
  UserIcon,
  SettingsIcon
} from '#/elevate/components/Icons/Icons';
import style from './menuItem.scss';

const icons = {
  faUser: ({ className }) => (
    <UserIcon className={`${className} ${style.user}`} />
  ),
  faSearch: ({ className }) => <SearchIcon className={className} />,
  faInfoCircle: ({ className }) => <SettingsIcon className={className} />,
  faProfile: ({ className }) => (
    <UserIcon className={`${className} ${style.user}`} />
  ),
  faSettings: ({ className }) => <SettingsIcon className={className} />
};

/**
 * MenuItem Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.data The content to be displayed
 * @returns {React.ReactComponentElement} Component
 */
const MenuItem = ({ data, nav }) => {
  const { pathname } = useLocation();
  const { faIcon, image } = data;
  const userImg = image ? (
    <img className={style.profilePicture} src={image} alt="" />
  ) : null;
  const isCurrent = data.to.indexOf(pathname) === 0;
  const className = `${style.item} ${style.primary}`;
  const itemClassName = `${className} ${
    nav.isFocused ? style['item-focused'] : ''
  } ${isCurrent ? style['item-current'] : ''}`;

  const itemIcon =
    faIcon && icons[faIcon]
      ? icons[faIcon]({ className: `${className} ${style.icon}` })
      : null;

  const itemContent = userImg || itemIcon || data.displayText;

  return (
    <div
      className={itemClassName}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <button style={{ 'line-height': '1.375vw' }}>
        <NavLink
          to={data.to}
          id={`menuitem_${data.displayText.toLowerCase()}`}
          activeClassName={style['item-focused']}
        >
          {itemContent}
        </NavLink>
      </button>
    </div>
  );
};

MenuItem.propTypes = {
  data: PropTypes.object,
  nav: PropTypes.object
};

export default withFocus(MenuItem);
