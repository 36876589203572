const useItemStyle = ({ data, width, height, type = 'poster' }) => {
  const itemStyle = { width, height };

  // adapt to both images and image attributes.
  // return images or image attribute and fallback to empty array.
  const images = [...(data.images || []), ...(data.image || [])];
  if (images.length) {
    let filteredImages = images.filter(image => image.type === type);
    if (filteredImages.length === 0) {
      filteredImages = images;
    }
    const imageUrl = filteredImages[0].url || filteredImages[0].fileUrl;

    // forces the // schema to use http or https depending on the source. this avoid mixed content issues
    itemStyle.backgroundImage = `url(${imageUrl})`.replace(
      /^url\((?:https?):/,
      'url('
    );
    itemStyle.imageUrl = imageUrl;
  }
  return { itemStyle };
};

export default useItemStyle;
