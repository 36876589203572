import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './horizontalBar.scss';

const HorizontalBar = ({ children, height, className }) => {
  return (
    <div
      className={`${styles.wrapper} ${className}`}
      style={{
        height
      }}
    >
      {children}
    </div>
  );
};

HorizontalBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.number
};

export const HeaderBar = props => {
  return <HorizontalBar {...props} className={styles.header} />;
};

export const FooterBar = props => {
  return <HorizontalBar {...props} className={styles.footer} />;
};
