import React from 'react';
import PropTypes from 'prop-types';

import { HeaderBar } from '#/views/EditionsDashboard/components/HorizontalBar/HorizontalBar';
import UserInfo from '#/views/EditionsDashboard/components/UserInfo';

import appSwitcher from '#/config/appSwitcher';
import Select from './Select';
import { EDITOR_TOP_BAR_HEIGHT, PLATFORMS } from './editorConstants';
import Button, { BUTTON_TYPE } from './Button';

const { desktop, mobile, ctv } = PLATFORMS;

const showUserInfo = !appSwitcher.hideUserInfo;

const isEditionsSupported = appSwitcher.backend === 'git';

const PLATFORM_OPTIONS = [
  {
    value: desktop.value,
    label: desktop.label
  },
  {
    value: mobile.value,
    label: mobile.label
  },
  {
    value: ctv.value,
    label: ctv.label
  }
];

const sideStyle = {
  top: 4,
  bottom: 4
};

const TopBar = ({
  currentPlatform,
  isEditionDisabled,
  isVersionsModalOpen,
  onPageModalClick,
  onPlatformUpdate,
  onVersionsButtonClick,
  userInfo
}) => {
  return (
    <HeaderBar height={EDITOR_TOP_BAR_HEIGHT}>
      <div style={{ position: 'absolute', left: 10, ...sideStyle }}>
      { isEditionsSupported && (
        <Button onClick={onVersionsButtonClick} type={BUTTON_TYPE.EMPTY_THIN}>
          {isVersionsModalOpen ? 'CLOSE HISTORY' : 'HISTORY'}
        </Button>
      )}
        {isEditionDisabled && (
          <b style={{ marginLeft: 10 }}>Editing Disabled</b>
        )}
      </div>
      <div style={{ position: 'absolute', right: 5, ...sideStyle }}>
        <Button
          onClick={onPageModalClick}
          type={BUTTON_TYPE.EMPTY_THIN}
          disabled={isEditionDisabled}
        >
          REVIEW PAGES
        </Button>
        {showUserInfo && <UserInfo userInfo={userInfo} /> }
      </div>
      <div
        style={{
          display: 'inline-block',
          width: 150,
          marginTop: 5
        }}
      >
        <Select
          options={PLATFORM_OPTIONS}
          value={PLATFORM_OPTIONS.find(q => q.value === currentPlatform)}
          onChange={({ value }) => {
            onPlatformUpdate(value);
          }}
        />
      </div>
    </HeaderBar>
  );
};

TopBar.propTypes = {
  currentPlatform: PropTypes.oneOf([
    desktop.value,
    mobile.value,
    ctv.value
  ]),
  isEditionDisabled: PropTypes.bool,
  isVersionsModalOpen: PropTypes.bool,
  onPageModalClick: PropTypes.func,
  onPlatformUpdate: PropTypes.func,
  onVersionsButtonClick: PropTypes.func,
  userInfo: PropTypes.object
};

TopBar.defaultProps = {
  currentPlatform: desktop.value,
  onPageModalClick: () => null,
  onPlatformUpdate: () => null,
  onVersionsButtonClick: () => null,
  userInfo: {}
};

export default TopBar;
