import React from 'react';
import PropTypes from 'prop-types';

const ICON_SIZE = 26;
const SPACE_FOR_ICON = 10;

const Divider = ({ icon, style }) => {
  return (
    <div style={{ position: 'relative', ...style }}>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          boxShadow: '0 1px 0 0 rgba(255, 255, 255, 0.1)',
          height: 1,
          marginBottom: 20,
          width: '100%'
        }}
      />
      {icon && (
        <div
          style={{
            // to avoid hardcoding the color here, the divider could draw two
            // lines instead or accept the background as a prop, but that is
            // not necessary yet
            backgroundColor: '#23282d',
            height: ICON_SIZE,
            left: 0,
            margin: 'auto',
            padding: `0 ${SPACE_FOR_ICON}px`,
            position: 'absolute',
            right: 0,
            top: -ICON_SIZE / 2,
            width: ICON_SIZE + SPACE_FOR_ICON * 2
          }}
        >
          <div
            style={{
              WebkitMaskImage: `url(${icon})`,
              WebkitMaskSize: 'cover',
              backgroundColor: '#777',
              height: ICON_SIZE,
              width: ICON_SIZE
            }}
          />
        </div>
      )}
    </div>
  );
};

Divider.propTypes = {
  icon: PropTypes.string,
  style: PropTypes.object
};

Divider.defaultProps = {
  style: {}
};

export default Divider;
