import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { scaledPixel } from '#/elevate/theme/scale';
import getResolution from '#/utils/getResolution';
import { R720p } from '#/elevate/theme/variables/breakpoints';
import { ACCEDO_CONTROL_CONTAINER_TEMPLATES } from '#/config/constants';

import styles from './Container.scss';

const {
  hero,
  continueWatching,
  carouselPortrait,
  carouselWide,
  carouselCategoryPortrait,
  carouselCategoryWide,
  gridHorizontalPortrait,
  gridHorizontalWide,
  gridVerticalPortrait,
  gridVerticalWide,
  carouselViewAllPortrait,
  carouselCategories,
  filterSort,
  carouselJumpRecommendation
} = ACCEDO_CONTROL_CONTAINER_TEMPLATES;

const Container = ({ children, displayText, id, style, template, index }) => {
  const dir = 'ltr';
  const templateClassName = styles[template] || '';
  const { width } = getResolution();

  const fontStyle = size => ({
    fontSize: `${size}px`,
    lineHeight: `${size}px`
  });
  const margin = index === 0 && template !== 'elevate-hero-banner' ? 86 : 0;
  const customStyle = {
    ...style,
    ...fontStyle(R720p >= scaledPixel(width) ? 28 : 36),
    marginTop: `${margin}px`
  };

  return (
    <div
      className={`${styles['outer-container']} ${templateClassName}`}
      style={customStyle}
    >
      {displayText && (
        <div id={id} className={styles['container-title']} dir={dir}>
          {displayText}
        </div>
      )}
      <div
        id={displayText ? null : id}
        className={`${styles['inner-container']} ${styles[dir]}`}
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  displayText: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  template: PropTypes.oneOf([
    hero,
    continueWatching,
    carouselPortrait,
    carouselWide,
    carouselCategoryPortrait,
    carouselCategoryWide,
    gridHorizontalPortrait,
    gridHorizontalWide,
    gridVerticalPortrait,
    gridVerticalWide,
    carouselViewAllPortrait,
    carouselCategories,
    filterSort,
    carouselJumpRecommendation
  ]),
  index: PropTypes.number
};

export default React.memo(Container, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
