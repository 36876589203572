import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { RoundButton } from '#/elevate/components/RoundButton/RoundButton';
import ImageWithPreload from '#/elevate/components/ImageWithPreload/ImageWithPreload';
import useItemStyle from '#/elevate/hooks/useItemStyle';

import heroItemTheme from './heroItem.scss';

const HeroItem = ({
  onClick,
  theme = heroItemTheme,
  nav = {},
  data,
  containerStyle,
  buttonText,
  height,
  width,
  containerId
}) => {
  const backgroundClassName = `${theme.background} ${
    nav.isFocused ? theme['background-focused'] : ''
  }`;
  const { itemStyle } = useItemStyle({ data, width, height, type: 'backdrop' });

  return (
    <div style={{ ...containerStyle }}>
      <div
        className={backgroundClassName}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        <ImageWithPreload src={itemStyle.imageUrl} />
        <div className={theme['content-container']}>
          <div className={theme.title}>{data.displayText || data.title}</div>
          <RoundButton
            className={theme.button}
            onClick={event => onClick({
              event,
              item: data,
              containerId
            })}
            displayText={buttonText || 'moreDetails'}
            nav={nav}
          />
        </div>
      </div>
    </div>
  );
};

HeroItem.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.object,
  containerStyle: PropTypes.any,
  height: PropTypes.number,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  width: PropTypes.string,
  containerId: PropTypes.string
};

export default withFocus(HeroItem);
