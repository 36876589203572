import React from 'react';
import PropTypes from 'prop-types';

import PageTitle from '#/components/PageTitle/PageTitle';
import appConfig from '#/config/app';

import * as styles from '../views.scss';

const { urlPrefix } = appConfig;

const ToBeImplemented = ({ location }) => (
  <div>
    <PageTitle text={location.pathname.replace(urlPrefix, '')} />
    <div className={styles.pageContent}>
      <p>
        The page for this route is yet to be implemented. This is merely a
        placeholder.
      </p>
    </div>
  </div>
);

ToBeImplemented.propTypes = {
  location: PropTypes.object
};

export default ToBeImplemented;
