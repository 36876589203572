const origWarn = console.warn.bind(console);

const messagesToHide = [
  'Warning: componentWillMount has been renamed',
  'Warning: componentWillReceiveProps has been renamed'
];

console.warn = (...data) => {
  const msg = data?.[0];

  if (msg?.includes) {
    for (let i = 0; i < messagesToHide.length; i += 1) {
      const messageToHide = messagesToHide[i];

      if (msg.includes(messageToHide)) {
        return;
      }
    }
  }

  origWarn(...data);
};
