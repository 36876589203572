const use = process.env.APP_USE || 'STA_2023'; // STA_NEW/VDK_REF

// Old sta demo app key: '5d3630871de1c4001c351fff' - deprecated
// Old vdk ref app key: '57563c0f9c9dad01e7b696c3' - for comparison during development of new app
// New STA key: 63efa1f7a0e84500175781a6 - current (under development)
// https://one.accedo.tv/control/application-group/63efa1c6a0e84500175781a0/application/63efa1f7a0e84500175781a6

// PlatformIds:
// {id: "5d3630511de1c4001b3c556f", accountId: "5d3567b41de1c4001c351f93", name: "web",…}
// {id: "63efa1f7a0e84500175781a5", accountId: "5d3567b41de1c4001c351f93", name: "CTV",…}
// {id: "63f38dcaa0e84500175781e2", accountId: "5d3567b41de1c4001c351f93", name: "Mobile",…}

const app = {
  STA_2023: {
    appKey: '63efa1f7a0e84500175781a6',
    appGroupId: '63efa1c6a0e84500175781a0',
    platformId: '63efa1f7a0e84500175781a5', // CTV
    backend: 'one', // ['one' || 'git']
    hideUserInfo: true,
    hideTags: true,
    containerTemplates: {
      CarouselPortrait: 'elevate-carousel-portrait',
      CarouselWide: 'elevate-carousel-wide',
      GridPortrait: 'elevate-grid-vertical-portrait',
      GridWide: 'elevate-grid-vertical-wide',
      HeroBanner: 'elevate-hero-banner'
    },
    pageTemplates: {
      default: 'elevate-modular-ui',
      editable: ['elevate-modular-ui', 'elevate-category', 'elevate-view-all']
    },
    entryTypeIds: {
      menuItem: '63efa345a0e8450017fe7454',
      container: '63efa345a0e8450017fe7451',
      page: '63efa345a0e8450017fe7452',
      item: '63efa345a0e8450017fe7455'
    }
  },
  VDK_REF: {
    appKey: '5d3630871de1c4001c351fff',
    appGroupId: '5d362f301de1c4001c351ff9',
    platformId: '5d3630511de1c4001b3c556f', // web
    backend: 'git', // ['one' || 'git']
    containerTemplates: {
      CarouselPortrait: 'carousel-portrait',
      CarouselWide: 'carousel-wide',
      GridPortrait: 'grid-portrait',
      GridWide: 'grid-wide',
      HeroBanner: 'hero-banner'
    },
    pageTemplates: {
      default: 'default',
      editable: ['default']
    },
    entryTypeIds: {
      menuItem: '5d36b8c523eec6001c798ea7',
      container: '5d36b81a23eec6001c798e93',
      page: '5d3734aaa0e845001caedfeb',
      item: '5d36b8a0a0e845001ab5a38b'
    }
  }
};

export default app[use];
