import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getProtocolRelativePathUrl } from '#/utils/url';

/**
 * Component for having an Image (Isolated or inside Items) with preload
 * It will include an load effect changing styles from loading to loaded
 *
 * @param {string} src image src url
 * @param {string} alt image alt text
 * @param {Object} style Image styles (width, height, ...)
 * @param {Object} className module className to use
 * @param {string} height Image's height
 * @param {string} width Image's width
 * @param {bool} viewAll placeholder to use.
 * If viewAll is true, the placeholder will be a text,
 * while if it's false the placeholder will be the app's logo
 *
 * @returns {React.ComponentElement} Component
 */
const ImageWithPreload = ({
  src,
  style,
  className = '',
  alt = '',
  height,
  width,
  viewAll
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const styleImgLoading = {
    opacity: 0,
    height
  };
  const wrapperStyle = {
    height,
    width,
    background: !viewAll
      ? `rgba(91, 91, 91, 0.55) url() no-repeat fixed center`
      : ''
  };

  const styleImgLoaded = {
    opacity: 1,
    transition: 'opacity 1s',
    height,
    position: 'absolute'
  };
  const imgPreloadStyles = imageLoaded ? styleImgLoaded : styleImgLoading;
  const finalImgStyle = {
    height: height || '100%',
    width: width || '100%',
    ...style
  };
  return (
    <div style={wrapperStyle}>
      <div style={imgPreloadStyles}>
        <img
          alt={alt}
          src={getProtocolRelativePathUrl(src)}
          style={finalImgStyle}
          className={className}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      </div>
    </div>
  );
};

ImageWithPreload.propTypes = {
  /** Image src */
  src: PropTypes.string,
  /** Image styles (width, height, ...) */
  style: PropTypes.object,
  /** Image alt text */
  alt: PropTypes.string,
  /** CSS module class name for the image */
  className: PropTypes.string,
  /** Image's dimensions */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Change the placeholder between the viewAll's text and the app's logo */
  viewAll: PropTypes.bool
};

export default ImageWithPreload;
