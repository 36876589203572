import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import config from '#/config';
import reduxModules from '#/redux/modules';
import AppContent from '#/containers/App/AppContent';

// import injectTapEventPlugin from 'react-tap-event-plugin';
// injectTapEventPlugin(); //@todo add back when require('react-dom') could get ^16.0.0

const { title, titleTemplate, meta } = config.app.head;
const { actions } = reduxModules.lifecycle;

// An application shell for listening to mount
// events and trigger appropriate lifecycle
// actions for your app. These would typically
// be used for analytics and similar.
class AppShell extends Component {
  componentDidMount() {
    this.appStart();
  }

  componentWillUnmount() {
    this.appQuit();
  }

  appStart() {
    this.props.dispatch(actions.appStart());
  }

  appQuit() {
    this.props.dispatch(actions.appQuit());
  }

  render() {
    const { accedoOne } = this.props;
    const usedTitle =
      accedoOne && accedoOne.appName
        ? accedoOne.appName
        : title || 'Live Editor';

    return (
      <div>
        <Helmet
          defaultTitle={usedTitle}
          titleTemplate={titleTemplate}
          meta={meta}
          style={[
            {
              type: 'text/css',
              cssText: `
                body, html {
                    width: 100%;
                    height: 100%;
                }
            `
            }
          ]}
        />
        <AppContent {...this.props} />
      </div>
    );
  }
}

AppShell.propTypes = {
  accedoOne: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = state => ({
  accedoOne: reduxModules.config.selectors.getAccedoOne(state)
});

export default connect(mapStateToProps)(AppShell);
