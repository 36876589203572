/*
 *** ACCEDO ONE CONFIGURATIONS ***
 *
 *  Configurations for an Accedo One client.
 */

import accedoOne from '@accedo/accedo-one-express';
import _get from 'lodash/get';
import appSwitcher from './appSwitcher';

export const SESSION_KEY = 'a1_s';
export const DEVICE_ID = 'a1_d';
export const APPLICATION_KEY = 'a1_a';
export const SIXTY_YEARS_IN_MS = 2147483647000; // from node_modules/@accedo/accedo-one-express/src/index.js
const HTTP_ONLY = false; // if cookies need to be retrieved from the client, this should be false

const middlewareUrlPrefix = process.env.MIDDLEWARE_URL_PREFIX || '/';
const middlewareBaseUrl = (process.env.MIDDLEWARE_BASE_URL || '').replace(
  /\/$/,
  ''
);
export default {
  appKey: process.env.ACCEDO_ONE_KEY || appSwitcher.appKey,
  getRequestInfo: req =>
    Object.assign(accedoOne.defaultGetRequestInfo(req), {
      sessionKey: _get(req, `cookies.${SESSION_KEY}`, ''),
      deviceId: _get(req, `cookies.${DEVICE_ID}`, '')
    }),
  onSessionKeyChanged: (key, res) => {
    res?.cookie(SESSION_KEY, key, {
      httpOnly: HTTP_ONLY,
      maxAge: SIXTY_YEARS_IN_MS
    });
  },
  onDeviceIdGenerated: (key, res) => {
    res?.cookie(DEVICE_ID, key, {
      httpOnly: HTTP_ONLY,
      maxAge: SIXTY_YEARS_IN_MS
    });
  },
  middlewareUrlPrefix,
  middlewareUrl: process.env.MIDDLEWARE_BASE_URL
    ? process.env.MIDDLEWARE_BASE_URL + middlewareUrlPrefix
    : '',
  middlewareBaseUrl
};
