import React from 'react';
import PropTypes from 'prop-types';

import UserInfo from './UserInfo';
import { HeaderBar } from './HorizontalBar/HorizontalBar';
import { DASHBOARD_TOP_BAR_HEIGHT } from './dashboardConstants';

const USER_INFO_WRAPPER_STYLE = {
  position: 'absolute',
  right: 0,
  top: 5
};

const DashboardHeader = ({ userInfo }) => {
  return (
    <HeaderBar height={DASHBOARD_TOP_BAR_HEIGHT}>
      <div style={USER_INFO_WRAPPER_STYLE}>
        <UserInfo userInfo={userInfo} />
      </div>
    </HeaderBar>
  );
};

DashboardHeader.propTypes = {
  userInfo: PropTypes.object
};

export default DashboardHeader;
