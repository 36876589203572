import kebabCase from 'lodash/kebabCase';

const getTargetForMenuItem = (item) => {
  const search = '?mode=edit';
  if (item.page?.alias) {
    return `/${item.page.alias}${search}`;
  }

  if (item.page) {
    return `/${item.page}${search}`;
  }

  if (item.action) {
    return `/${item.action}${search}`;
  }

  return `/${kebabCase(item.displayText)}`;
};

const getTargetIdsOfMenuItems = (menuItemsObject) => {
  const menuItemsTargetIds = [];
  const items = Object.values(menuItemsObject);
  items.forEach((item) => {
    menuItemsTargetIds.push(item.page?.id || item.page);
  });

  return menuItemsTargetIds;
};

export { getTargetForMenuItem, getTargetIdsOfMenuItems };
