import { BrowserRouter, HashRouter } from 'react-router-dom';
import { createHashHistory, createBrowserHistory } from 'history';

import appConfig from '#/config/app';

let appHistory;
let Router;

const historyOpts = {
  basename: appConfig.urlPrefix
};

if (
  typeof __HISTORY_TYPE__ !== 'undefined' &&
  __HISTORY_TYPE__ === 'hashHistory'
) {
  appHistory = createHashHistory(historyOpts);
  Router = HashRouter;
} else if (typeof window === 'object') {
  appHistory = createBrowserHistory(historyOpts);
  Router = BrowserRouter;
}

export default {
  appHistory,
  Router
};
