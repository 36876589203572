import React from 'react';
import PropTypes from 'prop-types';
import {
  ITEM_TYPE_TO_COMPONENT_MAP,
  ITEM_SIZE
} from '#/elevate/components/Item/itemHelper';
import ContentGrid from '#/elevate/components/ContentGrid/ContentGrid';

const getCarouselConfig = ({ itemType, config, carouselWidth }) => {
  const width = carouselWidth || 1880; // 1880 to take padding into consideration (1920-40)
  const spacing = 15;
  const itemSize = ITEM_SIZE[itemType];
  const { width: itemWidth, height: itemHeight } = {
    ...itemSize,
    ...config.itemOptions
  };
  const height = 1.05 * itemHeight;

  return {
    itemHeight,
    itemWidth,
    spacing,
    width,
    height
  };
};

const CarouselShelf = ({
  config = {},
  carouselWidth,
  ds,
  items,
  itemType,
  nav = {},
  onItemClick,
  containerId,
  templates,
  selectedEditorEntry
}) => {
  const dir = 'ltr';

  const carouselConfig = getCarouselConfig({
    itemType,
    config,
    carouselWidth
  });
  const Item = ITEM_TYPE_TO_COMPONENT_MAP[itemType];
  const { headPadding = 0, tailPadding = 30 } = config;

  return (
    <ContentGrid
      data={items}
      ds={ds}
      nav={{
        ...nav,
        id: config.id || nav.id,
        useLastFocus: true
      }}
      keyProperty="id"
      itemWidth={carouselConfig.itemWidth}
      itemHeight={carouselConfig.itemHeight}
      horizontal
      headPadding={headPadding}
      tailPadding={tailPadding}
      spacing={carouselConfig.spacing}
      width={carouselConfig.width}
      height={carouselConfig.height}
      onClick={onItemClick}
      DisplayComponent={Item}
      displayComponentProps={{ type: itemType, containerId, templates }}
      initialState={{
        position: headPadding
      }}
      dir={dir}
      buffer={10}
      selectedEditorEntry={selectedEditorEntry}
    />
  );
};

CarouselShelf.propTypes = {
  config: PropTypes.object,
  carouselWidth: PropTypes.number,
  ds: PropTypes.object,
  itemType: PropTypes.string,
  items: PropTypes.array,
  nav: PropTypes.object,
  onItemClick: PropTypes.func,
  containerId: PropTypes.string,
  templates: PropTypes.object,
  selectedEditorEntry: PropTypes.object
};

export default CarouselShelf;
