import React from 'react';
import PropTypes from 'prop-types';
import { LoremIpsum } from '@accedo/vdkweb-ui';

import moviePlaceholder from '#/static/images/placeholder_movie.jpg';
import PageTitle from '#/components/PageTitle/PageTitle';
import Player from '#/components/Player/Player';

import * as styles from './movieDetails.scss';

const USED_STREAM =
  'http://distribution.bbb3d.renderfarming.net/video/mp4/bbb_sunflower_1080p_60fps_normal.mp4';

const MovieDetails = ({ displaytext, theme }) => (
  <div>
    {displaytext ? <PageTitle text={displaytext} /> : ''}
    <div className={theme.playerSection}>
      <div className={theme.playerOuterContainer}>
        <div className={theme.playerInnerContainer}>
          <Player
            mediaUrl={USED_STREAM}
            theme={theme}
            browserControls={false}
            idleTimeout={8000}
            // poster="https://appgrid-api-cdn-ecs.cloud.accedo.tv/content/file/590c3a8f23eec600063328c5"
          />
        </div>
      </div>
    </div>
    <div className={theme.metadataContainer}>
      <div className={theme.movieCoverContainer}>
        <img
          src={moviePlaceholder}
          alt={displaytext}
          className={theme.movieCover}
        />
      </div>
      <div className={theme.movieDescription}>
        <LoremIpsum />
      </div>
    </div>
  </div>
);

MovieDetails.propTypes = {
  displaytext: PropTypes.string,
  theme: PropTypes.object
};

MovieDetails.defaultProps = {
  theme: styles
};

export default MovieDetails;
