import qs from 'qs';

import config from '#/config';
import * as ovp from '#/services/ovp';
import getVikimapService from '#/services/vikimap';
import {
  getAccedoOneClient,
  getAccedoOneServices
} from '#/services/accedoOne/accedoOne';

const getServices = (query, accedoOneClient, onHomeRedirect) => {
  const accedoOneServices = getAccedoOneServices(accedoOneClient);

  return {
    ovp,
    vikimap: getVikimapService(query, accedoOneClient, onHomeRedirect),
    configuration: accedoOneServices.configuration,
    status: accedoOneServices.status
  };
};

/**
 * Get the services that need to be used in redux on server side
 *
 * @param  {Object} req           The HTTP request object
 * @param  {Object} res           The HTTP response object
 * @return {Objcet}               The services will be used in redux
 */
export const getServerServices = (req, res) => {
  return getServices(req.query, res.locals.accedoOneClient, () =>
    res.redirect(config.app.urlPrefix)
  );
};

/**
 * Get the services that need to be used in redux on client side
 *
 * @return {Objcet}               The services will be used in redux
 */
export const getClientServices = () => {
  const accedoOneClient = getAccedoOneClient();
  const query = qs.parse((global.location?.search || '').replace(/^\?/, ''));

  return getServices(query, accedoOneClient, () => {
    window.location.href = config.app.urlPrefix;
  });
};
