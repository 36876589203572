import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider as ThemrThemeProvider } from 'react-css-themr';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import darkThemr from '#/theme/themes/dark';
import lightThemr from '#/theme/themes/light';
import { ThemeDefaults, AccedoOneTheme } from '#/config/templates';
import { selectors as configSelectors } from '#/redux/modules/config';

const themeNameToThemr = {
  dark: darkThemr,
  light: lightThemr
};

const ThemeContext = React.createContext();

const withTheme = Comp => {
  return props => {
    return (
      <ThemeContext.Consumer>
        {themeValue => {
          return <Comp {...props} theme={themeValue} />;
        }}
      </ThemeContext.Consumer>
    );
  };
};

const UnconnectedThemeProvider = React.memo(
  ({ children, theme }) => {
    const muiTheme = theme.name === 'dark' ? darkBaseTheme : lightBaseTheme;

    return (
      <ThemeContext.Provider value={theme}>
        <ThemrThemeProvider theme={themeNameToThemr[theme.name]}>
          <MuiThemeProvider muiTheme={getMuiTheme(muiTheme)}>
            {children}
          </MuiThemeProvider>
        </ThemrThemeProvider>
      </ThemeContext.Provider>
    );
  },
  (prevProps, nextProps) => {
    const { children: prevChildren, ...prevPropsParsed } = prevProps;
    const { children: nextChildren, ...nextPropsParsed } = nextProps;
    return (
      prevChildren === nextChildren && isEqual(prevPropsParsed, nextPropsParsed)
    );
  }
);

UnconnectedThemeProvider.propTypes = {
  theme: PropTypes.shape({
    name: PropTypes.string,
    titleColor: PropTypes.string
  })
};

let hasLoggedThemeWarning = false;
const mapStateToProps = state => {
  const accedoOne = configSelectors.getAccedoOne(state);
  let theme = { ...ThemeDefaults };

  if (accedoOne && accedoOne.theme && typeof accedoOne.theme === 'object') {
    theme = { ...theme, ...accedoOne.theme };

    if (
      accedoOne.theme.name &&
      !AccedoOneTheme[accedoOne.theme.name] &&
      !hasLoggedThemeWarning
    ) {
      console.warn(
        `Unexpected value of Accedo One Theme: "${accedoOne.theme.name}"`
      );

      hasLoggedThemeWarning = true;
    }
  }

  return { theme };
};

UnconnectedThemeProvider.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object
};

const ThemeProvider = connect(mapStateToProps)(UnconnectedThemeProvider);

export { ThemeProvider, withTheme, UnconnectedThemeProvider };
