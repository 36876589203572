import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@accedo/vdkweb-carousel';

import { itemSizeMap } from '#/utils/itemHelper';
import { getItemsMap } from '#/components/utils/itemsMap';
import { withTheme } from '#/theme/Theme';

import * as styles from './shelf.scss';

const TEMPLATE_HERO = 'hero-banner';

const getOptionsForTemplate = () => {
  return {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7500,
    slidesToShow: 1,
    slidesToScroll: 1,
    itemWidth: 'auto',
    itemHeight: '36.25vw',
    arrows: false
  };
};

const HeroShelf = ({
  config,
  containerId,
  isUsingEditor,
  itemType,
  items = [],
  onItemClick,
  selectedEditorEntry,
  templates,
  theme
}) => {
  if (!items.length) {
    return <span>No items to show</span>;
  }

  const { OVPItemType } = templates;

  const Item = getItemsMap(OVPItemType)[itemType];

  const { width, height } = { ...itemSizeMap[itemType], ...config.itemOptions };
  const carouselOptions = {
    itemWidth: width,
    itemHeight: height,
    ...getOptionsForTemplate(),
    ...config.carouselOptions
  };

  return (
    <div className={styles[theme.name]}>
      <Carousel
        items={items}
        displayObject={
          <Item
            containerId={containerId}
            isUsingEditor={isUsingEditor}
            isHoverableInEditor={config.isHoverableInEditor}
            onClick={onItemClick}
            selectedEditorEntry={selectedEditorEntry}
            template={TEMPLATE_HERO}
            templates={templates}
            type={itemType}
          />
        }
        keyProperty="id"
        options={carouselOptions}
      />
    </div>
  );
};

HeroShelf.propTypes = {
  config: PropTypes.object.isRequired,
  containerId: PropTypes.string,
  isUsingEditor: PropTypes.bool,
  itemType: PropTypes.string,
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  selectedEditorEntry: PropTypes.object,
  templates: PropTypes.object.isRequired,
  theme: PropTypes.object
};

export default withTheme(HeroShelf);
