import React from 'react';
import PropTypes from 'prop-types';

import Button from '#/components/Button/Button';
import arrowDown from '#/static/images/arrow-down.png';

import * as styles from './inputBox.scss';

const InputBox = ({
  containerStyle,
  disabled,
  max,
  min,
  multiline,
  onChange,
  style,
  value,

  ...props
}) => {
  const setValue = newValue => {
    if (disabled) {
      return;
    }
    if (newValue >= min && newValue <= max) {
      onChange({ target: { value: newValue } });
    }
  };

  const increaseInput = () => {
    if (disabled) {
      return;
    }
    setValue(Number(value) + 1);
  };

  const decreaseInput = () => {
    if (disabled) {
      return;
    }
    setValue(Number(value) - 1);
  };

  return (
    <div className={styles.compContainer} style={containerStyle}>
      {multiline ? (
        <textarea
          className={styles.comp}
          disabled={disabled}
          onChange={onChange}
          value={value || ''}
          {...props}
        />
      ) : (
        <input
          className={styles.comp}
          disabled={disabled}
          max={max}
          min={min}
          onChange={onChange}
          style={style}
          value={value || ''}
          {...props}
        />
      )}
      {props.type === 'number' && (
        <span>
          <Button onClick={decreaseInput}>
            <img src={arrowDown} alt="increase input" />
          </Button>
          <Button onClick={increaseInput}>
            <img src={arrowDown} alt="decrease input" />
          </Button>
        </span>
      )}
    </div>
  );
};

InputBox.propTypes = {
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

InputBox.defaultProps = {
  containerStyle: {},
  style: {}
};

export default InputBox;
