import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveGrid } from '@accedo/vdkweb-grid';
import { itemSizeMap } from '#/utils/itemHelper';
import { getItemsMap } from '#/components/utils/itemsMap';
import { XXS, XS, SM, MD, LG, XL } from '#/theme/variables/breakpoints';

const TEMPLATE_GRID = 'grid';
const bigNumber = 1000000;
const breakpointsInPixels = [0, XXS, XS, SM, MD, LG, XL, bigNumber];

const getResponsiveSettings = (itemType, OVPItemType, shouldEnforceMobile) => {
  const columnCount = {
    0: {
      [OVPItemType.Portrait]: 3,
      Default: 2
    },
    [XXS]: {
      [OVPItemType.Portrait]: 3,
      Default: 2
    },
    [XS]: {
      [OVPItemType.Portrait]: 5,
      Default: 3
    },
    [SM]: {
      [OVPItemType.Portrait]: 5,
      Default: 3
    },
    [MD]: {
      [OVPItemType.Portrait]: 5,
      Default: 3
    },
    [LG]: {
      [OVPItemType.Portrait]: 8,
      Default: 6
    },
    [XL]: {
      [OVPItemType.Portrait]: 8,
      Default: 6
    },
    [bigNumber]: {
      [OVPItemType.Portrait]: 8,
      Default: 6
    }
  };

  if (shouldEnforceMobile) {
    columnCount[0] = itemType === 'wide' ? 2 : 3;
  }

  return breakpointsInPixels.reduce(
    (acc, breakpoint) => ({
      ...acc,
      [breakpoint]: {
        columnCount:
          typeof columnCount[breakpoint] === 'object'
            ? columnCount[breakpoint][itemType] ||
              columnCount[breakpoint].Default
            : columnCount[breakpoint]
      }
    }),
    {}
  );
};

const GridShelf = ({
  config = {},
  containerId,
  isUsingEditor,
  itemType,
  items = [],
  onItemClick,
  selectedEditorEntry,
  templates
}) => {
  const { OVPItemType } = templates;

  if (!items.length) {
    return <span>No items to show</span>;
  }

  const Item = getItemsMap(OVPItemType)[itemType];

  const { width, height } = { ...itemSizeMap[itemType], ...config.itemOptions };
  const responsiveSettings = getResponsiveSettings(
    itemType,
    OVPItemType,
    config.shouldEnforceMobile
  );

  return (
    <div>
      <ResponsiveGrid
        items={items}
        displayObject={
          <Item
            containerId={containerId}
            isUsingEditor={isUsingEditor}
            isHoverableInEditor={config.isHoverableInEditor}
            onClick={onItemClick}
            selectedEditorEntry={selectedEditorEntry}
            template={TEMPLATE_GRID}
            templates={templates}
            type={itemType}
          />
        }
        keyProperty="id"
        itemWidth={width}
        itemHeight={height}
        responsiveSettings={responsiveSettings}
        isAnimated
      />
    </div>
  );
};

GridShelf.propTypes = {
  config: PropTypes.object.isRequired,
  containerId: PropTypes.string,
  isUsingEditor: PropTypes.bool,
  itemType: PropTypes.string,
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  selectedEditorEntry: PropTypes.object,
  templates: PropTypes.object.isRequired
};

export default GridShelf;
