import React from 'react';
import PropTypes from 'prop-types';

import ShelfContainersIds from './ShelfContainersIds';
import {
  determineItemTypeByTemplate,
  ContainerIdToComponent
} from './templateHelper';

const Shelf = ({
  config,
  containerId,
  isUsingEditor,
  items,
  onItemClick,
  selectedEditorEntry,
  template,
  templates
}) => {
  const {
    AccedoOneContainerTemplateMap,
    AccedoOneContainerTemplates: { CarouselPortrait, CarouselWide },
    OVPItemType
  } = templates;

  if (!AccedoOneContainerTemplateMap[template]) {
    console.warn(`Template "${template}" is not supported`);
    return null;
  }

  const uiContainerId =
    AccedoOneContainerTemplateMap[template] || ShelfContainersIds.Default;
  const ShelfComponent =
    ContainerIdToComponent[uiContainerId] || ContainerIdToComponent.Default;
  const itemType = determineItemTypeByTemplate({ template, OVPItemType });

  return (
    <ShelfComponent
      config={{ ...config, template }}
      containerId={containerId}
      isUsingEditor={isUsingEditor}
      itemType={itemType}
      items={items}
      {...((template === CarouselPortrait || template === CarouselWide) && {
        key: template
      })}
      onItemClick={onItemClick}
      selectedEditorEntry={selectedEditorEntry}
      templates={templates}
    />
  );
};

Shelf.propTypes = {
  config: PropTypes.object,
  containerId: PropTypes.string,
  isUsingEditor: PropTypes.bool,
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  selectedEditorEntry: PropTypes.object,
  template: PropTypes.string,
  templates: PropTypes.object
};

Shelf.defaultProps = {
  items: []
};

export default Shelf;
