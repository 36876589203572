import React from 'react';
import PropTypes from 'prop-types';
import { MenuToggle } from '@accedo/vdkweb-ui';

import { withTheme } from '#/theme/Theme';

import * as styles from './headerMenu.scss';

const HeaderMenu = ({
  VikimapMenu,
  isCollapsed,
  menuId,
  onMenuCollapse,
  onMenuToggle,
  theme,
  currentPlatform
}) => {
  return (
    <span
      className={`${styles.container} ${
        isCollapsed ? styles.collapsed : styles.expanded
      } ${styles[theme.name]}`}
    >
      <span
        className={`${currentPlatform === 'mobile' ? styles.collapsible : ''} ${
          !isCollapsed ? styles.expanded : ''
        }`}
      >
        <VikimapMenu
          id={menuId}
          isCollapsible
          isCollapsed={isCollapsed}
          onMenuItemClick={onMenuCollapse}
        />
      </span>
      <span className={currentPlatform === 'mobile' ? '' : styles.toggle}>
        <MenuToggle isCollapsed={isCollapsed} onMenuToggle={onMenuToggle} />
      </span>
    </span>
  );
};

HeaderMenu.propTypes = {
  VikimapMenu: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isCollapsed: PropTypes.bool,
  menuId: PropTypes.string,
  onMenuCollapse: PropTypes.func,
  onMenuToggle: PropTypes.func,
  theme: PropTypes.object,
  currentPlatform: PropTypes.oneOf(['desktop', 'mobile'])
};

HeaderMenu.defaultProps = {
  isCollapsed: true
};

export default withTheme(HeaderMenu);
