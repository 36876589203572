import React from 'react';
import PropTypes from 'prop-types';

import QueryDataShelf from '#/elevate/containers/QueryDataShelf/QueryDataShelf';
import Shelf from '#/elevate/components/Shelf';
import ContainerComp from '#/elevate/components/Container/Container';
import * as templates from '#/config/templates';
import { ACCEDO_ENTRIES_QUERY } from '#/containers/IntuitiveEditor/components/editorConstants';

const Container = ({
  containerConfig,
  id,
  isUsingEditor,
  items,
  onItemClick,
  query,
  selectedEditorEntry,
  ...props
}) => {
  const { template } = props;
  const isAccedoEntriesContainer =
    (items && items.length > 0) || query === ACCEDO_ENTRIES_QUERY;

  if (!isAccedoEntriesContainer && !query) {
    return null;
  }

  let { id: parentContainerId } = selectedEditorEntry?.parentContainer || {};
  if (!parentContainerId && selectedEditorEntry) {
    ({ id: parentContainerId } = selectedEditorEntry);
  }
  const isHoverableInEditor = parentContainerId === id;

  const shelfConfig = {
    ...containerConfig,
    isHoverableInEditor,
    id,
    template
  };

  return (
    <ContainerComp {...props}>
      {isAccedoEntriesContainer ? (
        <Shelf
          config={shelfConfig}
          containerId={id}
          isUsingEditor={isUsingEditor}
          items={items || []}
          onItemClick={onItemClick}
          selectedEditorEntry={selectedEditorEntry}
          template={template}
          templates={templates}
        />
      ) : (
        <QueryDataShelf
          config={shelfConfig}
          containerId={id}
          isUsingEditor={isUsingEditor}
          onItemClick={onItemClick}
          query={query}
          selectedEditorEntry={selectedEditorEntry}
          template={template}
        />
      )}
    </ContainerComp>
  );
};

Container.propTypes = {
  containerConfig: PropTypes.object,
  displayText: PropTypes.string,
  id: PropTypes.string,
  isUsingEditor: PropTypes.bool,
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  query: PropTypes.string,
  selectedEditorEntry: PropTypes.object,
  style: PropTypes.object,
  template: PropTypes.string,
  theme: PropTypes.object
};

export default Container;
