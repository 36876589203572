import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { PanelTitle } from '../Panel';
import InputBox from '../InputBox';
import Select from '../Select';

import * as styles from './contextualMenu.scss';
import { DATA_SOURCE_OPTIONS, CUSTOM_QUERY_DETECTION } from './queryObject';

const SELECT_SEPARATION = 10;

const getDataSourceAndCollectionFromQuery = query => {
  let foundCollection = null;

  const dataSource = DATA_SOURCE_OPTIONS.find(o => {
    foundCollection = (o.collections || []).find(collection => {
      return (collection.queries || []).find(queryObj => {
        return queryObj.value === query;
      });
    })?.value;

    if (!foundCollection && o.queryValue === query) {
      return true;
    }

    return foundCollection;
  })?.value;

  if (!dataSource && query) {
    // TODO: Instead of using 'any', loop through the data sources
    return [
      'any',
      DATA_SOURCE_OPTIONS.find(d => d.value === 'any').collections.reduce(
        (acc, c) => {
          if (acc.isFound === CUSTOM_QUERY_DETECTION.YES || !c.detectQuery) {
            return acc;
          }

          const isFound = c.detectQuery(query);
          if (isFound === CUSTOM_QUERY_DETECTION.NO) {
            return acc;
          }

          return { isFound, collection: c };
        },
        { isFound: CUSTOM_QUERY_DETECTION.NO, collection: null }
      ).collection?.value || ''
    ];
  }

  return [dataSource, foundCollection];
};

const getCollectionsOptions = newDataSourceValue => {
  return DATA_SOURCE_OPTIONS.find(o => o.value === newDataSourceValue)
    ?.collections;
};

const QueryForm = ({
  entry,
  getUpdateObj,
  isEditionDisabled,
  onEntryUpdate,
  queryValue,
  setQuery
}) => {
  const compRef = useRef({});

  const [origDataSource, origCollection] = getDataSourceAndCollectionFromQuery(
    queryValue
  );
  const [dataSourceValue, setDataSourceValue] = useState(origDataSource);
  const [collectionValue, setCollectionValue] = useState(origCollection);

  compRef.current.runDebounced =
    compRef.current.runDebounced || _debounce(fn => fn(), 500);

  useEffect(() => {
    const [dataSource, collectionVal] = getDataSourceAndCollectionFromQuery(
      entry?.query
    );
    setDataSourceValue(dataSource);
    setCollectionValue(collectionVal);
    setQuery(entry?.query);
  }, [entry?.id]);

  if (!entry) {
    return null;
  }

  const onDataSourceChange = newDataSourceValue => {
    setDataSourceValue(newDataSourceValue);

    const newCollectionsOptions = getCollectionsOptions(newDataSourceValue);

    if (!newCollectionsOptions) {
      const newQueryValue = DATA_SOURCE_OPTIONS.find(
        o => o.value === newDataSourceValue
      )?.queryValue;

      if (!newQueryValue) {
        return;
      }

      setQuery(newQueryValue);

      onEntryUpdate(
        getUpdateObj({
          newQuery: newQueryValue
        })
      );

      return;
    }

    setCollectionValue(null);
    setQuery(null);
  };

  const collectionsOptions = getCollectionsOptions(dataSourceValue);
  const collectionsSelectValue =
    collectionsOptions?.find(c => c.value === collectionValue) || null;
  const { queryType, convertInputValueToQuery, convertQueryToInputValue } =
    collectionsSelectValue || {};
  const queriesOptions = collectionsSelectValue?.queries;

  return (
    <div>
      <PanelTitle style={{ paddingLeft: 0 }}>Data</PanelTitle>
      <div style={{ marginBottom: SELECT_SEPARATION }}>
        <div className={styles.propTitle}>Data Source: </div>
        <div className={styles.propContentRight}>
          <Select
            isDisabled={isEditionDisabled}
            options={DATA_SOURCE_OPTIONS}
            value={DATA_SOURCE_OPTIONS.find(o => o.value === dataSourceValue)}
            onChange={({ value }) => {
              onDataSourceChange(value);
            }}
          />
        </div>
      </div>
      {collectionsOptions && (
        <div style={{ marginBottom: SELECT_SEPARATION }}>
          <div className={styles.propTitle}>Collection: </div>
          <div className={styles.propContentRight}>
            <Select
              isDisabled={isEditionDisabled}
              options={collectionsOptions}
              value={collectionsSelectValue}
              onChange={({ value }) => {
                setCollectionValue(value);
                setQuery(null);
              }}
            />
          </div>
        </div>
      )}
      {(queriesOptions || queryType) && (
        <React.Fragment>
          <div className={styles.propTitle}>Query: </div>
          <div className={styles.propContentRight}>
            {queryType === 'input' ? (
              <InputBox
                containerStyle={{width: '100%'}}
                disabled={isEditionDisabled}
                type="text"
                value={convertQueryToInputValue(queryValue)}
                onChange={e => {
                  const { value } = e.target;
                  const newQuery = convertInputValueToQuery(value);
                  setQuery(newQuery);
                  compRef.current.runDebounced(() => {
                    onEntryUpdate(
                      getUpdateObj({
                        newQuery
                      })
                    );
                  });
                }}
              />
            ) : (
              <Select
                isDisabled={isEditionDisabled}
                options={queriesOptions}
                value={queriesOptions.find(o => o.value === queryValue) || null}
                onChange={({ value }) => {
                  setQuery(value);
                  onEntryUpdate(
                    getUpdateObj({
                      newQuery: value
                    })
                  );
                }}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

QueryForm.propTypes = {
  entry: PropTypes.object,
  getUpdateObj: PropTypes.func,
  isEditionDisabled: PropTypes.bool,
  onEntryUpdate: PropTypes.func,
  queryValue: PropTypes.any,
  setQuery: PropTypes.func
};

QueryForm.defaultProps = {};

export default QueryForm;
