import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import { withTheme } from '#/theme/Theme';
import logo from '#/static/images/logo-vodafone.svg';

import HeaderMenu from '../HeaderMenu/HeaderMenu';

import * as styles from './header.scss';

const toggleEditMode = () => {
  if (typeof window === 'undefined') {
    return 'home';
  }
  const { search, pathname = '/home' } = window.location;

  let query = [];
  let newSearch = '?';
  if (search?.length) {
    query = search.substr(1).split('&');
    query = query.filter((item) => item !== 'mode=edit');
    if (query.length) {
      newSearch += query.join('&');
    } else {
      newSearch = '';
    }
  } else {
    newSearch += 'mode=edit';
  }

  window.location.search = newSearch;
};

export const Header = ({
  VikimapMenu,
  config,
  isCollapsed,
  onMenuCollapse,
  onMenuToggle,
  theme,
  urlPrefix,
  currentPlatform
}) => {
  const { title = '', version = '', custom = {}, accedoOne = {} } = config;
  const titleStyle = theme.titleColor ? { color: theme.titleColor } : {};
  const usedTitle = accedoOne.appName || title;
  const usedAppLogo = accedoOne.assets?.appLogo || logo;

  console.info(usedTitle, version);

  return (
    <div
      className={`${styles.appBar} ${styles[theme.name]}`}
      style={{
        position: 'relative'
      }}
    >
      <a onClick={toggleEditMode} className={styles.logoLink} title={'Toggle edit mode'}>
        <img src={usedAppLogo} className={styles.logo} alt="app logo" />
      </a>
      {custom.showTitleInHeader &&
        <span className={styles.logoTitle} style={titleStyle}>
          {usedTitle} {version}
        </span>
      }
      {accedoOne ? (
        <HeaderMenu
          menuId={accedoOne.mainMenuEntryId}
          isCollapsed={isCollapsed}
          onMenuToggle={onMenuToggle}
          onMenuCollapse={onMenuCollapse}
          VikimapMenu={VikimapMenu}
          currentPlatform={currentPlatform}
        />
      ) : (
        ''
      )}
    </div>
  );
};

Header.propTypes = {
  VikimapMenu: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  config: PropTypes.object,
  urlPrefix: PropTypes.string,
  isCollapsed: PropTypes.bool,
  onMenuCollapse: PropTypes.func,
  onMenuToggle: PropTypes.func,
  theme: PropTypes.object,
  currentPlatform: PropTypes.oneOf(['desktop', 'mobile'])
};

Header.defaultProps = {
  config: {},
  urlPrefix: ''
};

export default withTheme(Header);
