import React from 'react';
import PropTypes from 'prop-types';
import * as maintenanceTheme from './maintenance.scss';

const defaultMaintenanceMessage =
  'This site is not available at the moment. Please try again later.';

const Maintenance = ({ message, theme }) => {
  return (
    <div>
      <h1 className={theme.message}>{message}</h1>
    </div>
  );
};

Maintenance.propTypes = {
  message: PropTypes.node,
  theme: PropTypes.object
};

Maintenance.defaultProps = {
  message: defaultMaintenanceMessage,
  theme: maintenanceTheme
};

export default Maintenance;
