import config from '#/config';

const prefix = config.app.urlPrefix;
const baseUrl = config.accedoOne.middlewareBaseUrl
  ? config.accedoOne.middlewareBaseUrl
  : '';

const NOOP = () => null;
const io = __CLIENT__
  ? require('socket.io-client')
  : () => ({
      emit: NOOP,
      on: NOOP,
      removeListener: NOOP
    });

const ioOpts = {
  path: `${
    config.accedoOne.middlewareUrlPrefix
      ? config.accedoOne.middlewareUrlPrefix
      : prefix
  }socket.io`
};

const socket = baseUrl ? io(baseUrl, ioOpts) : io(ioOpts);

export default socket;
