import qs from 'qs';

import { createRandomString } from '#/utils/general';
import { MENUITEM_STATE } from '#/containers/IntuitiveEditor/components/editorConstants';

const TMP_CONTAINER_PREFIX = 'tmp-con-';

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getHelperStructures = ({ containers }) => {
  const containerIdToEntryMap = {};
  const containersIds = [];

  const parsedContainers = containers.map((c) => {
    const container = { ...c };
    containerIdToEntryMap[container.id] = container;
    containersIds.push(container.id);

    container.items = container.items || [];

    return container;
  });

  return {
    containerIdToEntryMap,
    containersIds,
    parsedContainers
  };
};

export const getHelperMenuStructures = ({
  menuItemsObject = {},
  pagesObject = {}
}) => {
  const menuItemIdToEntryMap = {};
  const menuPagesIds = [];
  const menuItems = Object.values(menuItemsObject);

  const parsedMenuItems = menuItems.map((c) => {
    const menuItem = { ...c };
    menuItemIdToEntryMap[menuItem.id] = menuItem;
    menuItem.page = pagesObject[menuItem.page];
    menuItem.items = menuItem.items || [];
    menuPagesIds.push(menuItem.page?.id || menuItem.page);

    return menuItem;
  });

  return {
    menuItemIdToEntryMap,
    parsedMenuItems,
    menuPagesIds
  };
};

export const getMenuPages = ({ menuItemsObject = {} }) => {
  const menuPagesState = {};
  const menuItems = Object.values(menuItemsObject);

  menuItems.forEach((item) => {
    menuPagesState[item.page?.id || item.page] = MENUITEM_STATE.LOADED;
  });

  return menuPagesState;
};

export const getNewContainersWithItemReplaced = ({
  containers,
  itemId,
  replacerFn
}) => {
  return containers.map((container) => {
    return {
      ...container,
      ...(container.items
        ? {
            items: container.items.map((item) =>
              item.id === itemId ? replacerFn(item) : item
            )
          }
        : {})
    };
  });
};

export const isUnsavedContainer = (entryId = '') =>
  entryId.includes(TMP_CONTAINER_PREFIX);

export const createUnsavedEntryId = () =>
  `${TMP_CONTAINER_PREFIX}${createRandomString()}`;

export const getEditionFromLocation = (location) => {
  const { edition } = qs.parse(location.search.replace(/^\?/, ''));

  return edition;
};
