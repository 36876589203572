import React, { Component } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@accedo/vdkweb-ui';

import { redirectToUrl } from '#/utils/general';
import {
  validateAreValuesDefined,
  validateContainsAllKeys
} from '#/utils/validations';
import modules from '#/redux/modules';
import { AccedoOnePageTemplateMap } from '#/config/templates';
import IntuitiveEditor from '#/containers/IntuitiveEditor/IntuitiveEditor';
import socket from '#/services/clientSocket/clientSocket';

import FailedToLoad from '../FailedToLoad/FailedToLoad';
import ContainerPage from '../ContainerPage/ContainerPage';

import VikimapViewsIds from './VikimapViewsIds';

const ViewIdToViewComponent = {
  [VikimapViewsIds.AccedoOneContainer]: ContainerPage,
  [VikimapViewsIds.Default]: ContainerPage
};

validateAreValuesDefined(ViewIdToViewComponent);
validateContainsAllKeys(ViewIdToViewComponent, VikimapViewsIds);

export const mapStateToProps = (state, ownProps) => {
  // Expecting either an ID set on props or an ID as query string paramater
  const id =
    ownProps.match && ownProps.match.params ? ownProps.match.params.id : null;

  const entry = modules.vikimap.selectors.getPageByAlias(state, id);

  // If no entry is found, set 'loaded' to false
  // to signal that we're in loading state.
  if (!entry || !(entry.id || entry.content) || entry.__isError) {
    return {
      entry,
      entryId: id,
      errorMessage: entry && entry.content ? entry.content.message : '',
      failedToLoad: !!(entry && entry.__isError),
      loaded: false,
      location: ownProps.location,
      vikimapQueries: state.modules.vikimapQueries
    };
  }

  // If no entry information is found set 'loaded' to true
  // to signal that the entry has been loaded.
  // Also propagate the entry to the props.
  return {
    entry,
    entryId: id,
    error: entry.__isError,
    loaded: true,
    vikimapQueries: state.modules.vikimapQueries
  };
};

const getQuery = ({ location }) => {
  return qs.parse((location.search || '').replace(/^\?/, ''));
};

class VikimapPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEntryObj: null,
      socketIoEntry: null,
      query: getQuery(props)
    };
  }

  componentDidMount() {
    this.props.dispatch(
      modules.vikimap.actions.fetchPageByAlias(this.props.entryId)
    );

    if (this.state.query.mode === 'live-preview') {
      socket.on('entry-updated', this.handleEntryUpdated);
    }

    if (this.props.entry && !this.props.entry?.__isFetching) {
      this.updateCurrentEntryObj();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ query: getQuery(this.props) });
    }

    if (this.props.entryId !== prevProps.entryId) {
      this.props.dispatch(
        modules.vikimap.actions.fetchPageByAlias(this.props.entryId)
      );
    }

    if (
      !this.props.entry?.__isFetching &&
      (!prevProps.entry ||
        prevProps.entry.__isFetching ||
        prevProps.entry?.id !== this.props.entry?.id)
    ) {
      this.updateCurrentEntryObj();
    }
  }

  componentWillUnmount() {
    socket.removeListener('entry-updated', this.handleEntryUpdated);
  }

  handleEntryUpdated = dataStr => {
    const data = JSON.parse(dataStr);
    const { content: socketIoEntry, editionId: socketIoEditionId } = data;
    const currentEditionId = this.state.query?.edition;

    if (currentEditionId !== socketIoEditionId) {
      return;
    }

    if (
      socketIoEntry?.id &&
      socketIoEntry.id !== this.props.entry?.id &&
      socketIoEntry._meta.entryAlias
    ) {
      redirectToUrl(
        `/${socketIoEntry._meta.entryAlias}?mode=live-preview&edition=${currentEditionId}`
      );

      return;
    }

    this.setState({ socketIoEntry });
  };

  handlePageRefresh = () => {
    this.props.dispatch(
      modules.vikimap.actions.invalidatePage(this.props.entry._meta.id)
    );

    this.props.dispatch(
      modules.vikimap.actions.fetchPageByAlias(this.props.entryId)
    );
  };

  updateCurrentEntryObj() {
    this.setState(state => ({
      currentEntryObj: {
        entry: this.props.entry,
        key: (state.currentEntryObj?.key || 0) + 1
      }
    }));
  }

  render() {
    const { failedToLoad, errorMessage } = this.props;

    // If the Vikimap entry failed to load, failedToLoad will
    // be true. Then we'll render a page to show the error info.
    if (failedToLoad) {
      return <FailedToLoad debugInfo={errorMessage} />;
    }

    const { entry: currentEntry } = this.state.currentEntryObj || {};

    // If the page entry data hasn't been loaded yet
    // we'll simply display a spinner.
    if (!currentEntry) {
      return <Spinner />;
    }

    // If we get to this point, we know that we have
    // the Vikimap page data. I.e. we have access
    // to the 'displayText', 'template' and 'containers'.
    //
    // We'll use the 'template'value to figure out
    // which type of page to render.

    const viewId =
      AccedoOnePageTemplateMap[currentEntry.template] ||
      VikimapViewsIds.Default;
    const TemplatePage =
      this.props.templateComp || ViewIdToViewComponent[viewId];

    if (this.state.query?.mode === 'edit') {
      return (
        <IntuitiveEditor
          entry={currentEntry}
          entryUpdated={this.state.currentEntryObj.key}
          key={currentEntry.id}
          location={this.props.location}
          onPageRefresh={this.handlePageRefresh}
          queriesData={this.props.vikimapQueries}
        >
          {editorProps => (
            <TemplatePage {...this.props} {...currentEntry} {...editorProps} />
          )}
        </IntuitiveEditor>
      );
    }

    const usedEntry =
      this.state.query?.mode === 'live-preview' && this.state.socketIoEntry
        ? this.state.socketIoEntry
        : currentEntry;

    return <TemplatePage {...this.props} {...usedEntry} key={usedEntry.id} />;
  }
}

VikimapPage.propTypes = {
  containers: PropTypes.array,
  dispatch: PropTypes.func,
  displayText: PropTypes.string,
  entry: PropTypes.object,
  entryId: PropTypes.string,
  errorMessage: PropTypes.string,
  failedToLoad: PropTypes.bool,
  loaded: PropTypes.bool,
  location: PropTypes.object,
  template: PropTypes.string,
  templateComp: PropTypes.func,
  vikimapQueries: PropTypes.object
};

export default connect(mapStateToProps)(VikimapPage);
