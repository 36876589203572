import { createAction } from 'redux-actions';
import {
  getServiceData,
  serviceRetry,
  serviceInvalidate,
  serviceUpdate
} from '../../utils/reduxHelper';

import selectors from './selectors';
import queryParser from './queryParser';

export const REQUEST = 'app/REQUEST_QUERY_DATA';
export const RECEIVE = 'app/RECEIVE_QUERY_DATA';
export const INVALIDATE = 'app/INVALIDATE_QUERY_DATA';
export const UPDATE = 'app/UPDATE_QUERY_DATA';

const onReceive = (services, query) => {
  if (!queryParser.executeQuery(query).then) {
    console.warn('queryParser.executeQuery not a promise', query);
  }
  return (
    queryParser
      .executeQuery(query)
      ?.then(result => {
        return { __key: query, content: result };
      })
      .catch(error => {
        // eslint-disable-next-line no-throw-literal
        throw { __key: query, error };
      }) || Promise.resolve({ __key: query, content: [] })
  );
};

const receiveConfig = createAction(RECEIVE, onReceive);
const requestConfig = createAction(REQUEST);
const invalidateConfig = createAction(INVALIDATE);
const updateConfig = createAction(UPDATE);

const getData = getServiceData({ selectors, requestConfig, receiveConfig });

const retry = serviceRetry({ invalidateConfig, getData });

// Invalidate the query data, then try requesting it again.
const invalidate = serviceInvalidate(invalidateConfig);

const updateData = serviceUpdate(updateConfig);

export default {
  getData,
  retry,
  invalidate,
  updateData
};
