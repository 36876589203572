import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './button.scss';

const Button = ({ onClick, children }) => (
  <div className={styles.container}>
    <button onClick={onClick} className={styles.button}>
      {children}
    </button>
  </div>
);

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default Button;
