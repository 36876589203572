import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import withConfig from '#/containers/utils/withConfig';
import HeaderComponent from '#/components/Header/Header';
import { actions, selectors } from '#/redux/modules/menu';
import VikimapMenu from '#/containers/Menu/VikimapMenu';
import appConfig from '#/config/app';

const mapStateToProps = state => ({
  isCollapsed: selectors.getMenuIsCollapsed(state)
});

const mapDispatchToProps = {
  onMenuToggle: actions.toggleMenu,
  onMenuCollapse: actions.collapseMenu
};

const enhance = compose(
  withProps({ VikimapMenu, urlPrefix: appConfig.urlPrefix }),
  withConfig,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(HeaderComponent);
