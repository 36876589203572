import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import FocusDiv from '@accedo/vdkweb-tv-ui/lib/FocusDiv/FocusDiv';

import { withTheme } from '#/theme/Theme';
import { navIdMap } from '#/utils/navigationHelper';
import getResolution from '#/utils/getResolution';
import { SM_TABLET } from '#/theme/variables/breakpoints';

import Logo from '#/elevate/components//Logo/Logo';
import Menu from '#/elevate/components/Menu/Menu';

import logo from '#/static/images/logo-vodafone.svg';
import styles from './tvHeader.scss';

const NAV_IDS = navIdMap.MENU.HEADER;

const nav = {
  container: {
    id: NAV_IDS.CONTAINER,
    forwardFocus: NAV_IDS.MENU,
    useLastFocus: true
  },
  menu: {
    id: NAV_IDS.MENU,
    parent: NAV_IDS.CONTAINER,
    forwardFocus: `${NAV_IDS.MENU_ITEM}0`,
    itemId: NAV_IDS.MENU_ITEM
  }
};

export const Header = ({ items, loaded, config, theme }) => {
  const { accedoOne = {} } = config;
  const { width } = getResolution();
  const usedAppLogo = accedoOne.assets?.appLogo || logo;

  const containerClass = classNames(styles.container, {
    [styles['is-visible']]: true
  });

  const imageClass = classNames(styles.img);

  const customStyle = {
    ...(SM_TABLET >= width
      ? { paddingTop: '1rem', paddingBottom: '1rem' }
      : { padding: '1.5rem' })
  };

  return loaded && (
    <header>
      <FocusDiv
        style={customStyle}
        className={containerClass}
        nav={{
          ...nav.container,
        }}
      >
        <Logo src={usedAppLogo} className={imageClass} />
        <Menu
          menuId={accedoOne.mainMenuEntryId}
          items={items}
          theme={theme}
          nav={nav.menu}
        />
      </FocusDiv>
    </header>
  );
};

Header.propTypes = {
  items: PropTypes.array,
  loaded: PropTypes.bool,
  config: PropTypes.object,
  theme: PropTypes.object
};

Header.defaultProps = {
  config: {}
};

export default withTheme(Header);
