import { environment, device } from '@accedo/xdk-core';

import config from '#/config';

const getResolution = () => {
  const { system } = device;

  if (system) {
    const { width, height } = system.getAppResolution();

    if (width && height) {
      return { width, height };
    }
  }

  return config.app.defaultScreenDimension;
};

environment.addEventListener(environment.DEVICE.ONLOAD, () => getResolution);

export default getResolution;
