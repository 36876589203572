import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import settingsIcon from '#/static/images/settings-gear.png';
import trashIcon from '#/static/images/remove-thrash-bin-black.png';
import reorderIcon from '#/static/images/reorder-rearrange.png';
import infoIcon from '#/static/images/info.png';

import appSwitcher from '#/config/appSwitcher';
import { PanelTitle } from '../Panel';
import { templatesPropType } from '../commonPropTypes';
import {
  ACCEDO_ENTRIES_QUERY,
  BRIGHTCOVE_ENTRIES_QUERY,
  ENTRY_TAGS_GROUPS
} from '../editorConstants';
import InputBox from '../InputBox';
import Button, { BUTTON_TYPE } from '../Button';
import Select from '../Select';
import Divider from '../Divider';

import * as styles from './contextualMenu.scss';
import QueryForm from './QueryForm';

const showTags = !appSwitcher.hideTags;

const getItemsOptions = ({
  availableItems,
  getContainerItems,
  isAccedoEntriesContainer,
  isBrightcoveEntriesContainer
}) => {
  // This function is needed for TMDB as well as Accedo and Brightcove OVP.
  // ToDo: investigate in which cases we need to return here.
  // Replace multiple boolean variables with one ovpPlatform variable.
  //
  // if (!isAccedoEntriesContainer && !isBrightcoveEntriesContainer) {
  //   return [];
  // }

  const containerItemsObj = getContainerItems().reduce((acc, item) => {
    acc[item.id] = true;
    return acc;
  }, {});

  return availableItems
    .filter(i => !containerItemsObj[i.id])
    .map(i => ({
      value: i.id,
      label: i.displayText
    }));
};

const getInnerClickHandler = fn => e => {
  e.stopPropagation();

  fn();
};

const ItemRow = ({
  containerItems,
  dragHandleProps,
  isAccedoEntriesContainer,
  isBrightcoveEntriesContainer,
  isEditionDisabled,
  item,
  onChildEntryEditClick,
  onChildEntryRemoveClick,
  onItemClick,
  position,
  query,
  updateQueriesData,
  wrapperProps
}) => {
  return (
    <div
      {...wrapperProps.top}
      {...dragHandleProps}
      className={styles.itemContainer}
      onClick={() => onItemClick(item)}
      style={{
        cursor: 'pointer',
        ...wrapperProps.style
      }}
    >
      <span className={styles.item} style={{ gridColumn: 2 }}>
        {position}
      </span>{' '}
      <img
        className={styles.item}
        src={item.images?.[0]?.url || item.image?.[0]?.fileUrl}
        alt={item.description}
        style={{
          maxWidth: 48,
          height: 28,
          borderRadius: 1,
          border: 'solid 1px rgba(255, 255, 255, 0.2)',
          gridColumn: 4
        }}
      />
      <span
        className={styles.item}
        style={{
          gridColumn: '6 / 8',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '85%'
        }}
      >
        {item.displayText || item.title}
      </span>{' '}
      {isAccedoEntriesContainer || isBrightcoveEntriesContainer ? (
        <div className={styles.itemActions}>
          <span
            onClick={getInnerClickHandler(() => onChildEntryEditClick(item))}
            style={{
              cursor: 'pointer',
              color: 'darkblue',
              backgroundImage: `url('${settingsIcon}')`,
              backgroundSize: '16px 16px',
              backgroundRepeat: 'no-repeat',
              width: 16,
              height: 16
            }}
          />
          <span
            onClick={getInnerClickHandler(() => {
              if (!isEditionDisabled) {
                if (query && query !== ACCEDO_ENTRIES_QUERY) {
                  updateQueriesData(
                    query,
                    containerItems.filter(i => i.id !== item.id)
                  );
                }

                onChildEntryRemoveClick(item);
              }
            })}
            style={{
              cursor: 'pointer',
              backgroundImage: `url('${trashIcon}')`,
              backgroundSize: '16px 16px',
              backgroundRepeat: 'no-repeat',
              width: 16,
              height: 16
            }}
          />
          <span
            style={{
              backgroundImage: `url('${reorderIcon}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '24px 24px',
              cursor: 'pointer',
              height: 24,
              marginTop: 3,
              width: 24
            }}
          />
        </div>
      ) : (
        <div className={styles.itemInfo}>
          <span
            style={{
              backgroundImage: `url('${infoIcon}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '16px 16px',
              cursor: 'pointer',
              height: 16,
              width: 16
            }}
          />
        </div>
      )}
    </div>
  );
};

ItemRow.propTypes = {
  containerItems: PropTypes.array,
  dragHandleProps: PropTypes.object,
  isAccedoEntriesContainer: PropTypes.bool,
  isBrightcoveEntriesContainer: PropTypes.bool,
  isEditionDisabled: PropTypes.bool,
  item: PropTypes.object,
  onChildEntryEditClick: PropTypes.func,
  onChildEntryRemoveClick: PropTypes.func,
  onItemClick: PropTypes.func,
  position: PropTypes.number,
  query: PropTypes.string,
  updateQueriesData: PropTypes.func,
  wrapperProps: PropTypes.object
};

ItemRow.defaultProps = {
  wrapperProps: {
    top: {},
    style: {}
  }
};

const parseMetadata = metadataStr => {
  try {
    const metadata = JSON.parse(metadataStr);

    return metadata;
  } catch (_) {
    return {};
  }
};

const TagsRow = ({ entryMetadata, onTagUpdate, entryTagGroup }) => {
  const currentEntryTags = entryMetadata?.tags;

  return (
    <div key={entryTagGroup.id} style={{ fontSize: 13 }}>
      <div style={{ marginBottom: 2, marginTop: 10 }}>
        {entryTagGroup.name}:{' '}
      </div>
      <div>
        {entryTagGroup.items.map(tagItem => {
          const checked = currentEntryTags?.includes(tagItem.id) || false;

          return (
            <div key={tagItem.id}>
              <label>
                <input
                  style={{ position: 'relative', top: -1, left: -2 }}
                  checked={checked}
                  onChange={() =>
                    onTagUpdate({
                      tagGroupId: entryTagGroup.id,
                      tagId: tagItem.id,
                      value: !checked
                    })
                  }
                  type="checkbox"
                />
                {tagItem.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TagsRow.propTypes = {
  entryMetadata: PropTypes.object,
  entryTagGroup: PropTypes.object,
  onTagUpdate: PropTypes.func
};

const TagsForm = ({ entryMetadata, onTagUpdate }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div style={{ marginTop: 29, marginBottom: 25 }}>
      <PanelTitle
        style={{ paddingRight: 8, paddingLeft: 0, display: 'inline' }}
      >
        Tags
      </PanelTitle>

      <div style={{ marginBottom: 0, display: 'inline' }}>
        <span
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            border: '1px solid rgba(61,255,216,.7)',
            borderRadius: 5,
            cursor: 'pointer',
            fontSize: 10,
            padding: '2px 8px',
            position: 'relative',
            top: -2
          }}
        >
          {isExpanded ? 'CLOSE' : 'OPEN'}
        </span>
      </div>

      <div style={{ display: isExpanded ? 'block' : 'none' }}>
        {ENTRY_TAGS_GROUPS.map(entryTagGroup => {
          return (
            <TagsRow
              entryMetadata={entryMetadata}
              entryTagGroup={entryTagGroup}
              key={entryTagGroup.id}
              onTagUpdate={onTagUpdate}
            />
          );
        })}
      </div>
    </div>
  );
};

TagsForm.propTypes = {
  entryMetadata: PropTypes.object,
  onTagUpdate: PropTypes.func
};

const ContextualMenu = ({
  availableItems,
  availableTemplates,
  entry,
  entryPosition,
  entryType,
  isEditionDisabled,
  newVersionDisplayed,
  onAddItemClick,
  onChildEntryEditClick,
  onChildEntryRemoveClick,
  onEntryRemove,
  onEntryUpdate,
  onItemClick,
  parentEntryField,
  queryData,
  updateQueriesData
}) => {
  const isAccedoEntriesContainer =
    entryType === 'container' &&
    (!entry.query || entry.query === ACCEDO_ENTRIES_QUERY);

  const isBrightcoveEntriesContainer =
    entryType === 'container' && entry.query?.includes(BRIGHTCOVE_ENTRIES_QUERY);

  const isAccedoEntriesItem =
    entryType === 'item' &&
    parentEntryField?.container &&
    (!parentEntryField.container.query ||
      parentEntryField.container.query === ACCEDO_ENTRIES_QUERY);

  const getEntryCategory = () => {
    if (isAccedoEntriesItem) {
      return entry?.subtitle;
    }

    return entry?.categories?.map(category => category.title).join(', ') || '';
  };

  const compRef = useRef({});
  const [titleValue, setTitle] = useState(entry?.title || '');
  const [queryValue, setQuery] = useState(entry?.query || '');
  const [displayTextValue, setDisplayText] = useState(entry?.displayText || '');
  const [categoryValue, setCategory] = useState(getEntryCategory());
  const [descriptionValue, setDescription] = useState(entry?.description || '');
  const [templateValue, setTemplate] = useState(entry?.template);
  const [posValue, setEntryPosition] = useState(entryPosition?.value);

  compRef.current.runDebounced =
    compRef.current.runDebounced || _debounce(fn => fn(), 500);

  const templateOptions = availableTemplates
    ? availableTemplates.map(t => ({ value: t.id, label: t.text }))
    : null;

  useEffect(() => {
    setTitle(entry?.title);
    setDisplayText(entry?.displayText);
    setCategory(getEntryCategory());
    setDescription(entry?.description);
    setTemplate(entry?.template);
    setEntryPosition(entryPosition?.value);
  }, [
    entry?.id,
    entryPosition?.value,
    newVersionDisplayed,
    parentEntryField?.container?.id
  ]);

  if (!entry) {
    return null;
  }

  const getUpdateObj = ({
    newDescription = descriptionValue,
    newDisplayText = displayTextValue,
    newMetadata = entry.metadata,
    newQuery = queryValue,
    newSubtitle = categoryValue,
    newTemplate = templateValue,
    newTitle = titleValue
  } = {}) => ({
    newDescription,
    newDisplayText,
    newMetadata,
    newQuery,
    newSubtitle,
    newTemplate,
    newTitle
  });

  const getContainerItems = () => {
    return queryData || entry.items || [];
  };

  const availableItemsOptions = getItemsOptions({
    availableItems,
    entry,
    getContainerItems,
    isAccedoEntriesContainer,
    isBrightcoveEntriesContainer
  });

  const entryMetadata = parseMetadata(entry.metadata);

  const onTagUpdate = ({ tagId, value }) => {
    const tags = entryMetadata?.tags || [];
    const newTags = value
      ? tags.concat([tagId])
      : tags.filter(t => t !== tagId);
    const newMetadata = {
      ...(entryMetadata || {}),
      tags: newTags
    };

    onEntryUpdate(
      getUpdateObj({
        newMetadata: JSON.stringify(newMetadata)
      })
    );
  };

  const submitText = (evt) => {
    evt.target.style.opacity = 0.5;

    if (displayTextValue) {
      compRef.current.runDebounced(() => {
        onEntryUpdate(
          getUpdateObj({
            newDisplayText: displayTextValue
          })
        ).then(() => {
          evt.target.style.opacity = 1;
        });
      });
    }
  };

  const readOnly = !isAccedoEntriesItem && entryType === 'item';

  return (
    <div style={{ width: '100%', padding: '10px 15px 0' }}>
      {parentEntryField && (
        <div onClick={parentEntryField.onClick} className={styles.cross}>
          &#x2717;
        </div>
      )}
      {parentEntryField && parentEntryField.link && (
        <div style={{ marginBottom: 24 }}>
          <div className={styles.propTitle}>{parentEntryField.label}: </div>
          <div
            onClick={parentEntryField.onClick}
            className={[
              styles.propContentRight,
              styles.parentEntryFieldLink
            ].join(' ')}
          >
            {parentEntryField.link}
          </div>
          <div style={{ clear: 'left' }} />
        </div>
      )}
      {!!availableTemplates && (
        <div style={{ marginBottom: 24 }}>
          <div className={styles.propTitle}>Template: </div>
          <div className={styles.propContentRight}>
            <Select
              options={templateOptions}
              isDisabled={isEditionDisabled}
              value={templateOptions.find(t => t.value === templateValue)}
              onChange={({ value }) => {
                setTemplate(value);
                onEntryUpdate(
                  getUpdateObj({
                    newTemplate: value
                  })
                );
              }}
            />
          </div>
        </div>
      )}
      {entryPosition && (
        <div style={{ marginBottom: 24 }}>
          <div className={styles.propTitle}>Position: </div>
          <div className={styles.propContentRight}>
            <InputBox
              type="number"
              value={posValue || ''}
              disabled={isEditionDisabled}
              min={1}
              max={entryPosition.total}
              onChange={e => {
                const { value } = e.target;
                setEntryPosition(value);
                compRef.current.runDebounced(() => {
                  entryPosition.onChange(value);
                });
              }}
            />
          </div>
        </div>
      )}
      <div style={{ marginBottom: 24 }}>
        <div className={styles.propTitle}>Title: </div>
        <div className={styles.propContentRight}>
          <InputBox
            containerStyle={{ width: readOnly ? '100%' : '80%' }}
            disabled={isEditionDisabled}
            type="text"
            value={displayTextValue || titleValue || ''}
            readOnly={readOnly}
            onChange={e => {
              const { value } = e.target;
              setDisplayText(value);
            }}
            // onBlur={submitText}
          />
          {!readOnly && (
            <Button onClick={submitText} style={{ margin: '10px 0 10px 5px' }}>OK</Button>
          )}
        </div>
      </div>
      {entryType === 'item' && (
        <div style={{ marginBottom: 24 }}>
          <div className={styles.propTitle}>Category: </div>
          <div className={styles.propContentRight}>
            <InputBox
              containerStyle={{ width: '100%' }}
              disabled={isEditionDisabled}
              type="text"
              value={categoryValue || ''}
              readOnly={readOnly}
              onChange={e => {
                const { value } = e.target;
                setCategory(value);
                compRef.current.runDebounced(() => {
                  onEntryUpdate(
                    getUpdateObj({
                      newSubtitle: value
                    })
                  );
                });
              }}
            />
          </div>
        </div>
      )}
      {entryType === 'item' && (
        <div style={{ marginBottom: 24 }}>
          <div className={styles.propTitle}>Description: </div>
          <div style={{ clear: 'both' }} />
          <div className={styles.propContent}>
            <InputBox
              disabled={isEditionDisabled}
              type="text"
              value={descriptionValue || ''}
              readOnly={readOnly}
              containerStyle={{ width: '100%' }}
              style={{ height: '100%' }}
              multiline
              rows={8}
              className={styles.description}
              onChange={e => {
                const { value } = e.target;
                setDescription(value);
                compRef.current.runDebounced(() => {
                  onEntryUpdate(
                    getUpdateObj({
                      newDescription: value
                    })
                  );
                });
              }}
            />
          </div>
        </div>
      )}
      {entryType === 'container' && showTags && (
        <>
          <Divider />
          <TagsForm entryMetadata={entryMetadata} onTagUpdate={onTagUpdate} />
        </>
      )}
      <Divider />
      {entryType === 'container' && (
        <QueryForm
          isEditionDisabled={isEditionDisabled}
          entry={entry}
          getUpdateObj={getUpdateObj}
          onEntryUpdate={onEntryUpdate}
          queryValue={queryValue}
          setQuery={setQuery}
        />
      )}
      {isAccedoEntriesItem && entryType === 'item' ? (
        <div>
          <Button
            disabled={isEditionDisabled}
            onClick={() => onEntryRemove(entry)}
            type={BUTTON_TYPE.EMPTY_THIN}
          >
            Remove from Container
          </Button>
        </div>
      ) : (
        <div style={{ height: 20 }} />
      )}
      {entryType === 'container' && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: 'solid 1px rgba(255, 255, 255, 0.2)',
            borderRadius: 4,
            padding: '16px 0 0'
          }}
        >
          {(() => {
            const items = getContainerItems();

            return (
              <div className={styles.itemsTitle}>
                {items.length} asset{items.length === 1 ? '' : 's'}
              </div>
            );
          })()}
          <div className={styles.itemsList}>
            {(isAccedoEntriesContainer || isBrightcoveEntriesContainer) && (
              <React.Fragment>
                <Select
                  disabled={isEditionDisabled}
                  key={availableItemsOptions.length}
                  options={availableItemsOptions}
                  isSearchable
                  value={null}
                  onChange={({ value }) => {
                    const item = availableItems.filter(i => i.id === value);
                    const { query } = entry || {};

                    if (query && query !== ACCEDO_ENTRIES_QUERY) {
                      updateQueriesData(query, queryData.concat(item));
                    }

                    onAddItemClick(value);
                  }}
                />
              </React.Fragment>
            )}
            {isAccedoEntriesContainer || isBrightcoveEntriesContainer ? (
              <Droppable
                droppableId="contextualMenuItems"
                type="contextualMenuItems"
              >
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {getContainerItems().map(
                      (containerItem, containerItemIdx, containerItems) => {
                        return (
                          <Draggable
                            key={containerItem.id}
                            draggableId={containerItem.id}
                            isDragDisabled={isEditionDisabled}
                            index={containerItemIdx}
                          >
                            {_provided => (
                              <ItemRow
                                isEditionDisabled={isEditionDisabled}
                                isAccedoEntriesContainer={
                                  isAccedoEntriesContainer
                                }
                                isBrightcoveEntriesContainer={
                                  isBrightcoveEntriesContainer
                                }
                                onChildEntryEditClick={onChildEntryEditClick}
                                onChildEntryRemoveClick={
                                  onChildEntryRemoveClick
                                }
                                className={styles.item}
                                containerItems={containerItems}
                                dragHandleProps={_provided.dragHandleProps}
                                wrapperProps={{
                                  top: {
                                    ref: _provided.innerRef,
                                    ..._provided.draggableProps
                                  },
                                  style: _provided.draggableProps.style
                                }}
                                item={containerItem}
                                position={containerItemIdx + 1}
                                updateQueriesData={updateQueriesData}
                                query={entry.query}
                              />
                            )}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ) : (
              getContainerItems().map((containerItem, idx, containerItems) => {
                return (
                  <ItemRow
                    containerItems={containerItems}
                    isAccedoEntriesContainer={isAccedoEntriesContainer}
                    isBrightcoveEntriesContainer={isBrightcoveEntriesContainer}
                    isEditionDisabled={isEditionDisabled}
                    item={containerItem}
                    key={containerItem.id}
                    onChildEntryEditClick={onChildEntryEditClick}
                    onItemClick={onItemClick}
                    position={idx + 1}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ContextualMenu.propTypes = {
  availableItems: PropTypes.array,
  availableTemplates: templatesPropType,
  entry: PropTypes.object,
  entryPosition: PropTypes.object,
  entryType: PropTypes.oneOf(['container', 'item']),
  isEditionDisabled: PropTypes.bool,
  newVersionDisplayed: PropTypes.number,
  onAddItemClick: PropTypes.func,
  onChildEntryEditClick: PropTypes.func,
  onChildEntryRemoveClick: PropTypes.func,
  onEntryRemove: PropTypes.func,
  onEntrySave: PropTypes.func,
  onEntryUpdate: PropTypes.func,
  onItemClick: PropTypes.func,
  parentEntryField: PropTypes.object,
  queryData: PropTypes.array,
  updateQueriesData: PropTypes.func
};

ContextualMenu.defaultProps = {
  availableItems: [],
  onAddItemClick: () => null,
  onChildEntryEditClick: () => null,
  onChildEntryRemoveClick: () => null
};

export default ContextualMenu;
