import React from 'react';
import PropTypes from 'prop-types';

import useHover from '#/components/hooks/useHover';

import * as styles from './button.scss';

const BUTTON_TYPE = {
  EMPTY: 'empty',
  EMPTY_THIN: 'empty-thin',
  FILL: 'fill'
};

const typeToClassNameMap = {
  [BUTTON_TYPE.EMPTY]: styles.typeEmpty,
  [BUTTON_TYPE.EMPTY_THIN]: styles.typeEmptyThin,
  [BUTTON_TYPE.FILL]: styles.typeFill
};

const getEmptyBackground = ({ isHovered, disabled }) => {
  const defaultVal = { backgroundColor: 'rgba(0, 0, 0, 0.5)' };
  if (disabled) {
    return defaultVal;
  }

  if (isHovered) {
    return { backgroundColor: 'rgba(15, 66, 56, 0.58)' };
  }

  return defaultVal;
};

const typeToStyleMap = {
  [BUTTON_TYPE.EMPTY]: ({ disabled, isHovered }) => ({
    ...getEmptyBackground({ disabled, isHovered }),
    ...(disabled ? { color: '#333' } : {}),
    ...(disabled ? { border: 'solid 1px #333' } : {})
  }),
  [BUTTON_TYPE.EMPTY_THIN]: ({ disabled, isHovered }) => ({
    ...getEmptyBackground({ disabled, isHovered })
  }),
  [BUTTON_TYPE.FILL]: ({ disabled }) => ({
    color: disabled ? '#777' : '#333'
  })
};

const Button = ({ style, disabled, className, type, ...props }) => {
  const [hoverRef, isHovered] = useHover();
  const finalClassName = [typeToClassNameMap[type], styles.common, className]
    .filter(c => !!c)
    .join(' ');

  const finalStyle = {
    cursor: disabled ? 'default' : 'pointer',
    ...typeToStyleMap[type]({ disabled, isHovered }),
    ...style
  };

  return (
    <button
      {...props}
      ref={hoverRef}
      style={finalStyle}
      className={finalClassName}
      disabled={disabled}
    />
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.oneOf(Object.keys(BUTTON_TYPE).map(k => BUTTON_TYPE[k]))
};

Button.defaultProps = {
  className: '',
  disabled: false,
  style: {},
  type: BUTTON_TYPE.FILL
};

export default Button;

export { BUTTON_TYPE };
