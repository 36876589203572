import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import removeBinImage from '#/static/images/remove-thrash-bin.svg';
import renameImage from '#/static/images/rename.svg';
import navigationImage from '#/static/images/navigation.svg';
import useHover from '#/components/hooks/useHover';
import appSwitcher from '#/config/appSwitcher';
import { MENUITEM_STATE } from '#/containers/IntuitiveEditor/components/editorConstants';

import * as styles from './pageModal.scss';

import Button, { BUTTON_TYPE } from './Button';
import InputBox from './InputBox';
import Modal from './Modal';
import Select from './Select';

const CREATED_BORDER = 'solid 1px #414141';

const NOOP = () => {
  return null;
};

const ICON_SIZE = 25;

const IconButton = ({
  disabled,
  disabledIcon,
  icon,
  onClick,
  style,
  title,
  className
}) => {
  const [ref, isHovered] = useHover();

  return (
    <span
      ref={ref}
      onClick={disabled ? NOOP : onClick}
      style={{
        WebkitMask: `url(${disabled && !!disabledIcon ? disabledIcon : icon})`,
        background: isHovered && !disabled ? '#3dffd8' : '#99ffe2',
        cursor: disabled ? 'default' : 'pointer',
        WebkitMaskSize: 'cover',
        display: 'inline-block',
        height: ICON_SIZE,
        maskSize: 'cover',
        width: ICON_SIZE,
        ...style
      }}
      title={title}
      className={className}
    />
  );
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  disabledIcon: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string
};

IconButton.defaultProps = {
  style: {}
};

const getMenusHelperStructures = ({ pages, menus }) => {
  const pageIdToEntryMap = pages.reduce((acc, page) => {
    acc[page.id] = page;
    return acc;
  }, {});
  const assignedPagesObj = menus.reduce((acc, menuItem) => {
    if (menuItem.page?.id) {
      acc[menuItem.page.id] = menuItem.id;
    }

    return acc;
  }, {});

  return {
    assignedPagesObj,
    pageIdToEntryMap
  };
};

const commonIconButtonStyle = {
  position: 'absolute',
  top: 6
};

const PageModal = ({
  currentPageEntry,
  getTargetForMenuItem,
  isCreatePageDisabled,
  menus,
  onCreatePageClick,
  onDeletePageClick,
  onMenuChange,
  onPageTitleClick,
  onCreateMenuItemClick,
  onRemoveMenuItemClick,
  pageTitleValue,
  pages,
  setPageTitle,
  urlValue,
  menuPagesState,

  ...props
}) => {
  const { pageIdToEntryMap, assignedPagesObj } = getMenusHelperStructures({
    getTargetForMenuItem,
    menus,
    pages
  });

  const history = useHistory();

  let editStyle;
  let menuStyle;
  let pageState;
  let menuItemFunc;
  let toolTip;
  let rotate;
  let message;

  return (
    <Modal
      {...props}
      style={{
        content: {
          backgroundColor: '#000',
          border: 'solid 1px #414141',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
          color: '#eee',
          left: '20%',
          padding: 0,
          right: '20%'
        }
      }}
    >
      <div className={styles.wrapper}>
        <div onClick={props.onRequestClose} className={styles.cross}>
          &#x2717;
        </div>
        <h1>PAGES</h1>
        <h2 className={styles.modalSectionHeader}>ADD NEW PAGE</h2>
        <div className={styles.divisor} style={{ borderTop: CREATED_BORDER }} />
        <div className={styles.newPageForm}>
          <div className={styles.newPageItem}>
            <div className={styles.newPageLabel}>PAGE TITLE</div>
            <InputBox
              value={pageTitleValue}
              placeholder="Enter Page Title"
              onChange={(e) => {
                const { value } = e.target;
                setPageTitle(value);
              }}
            />
          </div>
          <div className={styles.newPageItem}>
            {/*
                ToDo: Here we could add a page template selector.
                Available templates should first be listed in
                src/config/appSwitcher.js, and be fetched from there.
             */}
          </div>
          <div
            className={styles.newPageItem}
            style={{ paddingRight: 0, flexGrow: 1 }}
          >
            <Button
              onClick={() =>
                onCreatePageClick(appSwitcher.pageTemplates.default)
              }
              disabled={!pageTitleValue || !urlValue || isCreatePageDisabled}
              className={styles.newPageButton}
              type={BUTTON_TYPE.EMPTY}
            >
              ADD PAGE
            </Button>
          </div>
        </div>
        <h2 className={styles.modalSectionHeader}>
          CREATED PAGES ({pages.length})
        </h2>
        <div>
          {pages.map((page, pageIdx) => {
            pageState = menuPagesState[page.id];
            rotate = '';
            message = '';

            editStyle = {
              ...commonIconButtonStyle,
              right: ICON_SIZE + 10
            };
            menuStyle = {
              ...commonIconButtonStyle,
              right: 2 * (ICON_SIZE + 10)
            };
            if (pageState === MENUITEM_STATE.LOADED) {
              menuStyle.background = '#f33';
              toolTip = 'Remove from menu';
              menuItemFunc = () => onRemoveMenuItemClick({ page });
            } else if (
              pageState === MENUITEM_STATE.PENDING ||
              pageState === MENUITEM_STATE.DELETED
            ) {
              toolTip = 'Waiting for server...';
              menuStyle.background = '#777';
              menuStyle.cursor = 'not-allowed';
              menuItemFunc = () => console.info(toolTip);
              rotate = styles.rotate;
            } else {
              toolTip = 'Add to menu';
              menuItemFunc = () => onCreateMenuItemClick({ page });
            }
            if (!page.alias) {
              editStyle.display = 'none';
              menuStyle.display = 'none';
              message = 'Missing page alias';
            }
            if (appSwitcher.pageTemplates.editable.indexOf(page.template) < 0) {
              editStyle.display = 'none';
            }

            return (
              <div
                className={styles.createdPageRow}
                key={page.id}
                style={{
                  borderTop: CREATED_BORDER,
                  ...(pageIdx === pages.length - 1
                    ? { borderBottom: CREATED_BORDER }
                    : {})
                }}
              >
                <div
                  onClick={() => onPageTitleClick(page)}
                  className={[
                    styles.createdPageRowItem,
                    styles.goToPageLink
                  ].join(' ')}
                >
                  {page.title}
                </div>
                <div className={styles.createdPageRowItem}>{message}</div>
                <div
                  className={styles.createdPageRowItem}
                  style={{ textAlign: 'right' }}
                >
                  <IconButton
                    style={menuStyle}
                    className={rotate}
                    icon={navigationImage}
                    onClick={menuItemFunc}
                    title={toolTip}
                  />
                  <IconButton
                    style={editStyle}
                    icon={renameImage}
                    onClick={() =>
                      history.push(`${page.alias}${history.location.search}`)
                    }
                    title="Edit page"
                  />
                  {pages.length >= 2 && page?.alias !== 'home' && (
                    <IconButton
                      style={{ ...commonIconButtonStyle, right: 0 }}
                      icon={removeBinImage}
                      onClick={() => onDeletePageClick(page)}
                      title="Delete page"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <h2 className={styles.modalSectionHeader}>
          MENU ITEMS ({menus.length})
        </h2>
        <div>
          {menus.map((parsedMenu) => {
            const pagesOptions = [{ label: 'NONE', value: null }]
              .concat(
                pages.map((p) =>
                  assignedPagesObj[p.id] &&
                  assignedPagesObj[p.id] !== parsedMenu.id
                    ? null
                    : {
                        label: p.title,
                        value: p.id
                      }
                )
              )
              .filter((p) => !!p);
            const target = getTargetForMenuItem(parsedMenu);
            const pageValue = pagesOptions.find((option) => {
              const page = pageIdToEntryMap[option.value];
              return page?.alias && `/${page.alias}` === target;
            });

            return (
              <div key={parsedMenu.id} className={styles.assignDestinationRow}>
                <div className={styles.assignDestingationRowItem}>
                  {parsedMenu.displayText}
                </div>
                <div className={styles.assignDestingationRowItem}>{target}</div>
                <div
                  className={styles.assignDestingationRowItem}
                  style={{ maxWidth: 200 }}
                >
                  <Select
                    isSearchable
                    options={pagesOptions}
                    value={
                      pageValue || pagesOptions.find((o) => o.value === null)
                    }
                    onChange={(o) =>
                      onMenuChange({
                        menu: parsedMenu,
                        value: o.value,
                        currentPageId: pageValue?.value
                      })
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

PageModal.propTypes = {
  currentPageEntry: PropTypes.object,
  getTargetForMenuItem: PropTypes.func,
  isCreatePageDisabled: PropTypes.bool,
  menus: PropTypes.array,
  onCreatePageClick: PropTypes.func,
  onDeletePageClick: PropTypes.func,
  onMenuChange: PropTypes.func,
  onPageTitleClick: PropTypes.func,
  onCreateMenuItemClick: PropTypes.func,
  onRemoveMenuItemClick: PropTypes.func,
  onRequestClose: PropTypes.func,
  pageTitleValue: PropTypes.string,
  pages: PropTypes.array,
  setPageTitle: PropTypes.func,
  setUrl: PropTypes.func,
  urlValue: PropTypes.string,
  menuPagesState: PropTypes.object
};

PageModal.defaultProps = {
  currentPageEntry: null,
  getTargetForMenuItem: () => '/',
  isCreatePageDisabled: false,
  menus: [],
  onCreatePageClick: () => null,
  onDeletePageClick: () => null,
  onMenuChange: () => null,
  onPageTitleClick: () => null,
  onCreateMenuItemClick: () => null,
  onRemoveMenuItemClick: () => null,
  pages: [],
  setPageTitle: () => null,
  setUrl: () => null,
  urlValue: '',
  menuPagesState: {}
};

export default PageModal;
