import React from 'react';
import ReactSelect, { components } from 'react-select';
import arrowDown from '#/static/images/arrow-down.png';

const darkBackgroundColor = 'rgba(255, 255, 255, 0.15)';
const darkBackgroundColorHover = 'rgba(255, 255, 255, 0.3)';

const customStyles = {
  control: base => ({
    ...base,
    border: '0 !important',
    minHeight: 25,
    boxShadow: '0 !important',
    ':hover': {
      backgroundColor: darkBackgroundColorHover
    },
    height: 26,
    backgroundColor: darkBackgroundColor,
    fontSize: 12
  }),
  valueContainer: base => ({
    ...base,
    height: 26,
    padding: 0
  }),
  indicatorsContainer: base => ({
    ...base,
    height: 26
  }),
  input: base => ({
    ...base,
    color: '#eee',
    paddingLeft: 6
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  menu: base => ({
    ...base,
    backgroundColor: 'rgba(100, 100, 100, 1)',
    borderColor: 'red',
    top: 18,
    fontSize: 12
  }),
  menuList: base => ({
    ...base,
    minHeight: 'fit-content'
  }),
  option: base => ({
    ...base,
    color: '#eee',
    backgroundColor: darkBackgroundColor,
    ':hover': {
      backgroundColor: darkBackgroundColorHover
    }
  }),
  singleValue: base => ({
    ...base,
    color: '#eee',
    fontSize: 12,
    marginLeft: 8
  })
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        style={{
          width: 24,
          height: 24,
          backgroundImage: `url('${arrowDown}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '24px 24px',
          backgroundPosition: 'center center'
        }}
      />
    </components.DropdownIndicator>
  );
};

const Select = props => {
  return (
    <ReactSelect
      {...props}
      styles={customStyles}
      components={{ DropdownIndicator }}
    />
  );
};

Select.defaultProps = {
  isSearchable: false
};

export default Select;
