import React from 'react';
import PropTypes from 'prop-types';

import { FooterBar } from '#/views/EditionsDashboard/components/HorizontalBar/HorizontalBar';
import {
  EDITION_STATUS_ORDER,
  EDITION_STATUS_NAME
} from '#/views/EditionsDashboard/editionsConstants';

import { EDITOR_FOOTER_HEIGHT } from './editorConstants';
import Button, { BUTTON_TYPE } from './Button';
import * as styles from './footer.scss';
import appSwitcher from '#/config/appSwitcher';

const isEditionsSupported = appSwitcher.backend === 'git';

const getNextStatus = editionMetadata => {
  if (!editionMetadata) {
    return null;
  }

  const idx = EDITION_STATUS_ORDER.indexOf(editionMetadata.status);

  if (idx === EDITION_STATUS_ORDER.length - 1) {
    return null;
  }

  return EDITION_STATUS_ORDER[idx + 1];
};

const Footer = ({
  onUpdateEditionStatusClick,
  onDashboardClick,
  editionMetadata
}) => {
  const nextStatus = getNextStatus(editionMetadata);

  return (
    <FooterBar height={EDITOR_FOOTER_HEIGHT}>
      { isEditionsSupported && (
        <div style={{ position: 'absolute', left: 0, top: 2 }}>
          <Button
            onClick={onDashboardClick}
            type={BUTTON_TYPE.EMPTY_THIN}
            style={{ marginLeft: 10 }}
          >
            DASHBOARD
          </Button>
        </div>
      )}
      {editionMetadata && isEditionsSupported && (
        <Button
          disabled={!nextStatus}
          type={BUTTON_TYPE.EMPTY_THIN}
          className={styles.pushToProduction}
          onClick={() => onUpdateEditionStatusClick(nextStatus)}
        >
          {nextStatus
            ? `MOVE TO ${EDITION_STATUS_NAME[nextStatus].toUpperCase()}`
            : EDITION_STATUS_NAME[editionMetadata.status].toUpperCase()}
        </Button>
      )}
      <div className={styles.bottom} />
    </FooterBar>
  );
};

Footer.propTypes = {
  editionMetadata: PropTypes.object,
  onDashboardClick: PropTypes.func,
  onUpdateEditionStatusClick: PropTypes.func
};

Footer.defaultProps = {
  onDashboardClick: () => null,
  onUpdateEditionStatusClick: () => null
};

export default Footer;
